import { render, staticRenderFns } from "./IconClosed.vue?vue&type=template&id=768be29a&scoped=true&"
import script from "./IconClosed.vue?vue&type=script&lang=js&"
export * from "./IconClosed.vue?vue&type=script&lang=js&"
import style0 from "./IconClosed.vue?vue&type=style&index=0&id=768be29a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "768be29a",
  null
  
)

export default component.exports