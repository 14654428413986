import { mapState, mapMutations } from 'vuex'
import uniqid from 'uniqid'
import MessageModalItem from './MessageModalItem'
import GET_USER from '@/graphql/users/GetUser.gql'
import CREATE_MESSAGE from '@/graphql/messages/CreateMessage.gql'
import ListMessages from '@/graphql/messages/ListMessages.gql'

export default {
  components: {
    MessageModalItem
  },
  data() {
    return {
      newMessage: ''
    }
  },
  apollo: {
    getUser: {
      query: GET_USER,
      variables() {
        return {
          id: this.toUser.id
        }
      },
      fetchPolicy: 'network-only',
      prefetch: true,
      skip() {
        return !this.toUser.id
      },
      result({ data, error }) {
        if (error) {
          this.showNotifyBar({
            message: `Unable to get user`,
            type: 'error'
          })
        } else if (data && data.getUser) {
          this.setToUser(data.getUser)
          this.scrollDiv()
        }
      }
    },
    listMessages: {
      query: ListMessages,
      variables() {
        return {
          toUser: this.toUser.id,
          fromUser: this.companyId
        }
      },
      fetchPolicy: 'network-only',
      prefetch: true,
      skip() {
        return !this.toUser.id || !this.companyId
      },
      result({ data, error }) {
        if (error) {
          this.showNotifyBar({
            message: `Unable to get messages`,
            type: 'error'
          })
        } else if (data && data.listMessages) {
          this.setCurrentMessages(data.listMessages)
          this.scrollDiv()
        }
      }
    }
  },
  computed: {
    ...mapState(['company']),
    ...mapState('message', ['toUser', 'currentMessages'])
  },
  mounted() {
    this.scrollDiv()
  },
  methods: {
    ...mapMutations('message', [
      'setToUser',
      'setCurrentMessages',
      'handleCurrentMessenger',
      'updateCurrentMessages'
    ]),
    showDate(index) {
      if (index === 0) {
        return true
      }
      let prv = this.currentMessages[index - 1]
      let nxt = this.currentMessages[index]
      prv = this.readableDate(prv.createdAt)
      nxt = this.readableDate(nxt.createdAt)
      return prv !== nxt
    },
    showTime(index) {
      if (index === this.currentMessages.length - 1) {
        return true
      }
      let cur = this.currentMessages[index]
      let nxt = this.currentMessages[index + 1]
      cur = this.readableTime(cur.createdAt)
      nxt = this.readableTime(nxt.createdAt)
      if (
        cur === nxt &&
        this.showDate(index) &&
        index === this.currentMessages.length - 1
      ) {
        return true
      }
      return cur !== nxt
    },
    async attachImage(event) {
      try {
        if (!event.target.files.length) return
        if (!event.target.files[0].type.startsWith('image')) {
          this.showNotifyBar({
            message: 'Only images are accepted',
            type: 'error'
          })
          return
        }
        const size = 10000
        const sizeValid = Array.from(event.target.files).every((i) =>
          this._isFileSizeValid(i, size)
        )
        if (!sizeValid) {
          this.showNotifyBar({
            message: `Image size should not be greater than ${size / 1000}MB`,
            type: 'error'
          })
          return
        }
        const picture = await this.getDataUrl({ event })
        const details = {
          images: [picture]
        }
        const messageType = 'image'
        const content = ''
        const generateMessage = {
          id: uniqid(),
          fromUser: {
            id: this.companyId,
            companyName: this.company.companyName
          },
          toUser: {
            id: this.toUser.id,
            firstName: this.toUser.firstName,
            lastName: this.toUser.lastName
          },
          content,
          messageType,
          details,
          createdAt: Date.now(),
          isRead: []
        }
        this.updateCurrentMessages({
          payload: generateMessage,
          companyId: this.companyId
        })
        this.scrollDiv()
        const pictureUpdate = await this.uploadToCloud({
          file: picture.file,
          id: picture.id,
          folder: 'messages'
        })
        details.images = [
          {
            id: pictureUpdate.id,
            url: pictureUpdate.url
          }
        ]

        const message = {
          toUser: this.toUser.id,
          fromUser: this.companyId,
          fromType: 'company',
          toType: 'user',
          sender: this.companyId,
          content,
          messageType,
          details
        }

        await this.graphqlMutation({
          mutation: CREATE_MESSAGE,
          variables: { input: message }
        })
      } catch (error) {
        this.showNotifyBar({
          message: 'Unable to send message',
          type: 'error'
        })
      }
    },
    async postMessage() {
      const newMessage = this.$refs.messageInput.textContent
      if (!newMessage) return
      try {
        const generateMessage = {
          id: await uniqid(),
          fromUser: {
            id: this.companyId,
            companyName: this.company.companyName
          },
          toUser: {
            id: this.toUser.id,
            firstName: this.toUser.firstName,
            lastName: this.toUser.lastName
          },
          content: newMessage,
          createdAt: Date.now(),
          isRead: false
        }
        const message = {
          toUser: this.toUser.id,
          fromUser: this.companyId,
          fromType: 'company',
          toType: 'user',
          content: newMessage
        }
        this.updateCurrentMessages({
          payload: generateMessage,
          companyId: this.companyId
        })
        this.scrollDiv()
        this.$refs.messageInput.textContent = ''
        this.$refs.messageInput.focus()
        this.showPlaceholder = true
        const data = await this.graphqlMutation({
          mutation: CREATE_MESSAGE,
          variables: { input: message }
        })
        if (data && data.createMessage && data.createMessage.code !== 200) {
          throw new Error('error')
        }
      } catch (error) {
        this.showNotifyBar({
          message: 'Unable to send message',
          type: 'error'
        })
      }
    },
    scrollDiv() {
      this.$nextTick(() => {
        const d = this.$refs.messageContents
        d.scrollTop = d.scrollHeight
      })
    }
  }
}
