export default {
  methods: {
    shareToTwitter(postDetails) {
      const text =
        postDetails.type === 'job'
          ? `Check out this role for a ${postDetails.title} on @joinTabulio. Please retweet and tag your friends.`
          : postDetails.type === 'collab'
          ? `Join me in building ${postDetails.title} on @joinTabulio.`
          : `Check out ${postDetails.title} by ${postDetails.poster.firstName} ${postDetails.poster.lastName} on @joinTabulio.`
      const post = {
        url: this.linkUrl,
        text: encodeURIComponent(text)
      }
      const getWindowOptions = function () {
        const width = 500
        const height = 350
        const left = window.innerWidth / 2 - width / 2
        const top = window.innerHeight / 2 - height / 2

        return [
          'resizable,scrollbars,status',
          `height=${height}`,
          `width=${width}`,
          `left=${left}`,
          `top=${top}`
        ].join()
      }
      const url = `https://twitter.com/intent/tweet?url=${post.url}&text=${post.text}`
      const win = window.open(url, 'Share On Twitter', getWindowOptions())
      win.opener = null
    },

    shareToFacebook() {
      window.FB.ui({
        method: 'share',
        href: this.linkUrl
      })
    }
  }
}
