<template>
  <div>
    <form class="settings__box" @input="monitorErrors">
      <div class="change-picture">
        <div
          v-if="state.bannerImage && state.bannerImage.url"
          class="profile__banner"
        >
          <img
            class="profile__banner-img"
            :src="state.bannerImage.url"
            alt="Click to add image"
          />
          <label for="add-banner" class="add-banner">
            <input
              id="add-banner"
              type="file"
              @change="uploadPicture($event, 'bannerImage')"
            />
            <svg
              class="u-icon"
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path
                fill="#a7a7af"
                d="M21.02 5H19V2.98c0-.54-.44-.98-.98-.98h-.03c-.55 0-.99.44-.99.98V5h-2.01c-.54 0-.98.44-.99.98v.03c0 .55.44.99.99.99H17v2.01c0 .54.44.99.99.98h.03c.54 0 .98-.44.98-.98V7h2.02c.54 0 .98-.44.98-.98v-.04c0-.54-.44-.98-.98-.98zM16 9.01V8h-1.01c-.53 0-1.03-.21-1.41-.58-.37-.38-.58-.88-.58-1.44 0-.36.1-.69.27-.98H5c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-8.28c-.3.17-.64.28-1.02.28-1.09-.01-1.98-.9-1.98-1.99zM15.96 19H6c-.41 0-.65-.47-.4-.8l1.98-2.63c.21-.28.62-.26.82.02L10 18l2.61-3.48c.2-.26.59-.27.79-.01l2.95 3.68c.26.33.03.81-.39.81z"
              />
            </svg>
          </label>
        </div>
        <label v-else for="add-banner" class="banner-none">
          <input
            id="add-banner"
            type="file"
            @change="uploadPicture($event, 'bannerImage')"
          />
          <svg
            class="u-icon"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path
              fill="#a7a7af"
              d="M21.02 5H19V2.98c0-.54-.44-.98-.98-.98h-.03c-.55 0-.99.44-.99.98V5h-2.01c-.54 0-.98.44-.99.98v.03c0 .55.44.99.99.99H17v2.01c0 .54.44.99.99.98h.03c.54 0 .98-.44.98-.98V7h2.02c.54 0 .98-.44.98-.98v-.04c0-.54-.44-.98-.98-.98zM16 9.01V8h-1.01c-.53 0-1.03-.21-1.41-.58-.37-.38-.58-.88-.58-1.44 0-.36.1-.69.27-.98H5c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-8.28c-.3.17-.64.28-1.02.28-1.09-.01-1.98-.9-1.98-1.99zM15.96 19H6c-.41 0-.65-.47-.4-.8l1.98-2.63c.21-.28.62-.26.82.02L10 18l2.61-3.48c.2-.26.59-.27.79-.01l2.95 3.68c.26.33.03.81-.39.81z"
            />
          </svg>
          <h4>Banner image</h4>
        </label>
        <div class="profile__thumbnail">
          <div v-if="state.logo.url" class="profile__has-image">
            <img :src="state.logo.url" alt="Click to add image" />
            <label for="add-photo">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                enable-background="new 0 0 24 24"
                height="24"
                viewBox="0 0 24 24"
                width="24"
              >
                <path
                  fill="#ffffff"
                  d="M3,4V1h2v3h3v2H5v3H3V6H0V4H3z M6,10V7h3V4h7l1.83,2H21c1.1,0,2,0.9,2,2v12c0,1.1-0.9,2-2,2H5c-1.1,0-2-0.9-2-2V10H6z M13,19c2.76,0,5-2.24,5-5s-2.24-5-5-5s-5,2.24-5,5S10.24,19,13,19z M9.8,14c0,1.77,1.43,3.2,3.2,3.2s3.2-1.43,3.2-3.2 s-1.43-3.2-3.2-3.2S9.8,12.23,9.8,14z"
                />
              </svg>
              <input
                id="add-photo"
                class="in-add-photo"
                type="file"
                @change="uploadPicture($event, 'logo')"
              />
            </label>
          </div>
          <label v-else for="add-photo" class="profile__no-image">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 24 24"
              class="u-icon"
            >
              <path
                fill="#e5e5eb"
                d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v1c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-1c0-2.66-5.33-4-8-4z"
              />
            </svg>
            <input
              id="add-photo"
              class="in-add-photo"
              type="file"
              @change="uploadPicture($event, 'logo')"
            />
          </label>
        </div>
      </div>
      <input-field
        v-model.trim="state.companyName"
        label="Name of Company"
        name="name"
        type="text"
        :error-message="errorMessages.companyName"
      />
      <input-field
        v-model.number.trim="state.noOfEmployees"
        label="No. of Employees"
        name="noOfEmployees"
        type="number"
        :error-message="errorMessages.noOfEmployees"
      />

      <location-field
        :current-location="state.location"
        label="Address"
        name="address"
        :error-message="errorMessages.location"
        @update="updateLocation"
      />
      <textarea-input-field
        v-model.trim="state.summary"
        label="About your company"
        name="briefSummary"
        type="text"
        :error-message="errorMessages.summary"
      />
      <text-editor
        label="Details"
        :content="state.description"
        @editEditorContent="editEditorContent"
      />
      <base-button
        text="Save"
        class="btn-space-top mx-auto"
        :is-loading="btnDisabled"
        @click="confirmDetails"
      />
    </form>
  </div>
</template>

<script>
import uniqid from 'uniqid'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapMutations } from 'vuex'
import TextEditor from '@/components/common/TextEditor'
import LocationField from '@/components/common/LocationField'
import EDIT_COMPANY from '@/graphql/companies/EditCompany.gql'

export default {
  name: 'CompanyProfile',
  components: {
    TextEditor,
    LocationField
  },
  mixins: [validationMixin],
  data() {
    return {
      btnDisabled: false,
      showDomainVerification: false,
      medias: [],
      shouldSave: false,
      state: {
        companyName: '',
        summary: '',
        description: '',
        noOfEmployees: null,
        image: {
          url: '',
          id: ''
        },
        bannerImage: {
          url: '',
          id: ''
        },
        location: {
          country: '',
          state: '',
          address: '',
          placeId: ''
        }
      },
      errorMessages: {
        companyName: '',
        summary: '',
        description: '',
        noOfEmployees: '',
        logo: '',
        location: ''
      },
      showErrors: false
    }
  },
  metaInfo: {
    title: 'Space | Settings'
  },
  computed: {
    allMediaUploaded() {
      return this.medias.every((m) => m.uploaded)
    }
  },
  watch: {
    medias: {
      deep: true,
      handler() {
        if (this.allMediaUploaded && this.shouldSave) {
          this.submit()
        }
      }
    }
  },
  created() {
    const d = this.$store.state.company
    this.state = {
      companyName: d.companyName,
      summary: d.summary,
      description: d.description,
      noOfEmployees: d.noOfEmployees,
      logo: d.logo,
      bannerImage: d.bannerImage,
      location: d.location
    }
  },

  methods: {
    ...mapMutations('auth', ['showModal']),
    async confirmDetails() {
      this.showErrors = true
      const isValid = await this.checkErrors()
      if (!isValid) return
      this.btnDisabled = true
      if (!this.allMediaUploaded) {
        this.shouldSave = true
      } else {
        this.submit()
      }
    },
    async submit() {
      try {
        const input = await this.removeTypename(this.state)
        const data = await this.graphqlMutation({
          mutation: EDIT_COMPANY,
          variables: {
            companyId: this.companyId,
            input
          }
        })
        if (data && data.editCompany) {
          this.showNotifyBar({
            message: 'Company successfully updated',
            type: 'success'
          })
          this.$store.commit('setCompany', data.editCompany)
        }
      } catch (error) {
        this.showNotifyBar({
          message: 'An error occured, try again',
          type: 'error'
        })
      } finally {
        this.btnDisabled = false
      }
    },
    updateLocation(location) {
      this.state.location = location
    },
    setMediaArr(payload) {
      const media = this.medias.find((item) => item.mediaId === payload.mediaId)
      if (media) {
        const arr = this.medias.filter((item) => item.mediaId !== media.mediaId)
        this.medias = arr.concat(payload)
      } else {
        this.medias = this.medias.concat(payload)
      }
    },
    async uploadPicture(event, type) {
      if (!event.target.files.length) return
      if (!event.target.files[0].type.startsWith('image')) {
        this.showNotifyBar({
          message: 'Only images are accepted',
          type: 'error'
        })
        return
      }
      const size = 10000
      const sizeValid = Array.from(event.target.files).every((i) =>
        this._isFileSizeValid(i, size)
      )
      if (!sizeValid) {
        this.showNotifyBar({
          message: `Image size should not be greater than ${size / 1000}MB`,
          type: 'error'
        })
        return
      }
      const picture = await this.getDataUrl({ event })
      this.state[type] = picture
      const mediaId = await uniqid()
      this.setMediaArr({ mediaId, uploaded: false })
      const folder = type === 'bannerImage' ? 'banner_image' : 'company_logo'
      const pictureUpdate = await this.uploadToCloud({
        file: picture.file,
        id: picture.id,
        folder
      })
      this.state[type] = pictureUpdate
      this.setMediaArr({ mediaId, uploaded: true })
    },
    editEditorContent(text) {
      this.state.description = text
    },
    checkErrors() {
      const arr = ['companyName']
      arr.forEach((value) => {
        switch (value) {
          case 'companyName':
            if (!this.$v.state.companyName.required) {
              this.errorMessages.companyName = 'Name of the company is required'
            } else {
              this.errorMessages.companyName = ''
            }
            break
          case 'summary':
            if (!this.$v.state.summary.required) {
              this.errorMessages.summary = 'Company info is required'
            } else {
              this.errorMessages.summary = ''
            }
            break
          default:
            break
        }
      })
      return !this.$v.state.$invalid
    },
    monitorErrors() {
      if (this.showErrors) this.checkErrors()
    }
  },
  validations: {
    state: {
      companyName: {
        required
      },
      summary: {
        required
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.change-picture {
  margin-bottom: 2rem;
  width: 100%;
  position: relative;
}
.profile__banner {
  position: relative;
  width: 100%;
  height: 250px;
  display: flex;
  &-img {
    height: 100%;
    width: 100%;
    flex: 1;
    border: 1px solid var(--grey-1);
    border-radius: 10px;
    @include flex;
  }
  input {
    display: none;
  }
  label {
    cursor: pointer;
    z-index: 1;
    border-radius: 50%;
    left: calc(50% - 20px);
    top: calc(50% - 20px);
    width: 40px;
    height: 40px;
    padding: 0.5rem;
    @include flex;
    position: absolute;
    transform-origin: center center;
    background-color: var(--grey-2);
    @include button-click;
    img {
      width: 32px;
      height: 32px;
    }
  }
}

.banner-none {
  @extend .profile__banner;
  border: 2px dashed var(--grey-6);
  border-radius: 10px;
  cursor: pointer;
  @include flex($direction: column);
  transition: var(--transition-1);
  svg {
    transition: var(--transition-1);
    width: 5rem;
    height: auto;
  }
  &:hover {
    background: var(--grey-2);
    svg {
      transform: scale(1.1);
    }
  }
}
.profile {
  &__thumbnail {
    position: absolute;
    right: 1rem;
    bottom: -1.5rem;
    width: 80px;
    height: 80px;
    overflow: hidden;
    @include flex;
    input {
      display: none;
    }
    svg {
      transition: var(--transition-1);
    }
    &:hover {
      svg {
        transform: scale(1.1);
      }
    }
    label {
      cursor: pointer;
      transition: var(--transition-1);
      width: 100%;
      height: 100%;
      @include flex;
    }
  }
  &__has-image {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    border: 2px solid var(--grey-8);
    label {
      @include absolute;
      background: var(--opacity-black-20);
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__no-image {
    background: var(--black-2);
    border: 2px solid var(--grey-6);
    border-radius: 50%;
    &:hover {
      background: var(--grey-2);
    }
  }
}
#app.dark-theme {
  label.profile__banner {
    &:hover {
      background: var(--grey-1);
    }
  }
}
</style>
