import { mapMutations, mapState } from 'vuex'
import GET_NOTIFICATIONS from '@/graphql/notifications/GetNotifications.gql'
import NEW_NOTIFICATION from '@/graphql/notifications/NewNotification.gql'
import { EventBus } from '@/utils/event-bus'
import UPDATE_NOTIFICATION_TO_SEEN from '@/graphql/notifications/UpdateNotificationToSeen.gql'

export default {
  data() {
    return {
      showNotificationDropdown: false
    }
  },
  apollo: {
    getNotifications: {
      query: GET_NOTIFICATIONS,
      variables() {
        const { totalCount, hasMore, currentCount, limit } =
          this.notificationCursor
        return {
          userId: this.companyId,
          cursor: {
            totalCount,
            hasMore,
            currentCount,
            limit
          }
        }
      },
      manual: true,
      prefetch: false,
      fetchPolicy: 'network-only',
      skip() {
        return !this.userId || this.notifications.length
      },
      result({ data, error }) {
        if (data?.getNotifications?.data) {
          this.updateNotifications(data.getNotifications.data)
          this.setNotificationCursor(data.getNotifications.cursor)
          this.$apollo.queries.getNotifications.skip = true
        }
      }
    },
    $subscribe: {
      newNotification: {
        query: NEW_NOTIFICATION,
        variables() {
          return {
            userId: this.companyId
          }
        },
        manual: true,
        skip() {
          return !this.companyId
        },
        result({ data, error }) {
          if (!error) {
            this.addNewNotification(data.newNotification)
          }
        }
      }
    }
  },
  computed: {
    ...mapState(['notifications', 'notificationCursor']),
    newNotifications() {
      return this.notifications.some((item) => item.isSeen === false)
    }
  },
  mounted() {
    EventBus.$on('fetchMore', (value) => {
      this.$apollo.queries.getNotifications.skip = false
    })
  },
  methods: {
    ...mapMutations([
      'updateNotifications',
      'addNewNotification',
      'markNotificationAsSeen',
      'setNotificationCursor'
    ]),
    async toggleNotificationDropdown() {
      this.showNotificationDropdown = !this.showNotificationDropdown
      await this.graphqlMutation({
        mutation: UPDATE_NOTIFICATION_TO_SEEN,
        variables: {
          userId: this.companyId
        }
      })
      this.markNotificationAsSeen()
    }
  }
}
