<template>
  <div class="page--has-info">
    <!-- <div v-if="!company.domainEmailVerified" class="empty-content">
      <empty-timeline class="bal" />
      <h4>Welcome to Space!</h4>
      <p>
        To protect your company and our users, we need to confirm you work at
        the company you are representing before you can start hiring on Tabulio.
        Click on verify to begin the process.
      </p>
      <base-button text="Verify" @click="showModal('domainVerification')" />
    </div> -->
    <is-loading v-if="!showPage || $apollo.loading" />
    <div v-else-if="!jobs.length" class="empty-content">
      <empty-timeline class="bal" />
      <h4>Ready to start hiring?</h4>
      <p>
        Click on "Post a job" to list your vacancies and start receiving
        applications from talents. You can also search through our talent pool
        to find creatives based on their skill set or project.
      </p>
    </div>
    <div v-else>
      <div class="job__cont">
        <job-item
          v-for="job in jobs"
          :key="job.id"
          v-bind="job"
          @deleteJob="handleConfirmDelete"
          @updateStatus="handleUpdateStatus"
          @editJob="editJob"
        />
      </div>
      <delete-job
        v-if="showConfirmDelete"
        @click="handleDelete"
        @hideModal="showConfirmDelete = false"
      />
      <update-status
        v-if="showUpdateStatus"
        :job-id="currentJob.id"
        :btn-disabled="btnDisabled"
        @click="updateStatus"
        @hideModal="showUpdateStatus = false"
      />
    </div>
    <page-info-container :info="info" :show-page-mobile-info-modal="false" />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import EmptyTimeline from '../components/illustrations/EmptyTimeline.vue'
import PageInfoContainer from '@/components/common/PageInfoContainer.vue'
import JobItem from '@/components/jobs/JobItem'
import LIST_JOB from '@/graphql/jobs/ListJob.gql'
import jobUpdateMixin from '@/mixins/jobUpdate'

export default {
  name: 'Dashboard',
  components: {
    JobItem,
    PageInfoContainer,
    EmptyTimeline
  },
  mixins: [jobUpdateMixin],
  data() {
    return {
      btnDisabled: false,
      jobs: [],
      currentJob: {},
      showPage: false,
      info: {
        title: 'Tabulio Space Highlights!',
        list: [
          {
            text: 'Job post - List your vacancies to reach a large pool of talents and get applications from interested candidates',
            svg: 'create-collab-idea'
          },
          {
            text: 'Talent pool - Search through our pool to discover talents by skill-set or type of projects they have worked on',
            svg: 'create-collab-look'
          },
          {
            text: 'Shortlist - Select qualified candidates from the long list of applicants',
            svg: 'create-collab-start'
          },
          {
            text: 'Interview stages - Tabulio Space automatically creates kanban boards for your interview stages, which allow you to move candidates from one stage to another with ease',
            svg: 'create-collab-start'
          }
        ]
      }
    }
  },
  metaInfo: {
    title: 'Space | Jobs'
  },
  apollo: {
    listJob: {
      query: LIST_JOB,
      variables() {
        return {
          company: this.companyId
        }
      },
      fetchPolicy: 'network-only',
      skip() {
        return !this.companyId
      },
      result({ data, error }) {
        if (error) {
          this.showNotifyBar({
            message: `Unable to get jobs`,
            type: 'error'
          })
        } else if (data && data.listJob) {
          this.showPage = true
          this.jobs = data.listJob
        }
      }
    }
  },
  computed: {
    ...mapState(['company'])
  },
  methods: {
    ...mapMutations('auth', ['showModal'])
  }
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 4rem;
  @include flex($main: space-between);
  padding: 0rem 4rem;
}
.job__cont {
  display: flex;
  flex-direction: column;
  width: 600px;
}
.empty-content {
  @include flex($direction: column);
  height: 80vh;
  .bal {
    width: 270px;
    margin-bottom: 1rem;
    margin-top: 2rem;
    height: auto;
  }
  p {
    max-width: 420px;
    margin-bottom: 2rem;
    color: var(--grey-5);
  }
  p,
  h4 {
    text-align: center;
  }
  h4 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}
</style>
