<template>
  <div
    :class="['message-tab', { active: isSelectedChat }]"
    @click.stop.prevent="$emit('handleCurrentMessenger')"
  >
    <div class="img-wrapper">
      <poster-thumbnail
        :username="username"
        :first-name="firstName"
        :image="image"
        padding="nopadding"
        size="extra-small"
      />
      <div v-if="isOnline" class="online-indicator" />
    </div>
    <div class="flex-col flex-1">
      <div class="messenger__top">
        <p class="messenger__name">{{ firstName }} {{ lastName }}</p>
        <p class="messenger__date">
          {{ readableDateTime(lastMessage.createdAt) }}
        </p>
      </div>
      <p
        :class="[
          'messenger__message',
          { 'messenger__message--unread': hasUnread }
        ]"
      >
        {{ lastMessage.content }}
      </p>
    </div>
    <div v-if="isSelectedChat" :class="['indicator']"></div>
  </div>
</template>

<script>
export default {
  name: 'MessageSideBarItem',
  props: {
    firstName: {
      type: String,
      default: '',
      required: true
    },
    username: {
      type: String,
      default: '',
      required: true
    },
    lastName: {
      type: String,
      default: '',
      required: true
    },
    isOnline: {
      type: Boolean,
      default: false,
      required: false
    },
    image: {
      type: Object,
      default() {
        return {
          url: ''
        }
      }
    },
    id: {
      type: String,
      default: '',
      required: true
    },
    modalActiveUser: {
      type: String,
      default: '',
      required: false
    },
    messages: {
      type: Array,
      required: false,
      default() {
        return [{}]
      }
    }
  },
  data() {
    return {
      newMessagesAvailable: false
    }
  },
  computed: {
    lastMessage() {
      return this.messages[0]
    },
    isSelectedChat() {
      return this.id === this.modalActiveUser
    },
    isFromCurrentUser() {
      const id =
        typeof this.lastMessage.fromUser === 'string'
          ? this.lastMessage.fromUser
          : this.lastMessage.fromUser.id
      return this.companyId === id
    },
    hasUnread() {
      return !this.lastMessage.isRead && !this.isFromCurrentUser
    }
  }
}
</script>

<style lang="scss" scoped>
.message-tab {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  position: relative;
  cursor: pointer;
  margin: var(--spacing-xs);
  border-radius: 8px;
  overflow: hidden;
  &:hover,
  &.active {
    background-color: var(--black-2);
  }
  .img-wrapper {
    margin-right: 0.5rem;
    position: relative;
    height: 32px;
    width: 32px;
  }
  .online-indicator {
    background-color: #00ff94;
    width: 6px;
    height: 6px;
    position: absolute;
    right: 0rem;
    bottom: 0.2rem;
    border-radius: 50%;
  }
  .indicator {
    @include absolute;
    width: 4px;
    background: var(--blue-gradient);
  }
}
.messenger {
  &__top {
    @include flex($main: space-between, $cross: baseline);
  }
  &__name {
    max-width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__date {
    color: var(--grey-5);
    font-size: 13px;
  }
  &__message {
    color: var(--grey-5);
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 190px;
    text-overflow: ellipsis;
    &--unread {
      font-weight: bold;
      color: var(--grey-3);
    }
  }
}

@media (max-width: 600px) {
  .message-tab {
    padding: 0% 5%;
  }
}
#app.dark-theme {
  .message-tab {
    // background: var(--grey-2);
  }
  .message-tab:hover {
    background-color: var(--black-2);
  }
}
</style>
