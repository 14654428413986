<template>
  <div class="shortlists">
    <is-loading v-if="!showPage" />
    <div v-else>
      <div class="page-header">
        <back-arrow @click="$router.go(-1)" />
        <h4>{{ jobDetails.title }}</h4>
        <div class="ml-auto">
          <router-link
            class="link"
            :to="{ name: 'applications', params: $route.params.applicationId }"
          >
            View Applications
          </router-link>
        </div>
      </div>
      <div class="overflow-container">
        <div class="group-container">
          <group-board
            v-for="(group, index) in groups"
            :id="index"
            :key="index"
            :num-of-users="group.users.length"
            :title="group.title"
          >
            <draggable
              :list="group.users"
              :animation="200"
              ghost-class="ghost-card"
              group="users"
              class="drag-container"
              @change="onGroupChange"
            >
              <user-card
                v-for="user in group.users"
                :key="user.id"
                :applicant-id="user.id"
                v-bind="user"
                @handleCurrentMessenger="
                  handleCurrentMessenger({
                    show: true,
                    id: user.id
                  })
                "
              />
            </draggable>
          </group-board>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import cloneDeep from 'lodash.clonedeep'

import GroupBoard from '../components/shortlists/GroupBoard.vue'
import UserCard from '../components/shortlists/UserCard.vue'
import UpdateApplicationStage from '@/graphql/applications/UpdateApplicationStage.gql'
import applicationsMixin from '@/mixins/applications'
import jobUpdateMixin from '@/mixins/jobUpdate'

const SHORTLISTED = 'shortlisted'

export default {
  name: 'Shortlists',
  components: {
    Draggable,
    GroupBoard,
    UserCard
  },
  mixins: [jobUpdateMixin, applicationsMixin],
  data() {
    return {
      groups: []
    }
  },
  metaInfo() {
    return {
      title: `Shortlists for ${this.jobDetails.title}`
    }
  },
  computed: {
    gr() {
      if (!this.jobDetails?.interviewStages) return []
      const stages = cloneDeep(this.jobDetails.interviewStages).map(
        (stage) => ({ ...stage, title: stage.value, users: [] })
      )
      return stages
    }
  },
  async created() {
    try {
      const [jobRes, applicationListRes] = await Promise.all([
        this.getJob(),
        this.listJobApplications()
      ])
      this.jobDetails = jobRes.getJob
      this.applications = applicationListRes.listJobApplications
      this.sortApplications()
      this.showPage = true
    } catch (error) {
      this.showNotifyBar({
        message: 'Unable to get job',
        type: 'error'
      })
    }
  },
  methods: {
    sortApplications() {
      const defaultStage = {
        id: SHORTLISTED,
        title: 'Shortlisted',
        value: 'Shortlisted',
        users: []
      }
      const jobStages = cloneDeep(this.jobDetails.interviewStages).map(
        (stage) => ({
          ...stage,
          users: [],
          title: stage.value
        })
      )

      this.applications.forEach((application) => {
        const user = Object.assign(application.applicant, {
          resumeUrl: application.resume.fileUrl,
          applicationId: application.id
        })
        if (!application.interviewStage && application.shortListed) {
          defaultStage.users = defaultStage.users.concat(user)
        } else if (application.interviewStage && application.shortListed) {
          const stage = jobStages.find(
            (s) => s.id === application.interviewStage
          )
          if (stage) {
            stage.users = stage.users.concat(user)
          }
        }
      })
      this.groups = [].concat(defaultStage, jobStages)
    },
    async onGroupChange(card) {
      if (card.added) {
        const stage = this.groups.find((group) =>
          group.users.some((user) => user.id === card.added.element.id)
        )
        try {
          const stageId = stage.id === SHORTLISTED ? '' : stage.id
          await this.graphqlMutation({
            mutation: UpdateApplicationStage,
            variables: {
              applicationId: card.added.element.applicationId,
              stageId
            }
          })
        } catch (error) {
          this.showNotifyBar({
            message: 'Unable to update stage',
            type: 'error'
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.overflow-container {
  overflow: auto;
  width: 100%;
  @include hide-scroll;
}
.group-container {
  display: flex;
  padding: 0rem 2rem;
  width: fit-content;
}
.page-header {
  @include flex($main: flex-start);
  margin-bottom: 2rem;
  padding: 1rem 2rem;
  border-bottom: var(--border-2);
  align-self: flex-start;
  h4 {
    font-size: 1.5rem;
    text-transform: capitalize;
  }
}
.drag-container {
  padding: var(--spacing-sm) var(--spacing-base) 96px var(--spacing-base);
  padding-bottom: 48px;
  overflow: auto;
  height: calc(100vh - 4rem - 164px);
}
.ghost-card {
  opacity: 0.5;
}
.link {
  color: var(--blue-6);
  &:hover {
    text-decoration: underline;
  }
}
</style>
