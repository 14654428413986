<template>
  <div :class="['tabs-cont', customStyle]">
    <div class="tabs">
      <div class="flex">
        <button
          v-for="tab in tabs"
          :key="tab.name"
          :class="['tabs__item', { active: activeTab === tab.name }]"
          @click.prevent.stop="$emit('setActiveTab', tab)"
        >
          {{ tab.name }}
        </button>
      </div>
      <div
        class="tabs__toggle"
        :style="`left: calc(1.5rem + ${getPosition.left}px);width: ${getPosition.width}px`"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageTabs',
  props: {
    tabs: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    activeTab: {
      type: String,
      required: true,
      default: ''
    },
    customStyle: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    getPosition() {
      const t = {
        left: 0
      }
      const tab = this.tabs.findIndex((i) => i.name === this.activeTab)
      t.width = this.tabs[tab]?.width
      for (let index = 0; index < tab; index++) {
        t.left = t.left + this.tabs[index].width + 15
      }
      return t.width ? t : {}
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs-cont {
  max-width: 100vw;
  overflow-x: auto;
  position: sticky;
  top: 55px;
  z-index: 10;
}
.tabs {
  z-index: 10;
  display: flex;
  border-bottom: var(--border-1);
  padding: 0rem 1.5rem;
  align-items: center;
  background: var(--white);
  height: 56px;
  border-radius: var(--border-radius-lg) var(--border-radius-lg) 0px 0px;
  position: relative;
  &__item {
    padding: 0.25rem 1.5rem;
    font-size: 16px;
    border-radius: 2rem;
    color: var(--grey-5);
    background-color: transparent;
    position: relative;
    &::after {
      background: var(--grey-2);
      content: '';
      width: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: -1;
      height: 100%;
      border-radius: inherit;
    }
    &:not(:last-child) {
      margin-right: 1rem;
    }
    &.active {
      color: var(--blue-6);
      background: transparent;
    }
  }
  &__toggle {
    position: absolute;
    left: 0px;
    z-index: -1;
    height: calc(38px - 0.5rem);
    border-radius: 2rem;
    background: rgba(16, 27, 53, 0.9);
    transition: var(--transition-2);
    transition-duration: 0.6s;
  }
}
#app.dark-theme {
  .tabs {
    background: var(--grey-2);
    &__item {
      &::after {
        background: var(--grey-1);
      }
      &.active {
        background: transparent;
        button {
          color: var(--white);
        }
      }
    }
    &__toggle {
      background: var(--black-2);
    }
  }
}
@media screen and (max-width: 600px) {
  .tabs-cont.mobile-top-sticky {
    top: 0px;
  }
  .tabs {
    border-radius: 0px;
    position: relative;
    width: fit-content;
    top: 0px;
    min-width: 100%;
  }
}
</style>
