<template>
  <div :class="['dropdown-card']">
    <button
      class="reset-btn w100"
      @click.stop.prevent="$emit('goToUserSettings')"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 120 121"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M60 60.5C71.05 60.5 80 51.55 80 40.5C80 29.45 71.05 20.5 60 20.5C48.95 20.5 40 29.45 40 40.5C40 51.55 48.95 60.5 60 60.5ZM60 70.5C46.65 70.5 20 77.2 20 90.5V95.5C20 98.25 22.25 100.5 25 100.5H95C97.75 100.5 100 98.25 100 95.5V90.5C100 77.2 73.35 70.5 60 70.5Z"
          fill="url(#profile234)"
        />
        <defs>
          <linearGradient
            id="profile234"
            x1="20"
            y1="20.5"
            x2="111.519"
            y2="37.1373"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#64FFBF" />
            <stop offset="1" stop-color="#00CCFF" />
          </linearGradient>
        </defs>
      </svg>
      <p>Account Settings</p>
    </button>
    <!-- <button class="reset-btn" @click.stop.prevent="$emit('takeATour')">
      <svg width="24" height="24">
        <use href="#icontaketour" width="24" height="24" />
      </svg>
      <p>See how it works</p>
    </button> -->
    <button class="reset-btn" @click.stop.prevent="$emit('showContactUsModal')">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 0 24 24"
        width="24"
      >
        <path
          class="path__support"
          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92c-.5.51-.86.97-1.04 1.69-.08.32-.13.68-.13 1.14h-2v-.5c0-.46.08-.9.22-1.31.2-.58.53-1.1.95-1.52l1.24-1.26c.46-.44.68-1.1.55-1.8-.13-.72-.69-1.33-1.39-1.53-1.11-.31-2.14.32-2.47 1.27-.12.37-.43.65-.82.65h-.3C8.4 9 8 8.44 8.16 7.88c.43-1.47 1.68-2.59 3.23-2.83 1.52-.24 2.97.55 3.87 1.8 1.18 1.63.83 3.38-.19 4.4z"
        />
      </svg>
      <p>Contact Support</p>
    </button>
    <button
      class="reset-btn in-logout"
      @click.stop.prevent="$emit('logoutUser')"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M74.7726 73.9604C76.2631 75.451 78.6709 75.451 80.1615 73.9604L93.8821 60.2398C95.3726 58.7493 95.3726 56.3415 93.8821 54.8509L80.1615 41.1303C78.6709 39.6398 76.2631 39.6398 74.7726 41.1303C73.2821 42.6209 73.2821 45.0287 74.7726 46.5192L81.9578 53.7426H48.8219C46.7199 53.7426 45 55.4625 45 57.5645C45 59.6665 46.7199 61.3864 48.8219 61.3864H81.9578L74.7726 68.5716C73.2821 70.0621 73.3203 72.5081 74.7726 73.9604Z"
          fill="url(#logout1234)"
        />
        <path
          d="M35 10C29.4771 10 25 14.4772 25 20V100C25 105.523 29.4771 110 35 110H65C70.5228 110 75 105.523 75 100V90C75 87.2386 72.7614 85 70 85C67.2386 85 65 87.2386 65 90V95C65 97.7614 62.7614 100 60 100H40C37.2386 100 35 97.7614 35 95V25C35 22.2386 37.2386 20 40 20H60C62.7614 20 65 22.2386 65 25V30C65 32.7614 67.2386 35 70 35C72.7614 35 75 32.7614 75 30V20C75 14.4772 70.5228 10 65 10H35Z"
          fill="url(#logout1234)"
        />
        <defs>
          <linearGradient
            id="logout1234"
            x1="25"
            y1="10"
            x2="106.407"
            y2="20.3594"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#00CAFF" />
            <stop offset="1" stop-color="#4F81FF" />
          </linearGradient>
          <linearGradient
            id="logout1234"
            x1="25"
            y1="10"
            x2="106.407"
            y2="20.3594"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#00CAFF" />
            <stop offset="1" stop-color="#4F81FF" />
          </linearGradient>
        </defs>
      </svg>
      <p>Logout</p>
    </button>
  </div>
</template>
<script>
export default {
  name: 'DropdownCard',
  props: {
    username: {
      type: String,
      required: true,
      default: ''
    }
  },
  data() {
    return {
      showTransition: false
    }
  },
  mounted() {
    setTimeout(() => {
      this.showTransition = true
    }, 0)
  }
}
</script>

<style lang="scss" scoped>
.dropdown-card {
  position: absolute;
  top: 52px;
  right: 0%;
  background-color: var(--black-2);
  box-shadow: var(--box-shadow-3);
  border-radius: 8px;
  width: 240px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0.75rem 0.5rem;
  border: var(--border-2);
  animation: scale-height 0.6s var(--ease-out-quint) 1 forwards;
  .reset-btn,
  a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 1.5rem 0.75rem 0.75rem;
  }
  button {
    border-radius: 8px;
  }
  p {
    margin-left: 0.5rem;
    color: var(--grey-6);
  }
  svg {
    position: relative;
    top: -2px;
  }
  path {
    fill: var(--grey-6);
  }
  .dark-toggle {
    cursor: default;
    &:hover {
      background: unset;
    }
  }

  > *:hover {
    background: var(--grey-2);
    border-radius: 8px;
  }
}

@media (max-width: 600px) {
  .dropdown-card {
    right: 0px;
  }
}
</style>
