import { mapActions } from 'vuex'

import ListJobApplications from '@/graphql/applications/ListJobApplications.gql'
import GET_JOB from '@/graphql/jobs/GetJob.gql'

export default {
  data() {
    return {
      showPage: false,
      jobDetails: {
        status: '',
        salaryRange: {}
      }
    }
  },

  methods: {
    ...mapActions('message', ['handleCurrentMessenger']),
    async getJob() {
      return await this.graphqlQuery({
        query: GET_JOB,
        variables: {
          jobId: this.$route.params.applicationId,
          updateLastChecked: true
        }
      })
    },
    async listJobApplications() {
      return await this.graphqlQuery({
        query: ListJobApplications,
        variables: {
          jobId: this.$route.params.applicationId,
          updateLastChecked: true
        }
      })
    }
  }
}
