<template>
  <overlay-wrapper
    :show-inner-modal="showInnerModal"
    @hideInnerModal="hideInnerModal"
  >
    <transition name="scale-in" appear="">
      <div v-if="showInnerModal" class="overlay__content modal in-share-post">
        <img
          v-if="post.coverImage && post.coverImage.url"
          class="share-post-banner"
          :src="post.coverImage.url"
          :alt="post.title"
        />
        <div class="share-post-details">
          <p>Share</p>
          <h4>{{ post.title }}</h4>
          <div class="flex">
            <button
              class="share-post-tab"
              @click.stop.prevent="shareToTwitter(post)"
            >
              <svg width="60" height="60">
                <use width="60" height="60" href="#twitter" />
              </svg>
              <p>Twitter</p>
            </button>
            <button
              class="share-post-tab"
              @click.stop.prevent="shareToFacebook(post)"
            >
              <svg width="60" height="60">
                <use width="60" height="60" href="#facebook" />
              </svg>
              <p>Facebook</p>
            </button>
            <button
              class="share-post-tab"
              @click.stop.prevent="copyLink(post.linkUrl)"
            >
              <svg width="60" height="60">
                <use width="60" height="60" href="#copy-link" />
              </svg>
              <p>{{ copyLinkText }}</p>
            </button>
          </div>
        </div>
      </div>
    </transition>
  </overlay-wrapper>
</template>

<script>
import socialShare from '../../mixins/socialShare'
import innerModalMixin from '@/mixins/innerModal'

export default {
  name: 'SharePostModal',
  mixins: [innerModalMixin, socialShare],
  data() {
    return {
      copyLinkText: 'Copy link'
    }
  },
  computed: {
    post() {
      return this.$store.state.sharePostModal.post
    }
  },
  methods: {
    hideInnerModal() {
      this.showInnerModal = false
      setTimeout(() => {
        this.$store.commit('toggleSharePostModal', { show: false, post: {} })
      }, 300)
    },
    copyLink(url) {
      this._copyLink(url)
      this.copyLinkText = 'Copied'
      setTimeout(() => {
        this.copyLinkText = 'Copy link'
      }, 3000)
    }
  }
}
</script>

<style lang="scss" scoped>
@include popup-modal;
.modal {
  padding: 0px 0px 1rem;
  overflow: hidden;
  h4 {
    margin-bottom: var(--spacing-md);
    text-align: center;
  }
  p {
    color: var(--grey-5);
  }
  .share-post-banner {
    width: 100%;
    height: auto;
    max-height: 270px;
    object-fit: cover;
  }
  .share-post-details {
    padding: 2rem;
    width: 100%;
    @include flex($direction: column);
  }
  .share-post-tab {
    cursor: pointer;
    @include flex($direction: column);
    @include reset-btn;
    outline: none;
    width: 72px;
    svg {
      transition: var(--transition-1);
      margin-bottom: var(--spacing-sm);
      color: var(--black-2);
      &:hover {
        transform: scale(1.05);
      }
    }
    &:not(:last-child) {
      margin-right: 1.5rem;
    }
  }
  .copy-link {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    border-radius: 8px;
    overflow: hidden;
    input {
      padding: 1rem 0.75rem;
      width: 100%;
      cursor: text;
    }
    button {
      width: 100px;
    }
  }
}
</style>
