<template>
  <div v-click-outside="hideOptions" class="select-field__cont">
    <label class="label" :for="name">
      {{ label }}
      <span>(Tech stack, skills or tools)</span>
    </label>
    <div class="select-field">
      <div :class="['select-field__input', focused ? 'focused' : '']">
        <input
          :id="name"
          ref="skills"
          :type="type"
          :value="value"
          autocomplete="off"
          spellcheck="false"
          :placeholder="placeholder"
          @focus="focused = true"
          @input="$emit('input', $event.target.value)"
          @keydown.stop="addSkillFromInput($event)"
        />
      </div>
      <div
        v-show="value && focused && options.length"
        class="select-field__options"
      >
        <p
          v-for="option in options"
          :key="option.id"
          class="list-item"
          :value="option.id"
          @click="updateSkills(option)"
        >
          {{ option.name }}
        </p>
      </div>
    </div>
    <div v-if="skills.length > 0" :class="['array-items', bgColor]">
      <div v-for="skill in skills" :key="skill.id">
        <img
          v-if="skill.logoUploadUrl"
          class="skill__logo"
          :src="skill.logoUploadUrl"
          alt
        />
        <span v-else class="skill__no-icon"></span>
        <p>{{ skill.name }}</p>
        <img
          class="array-items__close"
          role="button"
          src="@/assets/icons/round-close-24px.svg"
          alt="Del"
          @click.prevent.stop="removeSkill(skill)"
        />
      </div>
    </div>
    <div class="error-message">
      <p>{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkillInputField',
  props: {
    label: {
      type: String,
      default: ''
    },
    errorMessage: {
      type: String,
      default: '',
      required: false
    },
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    name: {
      type: String,
      default: '',
      required: false
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    value: {
      type: [String, Number, Array],
      required: false,
      default: ''
    },
    options: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    bgColor: {
      type: String,
      default: '',
      required: false
    },
    skills: {
      type: Array,
      required: false,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      focused: false
    }
  },
  methods: {
    updateSkills(option) {
      this.$emit('update', option)
      setTimeout(() => {
        this.$refs.skills.focus()
      }, 100)
    },
    hideOptions() {
      this.focused = false
    },
    removeSkill(skill) {
      this.$emit('removeSkill', skill)
      this.focused = false
    },
    addSkillFromInput(event) {
      if (
        event.keyCode &&
        event.keyCode !== 9 &&
        event.keyCode !== 13 &&
        event.keyCode !== 188
      ) {
        return
      } else if (
        event.key &&
        event.key !== 'Enter' &&
        event.key !== ',' &&
        event.key !== 'Tab'
      ) {
        return
      }
      event.preventDefault()
      const { value } = event.target
      if (!value) return
      const isAlreadyAdded = this.skills.some(
        (skill) => skill.name === value.toLowerCase()
      )
      if (isAlreadyAdded) {
        this.$emit('input', '')
      } else {
        this.$emit('addSkillFromInput', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@include select-field;
.skill {
  &__add-new {
    width: 100%;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      color: var(--blue-7);
      background-color: var(--black-2);
    }
    span,
    button {
      font-size: 15px;
    }
  }
  &__logo {
    object-fit: contain;
    width: 18px;
    height: 18px;
    box-sizing: content-box;
    margin-right: 0.25rem;
  }
  &__no-icon {
    width: 12px;
    margin-right: 0.25rem;
    height: 12px;
    border-radius: 50%;
    background: var(--blue-6);
  }
  &__close-icon {
    margin-left: 1rem;
    cursor: pointer;
    height: 0.8rem;
  }
}
.array-items {
  @include array-items;
}
</style>
