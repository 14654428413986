import DELETE_JOB from '@/graphql/jobs/DeleteJob.gql'
import EDIT_JOB from '@/graphql/jobs/EditJob.gql'
import UpdateStatus from '@/components/jobs/UpdateStatus'
import DeleteJob from '@/components/jobs/DeleteJob'

export default {
  components: {
    UpdateStatus,
    DeleteJob
  },
  data() {
    return {
      showConfirmDelete: false,
      showUpdateStatus: false,
      btnDisabled: false
    }
  },

  methods: {
    handleConfirmDelete(id) {
      this.currentJob = { id }
      this.showConfirmDelete = true
    },
    handleUpdateStatus(id) {
      this.currentJob = { id }
      this.showUpdateStatus = true
    },

    handleDelete(value) {
      if (value) {
        this.deleteJob(this.currentJob?.id || this.jobDetails?.id)
      }
    },
    editJob(id) {
      this.$router.push({ name: 'edit-job', params: { jobId: id } })
    },
    async updateStatus({ status, jobId }) {
      try {
        this.btnDisabled = true
        await this.graphqlMutation({
          mutation: EDIT_JOB,
          variables: {
            jobId,
            company: this.companyId,
            input: {
              status
            }
          }
        })
        this.showUpdateStatus = false
        if (this.jobDetails) {
          this.jobDetails.status = status
        }
        this.showNotifyBar({
          message: 'Job status updated',
          type: 'success'
        })
      } catch (error) {
        this.showNotifyBar({
          message: 'Unable to job status',
          type: 'error'
        })
      } finally {
        this.btnDisabled = false
      }
    },
    async deleteJob(id) {
      try {
        const data = await this.graphqlMutation({
          mutation: DELETE_JOB,
          variables: {
            jobId: id,
            company: this.companyId
          }
        })
        if (data && data.deleteJob && data.deleteJob.code === 200) {
          this.showNotifyBar({
            message: 'Job successfully deleted',
            type: 'success'
          })
          if (this.jobs?.length) {
            this.jobs = this.jobs.filter((item) => item.id !== id)
          }
          this.$router.push({ name: 'dashboard' }).catch(() => {})
        }
      } catch (error) {
        this.showNotifyBar({
          message: 'An error occured, try again',
          type: 'error'
        })
      }
    }
  }
}
