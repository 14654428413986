<template>
  <div>
    <page-info
      class="u-no-mobile"
      :title="info.title"
      :description="info.description"
      :list="info.list"
    />
    <div v-if="showPageMobileInfoModal" class="u-mobile">
      <overlay-wrapper
        :show-inner-modal="showInnerModal"
        @hideInnerModal="hideInnerModal"
      >
        <transition name="scale-in" appear>
          <page-info
            v-if="showInnerModal"
            class="overlay__content modal"
            :title="info.title"
            :description="info.description"
            :list="info.list"
          />
        </transition>
      </overlay-wrapper>
    </div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="0"
      height="0"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <slot />
      </defs>
    </svg>
  </div>
</template>

<script>
import innerModalMixin from '@/mixins/innerModal'

import PageInfo from '@/components/common/PageInfo.vue'

export default {
  name: 'PageInfoContainer',
  components: {
    PageInfo
  },
  mixins: [innerModalMixin],
  props: {
    info: {
      type: Object,
      required: true,
      default() {
        return {
          title: '',
          description: '',
          list: []
        }
      }
    },
    showPageMobileInfoModal: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  methods: {
    hideInnerModal() {
      this.showInnerModal = false
      setTimeout(() => {
        this.$emit('close')
        this.showInnerModal = true
      }, 300)
    }
  }
}
</script>

<style lang="scss" scoped>
@include popup-modal;
svg path {
  fill: var(--grey-2);
}
svg.stroke path {
  fill: none;
  stroke: var(--grey-2);
}
</style>
