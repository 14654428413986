<template>
  <div class="page--has-info">
    <is-loading v-if="!showPage || $apollo.loading" />
    <div v-else class="dashboard-content">
      <search-bar />
      <div>
        <div v-if="!jobs.length" class="empty-content">
          <empty-timeline class="bal" />
          <h4>Ready to start hiring?</h4>
          <p>
            Click on "Post a job" to list your vacancies and start receiving
            applications from talents. You can also search through our talent
            pool to find creatives based on their skill set or project.
          </p>
        </div>
        <div v-else>
          <h3>Recent job posts</h3>
          <div class="job__cont">
            <job-item
              v-for="job in jobs"
              :key="job.id"
              v-bind="job"
              @deleteJob="handleConfirmDelete"
              @updateStatus="handleUpdateStatus"
              @editJob="editJob"
            />
          </div>
          <delete-job
            v-if="showConfirmDelete"
            @click="handleDelete"
            @hideModal="showConfirmDelete = false"
          />
          <update-status
            v-if="showUpdateStatus"
            :job-id="currentJob.id"
            :btn-disabled="btnDisabled"
            @click="updateStatus"
            @hideModal="showUpdateStatus = false"
          />
        </div>
      </div>
    </div>
    <page-info v-bind="info">
      <!-- <verification-required v-if="!company.domainEmailVerified" /> -->
    </page-info>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import EmptyTimeline from '../components/illustrations/EmptyTimeline.vue'
// import VerificationRequired from '../components/auth/VerificationRequired.vue'
import PageInfo from '@/components/common/PageInfo.vue'

import JobItem from '@/components/jobs/JobItem'
import LIST_JOB from '@/graphql/jobs/ListJob.gql'
import jobUpdateMixin from '@/mixins/jobUpdate'
import SearchBar from '@/components/common/SearchBar'
// import Activities from '@/components/dashboard/Activities.vue'

export default {
  name: 'Dashboard',
  components: {
    JobItem,
    SearchBar,
    PageInfo,
    EmptyTimeline
    // Activities
    // VerificationRequired
  },
  mixins: [jobUpdateMixin],
  data() {
    return {
      btnDisabled: false,
      jobs: [],
      currentJob: {},
      showPage: false,
      info: {
        title: 'Tabulio Space Highlights!',
        list: [
          {
            text: 'Job post - List your vacancies to reach a large pool of talents and get applications from interested candidates',
            svg: 'create-collab-idea'
          },
          {
            text: 'Talent pool - Search through our pool to discover talents by skill-set or type of projects they have worked on',
            svg: 'create-collab-look'
          },
          {
            text: 'Shortlist - Select qualified candidates from the long list of applicants',
            svg: 'create-collab-start'
          },
          {
            text: 'Interview stages - Tabulio Space automatically creates kanban boards for your interview stages, which allow you to move candidates from one stage to another with ease',
            svg: 'create-collab-start'
          }
        ]
      }
    }
  },
  metaInfo: {
    title: 'Space | Dashboard'
  },
  apollo: {
    listJob: {
      query: LIST_JOB,
      variables() {
        return {
          company: this.companyId
        }
      },
      fetchPolicy: 'network-only',
      skip() {
        return !this.companyId
      },
      result({ data, error }) {
        if (error) {
          this.showNotifyBar({
            message: `Unable to get jobs`,
            type: 'error'
          })
        } else if (data && data.listJob) {
          this.showPage = true
          this.jobs = data.listJob
        }
      }
    }
  },
  computed: {
    ...mapState(['company'])
  },
  methods: {
    ...mapMutations('auth', ['showModal'])
  }
}
</script>

<style lang="scss" scoped>
h3 {
  font-size: 1.5rem;
  margin-bottom: var(--spacing-base);
}

.job__cont {
  display: flex;
  flex-direction: column;
  width: 600px;
}
.empty-content {
  @include flex($direction: column);
  height: 70vh;
  .bal {
    width: 270px;
    margin-bottom: 1rem;
    height: auto;
  }
  p {
    max-width: 420px;
    margin-bottom: 2rem;
    color: var(--grey-5);
  }
  p,
  h4 {
    text-align: center;
  }
  h4 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}
</style>
