export default {
  props: {
    width: {
      type: String,
      required: false,
      default: '20'
    },
    hasBgClr: {
      type: Boolean,
      required: false,
      default: false
    },
    color: {
      type: String,
      required: false,
      default: '#ffffff'
    },
    height: {
      type: String,
      required: false,
      default: '20'
    }
  }
}
