<template>
  <div class="messages">
    <div class="message-tabs">
      <message-side-bar-item
        v-for="messenger in messengers"
        :id="messenger.id"
        :key="messenger.id"
        :first-name="messenger.firstName"
        :last-name="messenger.lastName"
        :is-online="messenger.isOnline"
        :username="messenger.username"
        :image="messenger.image"
        :modal-active-user="toUser.id"
        :messages="messenger.messages"
        @handleCurrentMessenger="
          handleCurrentMessenger({
            show: true,
            id: messenger.id
          })
        "
      />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import MessageSideBarItem from './MessageSideBarItem'

export default {
  name: 'MessageSideBar',
  components: {
    MessageSideBarItem
  },
  computed: {
    ...mapState('message', ['messengers', 'toUser'])
  },
  methods: {
    ...mapMutations('message', ['handleCurrentMessenger'])
  }
}
</script>

<style lang="scss" scoped>
.messages {
  width: 272px;
  position: fixed;
  top: 56px;
  left: 240px;
  z-index: 1;
  height: 100%;
  background-color: var(--grey-1);
  .message-tabs {
    height: 100%;
    padding: 1rem 0rem;
    overflow-y: auto;
    @include scrollbar;
  }
}
</style>
