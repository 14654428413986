<template>
  <div class="modal__body in-successful">
    <form>
      <icon-update width="80" height="80" color="url(#greengradient)" />
      <h4>Verification Successful</h4>
      <p>
        Congratulations! Now you can start hiring on Tabulio. Search for talents
        by their skill-set or post a job to start receiving applications.
      </p>
      <div class="flex align-center">
        <base-button text="Post a job" class="mr-md" @click="triggerPostJob" />
        <base-button type="secondary" text="Search" @click="triggerSearch" />
      </div>
    </form>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import IconUpdate from '../icons/IconUpdate.vue'
import innerModal from '@/mixins/innerModal'

export default {
  name: 'DomainSuccessful',
  components: { IconUpdate },

  mixins: [innerModal],
  methods: {
    ...mapMutations('auth', ['hideModals']),
    triggerPostJob() {
      this.$emit('hideModals')
      this.$router.push({ name: 'create-job' })
    },
    triggerSearch() {
      this.$router.push({ name: 'talent-pool' })
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-transparent-alt {
  margin-bottom: 0rem;
  width: 132px;
}
</style>
