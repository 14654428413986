<template>
  <div :class="['icon-cont', { 'has-background': hasBgClr }]">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 24 24"
      :width="width"
      :height="height"
      viewBox="0 0 24 24"
    >
      <g><rect fill="none" height="24" width="24" /></g>
      <g>
        <path
          d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M11.39,16.93V13.5H9.08c-0.38,0-0.62-0.4-0.44-0.73 l3.17-5.99c0.24-0.46,0.94-0.29,0.94,0.23v3.49h2.18c0.37,0,0.62,0.4,0.44,0.73l-3.04,5.93C12.1,17.62,11.39,17.45,11.39,16.93z"
          :fill="color"
        />
      </g>
    </svg>
  </div>
</template>

<script>
import iconMixins from './_iconMixins'
export default {
  mixins: [iconMixins]
}
</script>

<style lang="scss" scoped>
.icon-cont {
  @include flex;
}
.has-background {
  @include flex;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: var(--grey-8);
  transition: all 0.3s var(--ease-out-quint);
  &:hover {
    background-color: var(--grey-7);
  }
}
</style>
