<template>
  <overlay-wrapper
    :show-inner-modal="showInnerModal"
    @hideInnerModal="hideInnerModal"
  >
    <transition name="slide-to-left" appear>
      <div
        v-if="showInnerModal"
        :class="['notification-dropdown overlay__content']"
      >
        <div class="side-panel__header">
          <p class="heavy bold">Notifications</p>
          <button class="close-modal" @click.stop="hideInnerModal">
            <svg
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <use href="#closeicon" width="24" height="24" />
            </svg>
          </button>
        </div>
        <div v-if="notifications.length > 0" class="notification-list">
          <notification-dropdown-item
            v-for="notification in notifications"
            :id="notification.id"
            :key="notification.id"
            :details="notification.details"
            :created-at="notification.createdAt"
            :is-read="notification.isRead"
            :type="notification.type"
            @navigateToProfile="navigateToProfile($event, notification.id)"
            @openMessage="openMessage($event, notification.id)"
            @openApplication="openApplication($event, notification.id)"
          />
        </div>
        <div v-else class="notification-empty">
          <p>You do not have any notification</p>
        </div>
        <div v-intersect="fetchMore" class="notification-end">
          <div v-if="showLoader">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </overlay-wrapper>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import NotificationDropdownItem from './NotificationDropdownItem'
import UPDATE_NOTIFICATION_TO_READ from '@/graphql/notifications/UpdateNotificationToRead.gql'
import innerModal from '@/mixins/innerModal'
import { EventBus } from '@/utils/event-bus'
import intersect from '@/directives/intersection'

export default {
  name: 'NotificationDropdown',
  components: {
    NotificationDropdownItem
  },
  directives: {
    intersect
  },
  mixins: [innerModal],
  data() {
    return {
      showLoader: false
    }
  },
  computed: {
    ...mapState(['notifications', 'notificationCursor'])
  },
  watch: {
    'notifications.length'() {
      this.showLoader = false
    }
  },
  methods: {
    ...mapMutations(['markNotificationAsRead']),
    ...mapMutations('message', ['handleCurrentMessenger']),
    hideInnerModal() {
      this.showInnerModal = false
      setTimeout(() => {
        this.$emit('hideNotifications')
      }, 500)
    },
    navigateToProfile(username, id) {
      this.goToProfile(username)
      this.markAsRead(id)
    },
    openApplication(applicationId, id) {
      this.$router
        .push({ name: 'applications', params: { applicationId } })
        .catch((e) => {})
      this.markAsRead(id)
    },
    openMessage(user, id) {
      this.handleCurrentMessenger({
        show: true,
        id: user
      })
      this.markAsRead(id)
    },
    fetchMore(isDown) {
      if (!this.notificationCursor.hasMore || !isDown) return
      this.showLoader = true
      EventBus.$emit('fetchMore', true)
    },
    async markAsRead(id) {
      try {
        this.hideInnerModal()
        await this.graphqlMutation({
          mutation: UPDATE_NOTIFICATION_TO_READ,
          variables: {
            userId: this.userId,
            notificationId: id
          }
        })
        this.markNotificationAsRead(id)
      } catch (error) {}
    }
  }
}
</script>

<style lang="scss" scoped>
@include close-modal;
.close-modal {
  position: fixed;
  top: 1.25rem;
  right: 1.5rem;
  background: var(--color-grey--5);
}
.notification-dropdown {
  @include scrollbar;
  overflow-y: auto;
  position: fixed;
  width: 380px;
  top: 0px;
  right: 0px;
  z-index: 250;
  padding: 0.5rem;
  max-width: 100%;
  height: 100vh;
  background-color: var(--black-2);
  &::-webkit-scrollbar-track {
    border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
  }
}
.side-panel__header {
  padding: 1rem;
  @include flex($main: space-between);
}
.notification-empty {
  padding: 1rem 1.5rem;
  height: 300px;
  p {
    font-size: 14px;
  }
}
.notification-end {
  @include flex;
  padding: 1rem;
}
.tablet-trigger {
  display: none;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 132px;
  height: 36px;
}
.lds-ellipsis div {
  position: absolute;
  top: 15px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--blue-gradient);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 30px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 30px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 50px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 70px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(20px, 0);
  }
}

#app.dark-theme {
  .lds-ellipsis div {
    background: var(--color-white);
  }
}
</style>
