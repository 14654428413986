<template>
  <div :class="['icon-cont', { 'has-background': hasBgClr }]">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      viewBox="0 0 24 24"
    >
      <path
        d="M9 16.2L5.5 12.7C5.11 12.31 4.49 12.31 4.1 12.7C3.71 13.09 3.71 13.71 4.1 14.1L8.29 18.29C8.68 18.68 9.31 18.68 9.7 18.29L20.3 7.70001C20.69 7.31001 20.69 6.69001 20.3 6.30001C19.91 5.91001 19.29 5.91001 18.9 6.30001L9 16.2Z"
        :fill="color"
      />
    </svg>
  </div>
</template>

<script>
import iconMixins from './_iconMixins'
export default {
  mixins: [iconMixins]
}
</script>

<style lang="scss" scoped>
.icon-cont {
  @include flex;
}
.has-background {
  @include flex;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: var(--grey-8);
  transition: all 0.3s var(--ease-out-quint);
  &:hover {
    background-color: var(--grey-7);
  }
}
</style>
