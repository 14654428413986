<template>
  <div class="page--has-sidebar">
    <is-loading v-if="!showPage" />
    <div v-else>
      <div class="header">
        <back-arrow @click="$router.go(-1)" />
        <h4>{{ jobDetails.title }}</h4>
        <router-link
          class="link"
          :to="{ name: 'shortlists', params: $route.params.applicationId }"
        >
          View Shortlists
        </router-link>
        <div
          class="dropdown-container"
          @click.stop.prevent="showDropdown = true"
        >
          <dropdown-icon type="big" @click="showDropdown = true" />
          <dropdown-card
            v-if="showDropdown"
            v-click-outside="hideDropdown"
            :lists="dropdownList"
            @editJob="editJob"
            @deleteJob="handleConfirmDelete"
            @updateStatus="handleUpdateStatus"
          />
        </div>
      </div>
      <section class="flex w100">
        <div v-if="!showPage" class="application__loading">
          <loading-component />
        </div>
        <div
          v-else-if="!applications.length"
          class="fullsize application__empty"
        >
          <img src="@/assets/illustrations/not-found.svg" alt="" />
          <h4>Hang in there!</h4>
          <p>You will see applications here once they start coming in.</p>
        </div>
        <div v-else class="application__cont">
          <application-item
            v-for="application in applications"
            :key="application.id"
            :questions="application.questions"
            :applicant-first-name="application.applicant.firstName"
            :username="application.applicant.username"
            :applicant-last-name="application.applicant.lastName"
            :applicant-id="application.applicant.id"
            :job-title="application.applicant.jobTitle"
            :applicant-skills="application.applicant.skills"
            :profile-image="application.applicant.image"
            :portfolio="application.portfolio"
            :resume-url="application.resume && application.resume.fileUrl"
            :short-listed="application.shortListed"
            @shortListApplication="
              shortListApplication(application.id, application.shortListed)
            "
            @handleCurrentMessenger="
              handleCurrentMessenger({
                show: true,
                id: application.applicant.id
              })
            "
          />
        </div>
      </section>
    </div>
    <job-details :job-details="jobDetails" />
    <delete-job
      v-if="showConfirmDelete"
      @click="handleDelete"
      @hideModal="showConfirmDelete = false"
    />
    <update-status
      v-if="showUpdateStatus"
      :btn-disabled="btnDisabled"
      :job-id="$route.params.applicationId"
      @click="updateStatus"
      @hideModal="showUpdateStatus = false"
    />
  </div>
</template>

<script>
import JobDetails from '../components/applications/JobDetails.vue'
import jobUpdateMixin from '@/mixins/jobUpdate'
import applicationsMixin from '@/mixins/applications'
import ApplicationItem from '@/components/applications/ApplicationItem'
import ShortListApplication from '@/graphql/applications/ShortListApplication.gql'

export default {
  name: 'Application',
  components: {
    ApplicationItem,
    JobDetails
  },
  mixins: [jobUpdateMixin, applicationsMixin],
  data() {
    return {
      showDropdown: false,
      applications: [],
      dropdownList: [
        { type: 'action', text: 'Change status', action: 'updateStatus' },
        { type: 'action', text: 'Edit job', action: 'editJob' },
        { type: 'action', text: 'Delete', action: 'deleteJob' }
      ]
    }
  },
  metaInfo() {
    return {
      title: `Applications for ${this.jobDetails.title}`
    }
  },
  async created() {
    try {
      const [jobRes, applicationListRes] = await Promise.all([
        this.getJob(),
        this.listJobApplications()
      ])
      this.jobDetails = jobRes.getJob
      this.applications = applicationListRes.listJobApplications
      this.showPage = true
    } catch (error) {
      this.showNotifyBar({
        message: 'Unable to get job',
        type: 'error'
      })
    }
  },
  methods: {
    hideDropdown() {
      this.showDropdown = false
    },
    async shortListApplication(id, shortListed) {
      try {
        const application = this.applications.find((a) => a.id === id)
        application.shortListed = !shortListed
        await this.graphqlMutation({
          mutation: ShortListApplication,
          variables: {
            applicationId: id,
            shortListed: application.shortListed
          }
        })
        const action = shortListed ? 'removed from' : 'moved to'
        this.showNotifyBar({
          message: `User has been ${action} shortlists`,
          type: 'success'
        })
      } catch (error) {
        this.showNotifyBar({
          message: 'Unable to get short list application',
          type: 'error'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.application__cont {
  display: flex;
  flex-direction: column;
  width: 600px;
}
.application__loading {
  width: 600px;
}
.header {
  @include flex($main: flex-start);
  margin-bottom: 2rem;
  align-self: flex-start;
  h4 {
    font-size: 1.5rem;
    text-transform: capitalize;
  }
}
.fullsize {
  @include flex($direction: column);
  width: 600px;
  flex: 0 0 600px;
  padding-top: 100px;
  img {
    width: 400px;
    margin-bottom: 1rem;
    height: auto;
  }
  p {
    color: var(--grey-5);
  }
}
.dropdown-container {
  position: relative;
}
.link {
  margin-left: auto;
  color: var(--blue-6);
  margin-right: var(--spacing-base);
  &:hover {
    text-decoration: underline;
  }
}
</style>
