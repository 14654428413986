<template>
  <a target="_blank" :href="postUrl" class="card">
    <div class="card__img">
      <img
        :src="getImage(coverImageUrl, { size: 'medium', name: title })"
        alt=""
        srcset=""
      />
      <div class="type-icon">
        <svg width="24" height="24">
          <use :href="typeIcon" width="24" height="24" />
        </svg>
      </div>
    </div>
    <div class="card__content">
      <p>{{ summary || description }}</p>
    </div>
  </a>
</template>

<script>
export default {
  name: 'ApplicationCard',
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    summary: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: ''
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
    id: {
      type: String,
      required: true,
      default: ''
    },
    coverImageUrl: {
      type: String,
      required: true,
      default: ''
    }
  },
  computed: {
    postUrl() {
      const str =
        (this.title && this.title.toLowerCase().replace(/\s+/g, '+')) || ''
      return `${process.env.VUE_APP_USER_FRONTEND}/posts/${this.id}/${str}`
    },
    typeIcon() {
      const obj = {
        video: '#videotab',
        picture: '#picturetab',
        blog: '#writetab'
      }
      return obj[this.type]
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  cursor: pointer;
  width: 50%;
  &__img {
    width: 100%;
    height: 180px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.5rem 0.5rem 0rem 0rem;
    }
    .type-icon {
      position: absolute;
      left: 0.5rem;
      top: 0.5rem;
      z-index: 10;
    }
  }
  &__content {
    background: var(--grey-1);
    padding: 1rem;
    border-radius: 0rem 0rem 0.5rem 0.5rem;
    p {
      color: var(--grey-5);
    }
  }
  &:first-child {
    margin-right: 1.5rem;
  }
}
</style>
