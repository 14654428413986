<template>
  <div v-if="showPage" class="page--has-info">
    <div>
      <search-bar />
      <section class="flex w100">
        <div class="searchResults in-searchResults">
          <page-tabs
            :tabs="tabs"
            :active-tab="activeTab"
            @setActiveTab="setActiveTab"
          />
          <list-users
            :users="searchResults"
            :content-loading="isLoading"
            action-type="search"
          />
        </div>
      </section>
    </div>
    <page-info-container :info="info" :show-page-mobile-info-modal="false" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ListUsers from '../components/common/ListUsers.vue'
import CompanySearchPosts from '@/graphql/posts/CompanySearchPosts.gql'
import GetTopPosts from '@/graphql/posts/GetTopPosts.gql'
import CompanySearchUsers from '@/graphql/users/CompanySearchUsers.gql'
import GetTopUsers from '@/graphql/users/GetTopUsers.gql'
import SearchBar from '@/components/common/SearchBar'
import PageInfoContainer from '@/components/common/PageInfoContainer.vue'
import PageTabs from '@/components/common/PageTabs'

const cursor = {
  limit: 20,
  hasMore: false,
  currentCount: 0,
  totalCount: 0
}

export default {
  name: 'TalentPool',
  components: {
    SearchBar,
    ListUsers,
    PageTabs,
    PageInfoContainer
  },
  data() {
    return {
      showPage: true,
      activeTab: 'Projects',
      cursor: {
        ...cursor
      },
      searchResults: [],
      isLoading: true,
      tabs: [
        {
          name: 'Projects',
          value: 'projects',
          width: 105.98
        },
        {
          name: 'Users',
          value: 'users',
          width: 87.93
        }
      ],
      info: {
        title: 'Discover the tech talent you are looking for right away!',
        description:
          'Make a quick hire without creating a job post, search through our talent pool by project type or skill-set',
        list: [
          {
            text: 'Type in the word that best describes the skill-set or talent you are looking for (e.g vue.js, web3, fintech, figma, designer, etc.) in the search bar',
            svg: 'create-collab-idea'
          },
          {
            text: 'Toggle between the "Projects" or "Users" option to look through the project pool or user pool',
            svg: 'create-collab-look'
          },
          {
            text: 'Go through the results and assess profiles through their portfolio',
            svg: 'create-collab-start'
          },
          {
            text: 'Message talents to start a conversation with them',
            svg: 'create-collab-start'
          }
        ]
      }
    }
  },
  metaInfo: {
    title: 'Space | Talent Pool'
  },
  watch: {
    '$route.query.text'() {
      this.cursor = { ...cursor }
      this.handleSearch()
    }
  },

  created() {
    if (this.$route.query.text) {
      this.searchPosts()
    } else {
      this.getTopPosts()
    }
  },
  mounted() {
    document.querySelector('#searchbar').focus()
  },
  methods: {
    ...mapActions('message', ['handleCurrentMessenger']),
    setActiveTab(e) {
      this.cursor = { ...cursor }
      this.activeTab = e.name
      this.handleSearch()
    },
    handleSearch() {
      const e = this.activeTab
      const text = this.$route.query.text
      if (e === 'Users' && text) {
        this.searchUsers()
      } else if (e === 'Projects' && text) {
        this.searchPosts()
      } else if (e === 'Users') {
        this.getTopUsers()
      } else {
        this.getTopPosts()
      }
    },
    async searchUsers() {
      try {
        const { __typename, ...cursor } = this.cursor
        this.isLoading = true
        const data = await this.graphqlQuery({
          query: CompanySearchUsers,
          variables: {
            text: this.$route.query.text,
            cursor
          }
        })
        this.isLoading = false
        this.searchResults = data.companySearchUsers.users
        this.cursor = data.companySearchUsers.cursor
        scrollTo(0, 0)
      } catch (error) {
        this.showNotifyBar({
          message: 'Unable to get users',
          type: 'error'
        })
      }
    },
    async getTopUsers() {
      try {
        const { __typename, ...cursor } = this.cursor
        this.isLoading = true
        const data = await this.graphqlQuery({
          query: GetTopUsers,
          variables: {
            text: this.$route.query.text,
            cursor
          }
        })
        this.isLoading = false
        this.searchResults = data.getTopUsers.users
        this.cursor = data.getTopUsers.cursor
        scrollTo(0, 0)
      } catch (error) {
        this.showNotifyBar({
          message: 'Unable to get users',
          type: 'error'
        })
      }
    },
    async searchPosts() {
      try {
        const { __typename, ...cursor } = this.cursor
        this.isLoading = true
        const data = await this.graphqlQuery({
          query: CompanySearchPosts,
          variables: {
            text: this.$route.query.text,
            cursor
          }
        })
        const arr = data.companySearchPosts.posts
        this.searchResults = arr.map((item) => {
          return {
            ...item.poster,
            posts: [item]
          }
        })
        this.isLoading = false
        this.cursor = data.companySearchPosts.cursor
        scrollTo(0, 0)
      } catch (error) {
        this.showNotifyBar({
          message: 'Unable to get posts',
          type: 'error'
        })
      }
    },
    async getTopPosts() {
      try {
        const { __typename, ...cursor } = this.cursor
        this.isLoading = true
        const data = await this.graphqlQuery({
          query: GetTopPosts,
          variables: {
            cursor
          }
        })
        const arr = data.getTopPosts.posts
        this.searchResults = arr.map((item) => {
          return {
            ...item.poster,
            posts: [item]
          }
        })
        this.isLoading = false
        this.cursor = data.getTopPosts.cursor
        scrollTo(0, 0)
      } catch (error) {
        this.showNotifyBar({
          message: 'Unable to get posts',
          type: 'error'
        })
      }
    },
    goToPage(page) {
      this.cursor.currentCount = (page - 1) * this.cursor.limit
      this.handleSearch()
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0rem 10%;
  @include flex($direction: column, $main: flex-start);
}
.header {
  @include flex($main: flex-start);
  margin-bottom: 3rem;
  width: 600px;
  align-self: flex-start;
  h4 {
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: capitalize;
  }
}
.searchResults {
  display: flex;
  flex-direction: column;
  width: 600px;
}
.advert {
  width: 300px;
  min-height: 360px;
  height: fit-content;
  background: var(--grey-8);
  border-radius: 1rem;
  margin-left: auto;
  position: relative;
  z-index: 2;
  padding: 1.5rem 1.5rem 2rem;
  background: var(--purple-gradient);
  &:after {
    top: -50px;
  }
  &::before {
    top: -100px;
    right: -20px;
  }
  p {
    color: white;
    margin-bottom: 1rem;
    &::before {
      content: '\25CF';
      margin-right: 0.25rem;
    }
  }
}
.content-empty {
  width: 600px;
  height: 60vh;
  img {
    width: 300px;
    max-width: 300px;
  }
}
.loader {
  min-height: 60vh;
  flex: 1;
}
</style>
