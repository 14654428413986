<template>
  <div :class="['searchbar', isFocused ? 'is-focused' : '']">
    <input
      id="searchbar"
      ref="searchbar"
      v-model.trim="input"
      type="search"
      class="in-searchbar"
      placeholder="Search our talent pool"
      spellcheck="false"
      autocomplete="off"
      @keyup.enter="search"
    />
  </div>
</template>

<script>
import searchMixin from '../bars/_searchMixin'

export default {
  name: 'SearchBar',
  mixins: [searchMixin],
  data() {
    return {
      input: '',
      isFocused: false
    }
  }
}
</script>

<style lang="scss" scoped>
.searchbar {
  width: 600px;
  position: relative;
  margin-bottom: 3rem;
  background: var(--grey-1);
  border: var(--border-2);
  border-radius: 24px;
  box-shadow: var(--box-shadow-3);
  input {
    padding: 1rem 2rem;
    font-size: 2rem;
    width: 100%;
    line-height: 100%;
    background: transparent;
    border-radius: inherit;
    vertical-align: middle;
    outline: none;
    &::placeholder {
      color: var(--grey-5);
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: var(--grey-5);
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: var(--grey-5);
    }
  }
}
.searchbar-text {
  display: none;
}
@media (max-width: 1200px) {
  .searchbar {
    width: 200px;
  }
}
@media (max-width: 900px) {
  .searchbar {
    width: 120px;
    transition: var(--transition-1);
    &.is-focused {
      width: 300px;
    }
  }
  .searchbar-text {
    display: block;
    p {
      color: white;
      font-size: 1rem;
    }
  }
}

@media (max-width: 600px) {
  .searchbar {
    display: none;
  }
}
</style>
