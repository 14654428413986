export default {
  mounted() {
    this.input = this.$route.query.text
  },
  methods: {
    search() {
      if (!this.input) return
      const input = this.input
      const sliced = input.slice(1)
      const route = input.startsWith('@')
        ? `/talent-pool?text=${sliced}&filter=users`
        : input.startsWith('#')
        ? `/talent-pool?text=${sliced}&filter=posts`
        : `/talent-pool?text=${input.split(' ').join('_')}`
      // eslint-disable-next-line no-unused-vars
      this.$router.push(route).catch((err) => {})
    },

    focusOrSearch() {
      if (!this.input) {
        this.$refs.searchbar.focus()
      } else {
        this.search()
      }
    }
  }
}
