<template>
  <div :class="['skill__cont', tagColor]">
    <div
      v-for="(skill, index) in formatSkills"
      :key="index"
      :class="['skill__item', tagColor]"
    >
      <img
        v-if="showIcon && skill.logoUploadUrl"
        :src="skill.logoUploadUrl"
        class="skill__icon"
        alt
        srcset
      />
      <div v-if="showIcon && !skill.logoUploadUrl" class="skill__no-icon"></div>
      <p v-if="showName">
        {{ skill.name }}
      </p>
      <div v-if="showTooltip" :class="['skill__tooltip']">
        <p>{{ skill.name }}</p>
      </div>
    </div>
    <div v-if="hasMoreSkills" :class="['skill__item', tagColor]">
      <p>
        {{ remainderCount }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormSkillArray',
  props: {
    skills: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    tagColor: {
      type: String,
      required: false,
      default: ''
    },
    showTooltip: {
      type: Boolean,
      required: false,
      default: false
    },
    showRemainder: {
      type: Boolean,
      required: false,
      default: false
    },
    showIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    showName: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    maxTagNum() {
      return this.isMobile ? 3 : 4
    },
    formatSkills() {
      if (this.hasMoreSkills) {
        return this.skills.slice(0, this.maxTagNum - 1)
      }
      return this.skills
    },
    remainderCount() {
      return `+${this.skills.length - this.maxTagNum + 1}`
    },
    hasMoreSkills() {
      return this.showRemainder && this.skills.length > this.maxTagNum
    }
  }
}
</script>

<style lang="scss" scoped>
.skill {
  &__cont {
    display: flex;
    flex-wrap: wrap;
    margin-top: -0.5rem;
    margin-bottom: 0.25rem;
    &.no-bg {
      height: unset;
      margin-bottom: 0rem;
    }
  }
  &__icon {
    object-fit: contain;
    width: 24px;
    margin-right: 0.25rem;
    height: 24px;
    &:hover + .skill__tooltip {
      opacity: 1;
    }
  }
  &__no-icon {
    width: 12px;
    margin-right: 0.25rem;
    height: 12px;
    border-radius: 50%;
    background: var(--blue-6);
  }

  &__tooltip {
    position: absolute;
    opacity: 0;
    top: 2rem;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1;
    border-radius: 20px;
    padding: 0.25rem 0.75rem;
    backdrop-filter: blur(2px);
    left: 50%;
    transform: translateX(-50%);

    p {
      color: var(--white);
    }
  }
  &__item {
    padding: 0rem 1rem 0rem 0.75rem;
    border-radius: 1rem;
    height: 2rem;
    line-height: 1.5rem;
    margin-right: 0.5rem;
    display: flex;
    margin-top: 0.5rem;
    align-items: center;
    background-color: var(--grey-2);
    position: relative;
    transition: var(--transition-1);
    &.no-bg {
      padding: 0rem 0.25rem;
      background-color: transparent;
    }
    &.grey--2 {
      background-color: var(--grey-1);
    }
    &.off-white {
      background-color: var(--white);
    }
    p {
      color: var(--grey-6);
    }
  }
}
#app.dark-theme {
  .skill__item {
    &.grey--2 {
      background: var(--black-2);
    }
    &.off-white {
      background-color: var(--opacity-black-10);
    }
  }
}
</style>
