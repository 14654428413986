import { mapState } from 'vuex'
import SkillInputField from '@/components/common/SkillInputField'

export default {
  components: {
    SkillInputField
  },
  data() {
    return {
      currentSkill: '',
      skillResults: [],
      skillOptions: []
    }
  },
  computed: {
    ...mapState(['allSkills'])
  },
  watch: {
    currentSkill(text) {
      if (text) {
        this.skillOptions = this.allSkills
          .filter((item) => item.name.startsWith(text.toLowerCase()))
          .filter(
            (item) => !this.state.skills.map((i) => i.name).includes(item.name)
          )
          .slice(0, 5)
      } else {
        this.skillResults = []
      }
    }
  },
  methods: {
    updateSkills(skill) {
      this.currentSkill = ''
      if (skill) {
        this.state.skills = this.state.skills.concat(skill)
      }
    },
    addSkillFromInput(text) {
      if (!text) return
      let s = this.allSkills.find(
        (i) => i.name.toLowerCase() === text.toLowerCase()
      )
      if (s) {
        this.state.skills = this.state.skills.concat(s)
      } else {
        s = {
          id: `new-skill--${text}`,
          name: text,
          logoUploadId: null,
          logoUploadUrl: null
        }
        this.state.skills = this.state.skills.concat(s)
      }
      this.currentSkill = ''
    },
    removeSkill(skill) {
      this.state.skills = this.state.skills.filter((s) => s.id !== skill.id)
    }
  }
}
