<template>
  <div class="modal__body in-modal-domain">
    <form @submit.prevent="sendCode" @input="monitorErrors">
      <h4>Company Verification</h4>
      <p>
        The company email and website must be on the same domain. A verification
        code will be sent to the email address.
      </p>
      <input-field
        v-model.trim="state.website"
        placeholder="https://www.abcd.com"
        name="website"
        label="Website"
        :error-message="errorMessages.website"
      />
      <input-field
        v-model.trim="state.email"
        placeholder="admin@abcd.com"
        name="email"
        label="Company Email"
        :error-message="errorMessages.email"
      />
      <base-button
        text="Send Code"
        class="mt-md"
        :is-loading="btnDisabled"
        @click="sendCode"
      />
    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { mapMutations } from 'vuex'
import { INVALID_DOMAIN_EMAIL } from '../../utils/constants'
import VerifyCompanyEmail from '@/graphql/companies/VerifyCompanyEmail.gql'
import innerModal from '@/mixins/innerModal'

export default {
  name: 'DomainVerification',
  mixins: [innerModal, validationMixin],
  data() {
    return {
      btnDisabled: false,
      showErrors: false,
      errorMessages: {
        email: '',
        website: ''
      },
      state: {
        email: '',
        website: ''
      }
    }
  },
  methods: {
    ...mapMutations('auth', ['showModal']),
    async sendCode() {
      try {
        this.showErrors = true
        const isValid = await this.checkErrors()
        if (!isValid) return
        this.btnDisabled = true
        await this.graphqlMutation({
          mutation: VerifyCompanyEmail,
          variables: {
            companyId: this.companyId,
            domainEmail: this.state.email,
            website: this.state.website
          }
        })
        this.showModal('domainConfirmation')
      } catch (error) {
        const message = error.message.includes(INVALID_DOMAIN_EMAIL)
          ? 'The website and email are not on the same domain'
          : 'Unable to send verification code, try again'
        this.showNotifyBar({
          message,
          type: 'error'
        })
      } finally {
        this.btnDisabled = false
      }
    },
    monitorErrors() {
      if (this.showErrors) this.checkErrors()
    },
    checkErrors() {
      const arr = ['email', 'website']
      arr.forEach((value) => {
        switch (value) {
          case 'email':
            if (!this.$v.state.email.required || !this.$v.state.email.email) {
              this.errorMessages.email = 'Please enter a valid email address'
            } else {
              this.errorMessages.email = ''
            }
            break
          case 'website':
            if (!this.$v.state.website.required) {
              this.errorMessages.website = `Please provide the company's website`
            } else {
              this.errorMessages.website = ''
            }
            break
          default:
            break
        }
      })
      return !this.$v.state.$invalid
    }
  },
  validations: {
    state: {
      email: {
        required,
        email
      },
      website: {
        required
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
