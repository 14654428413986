<template>
  <div>
    <div class="details editor__cont">
      <label>{{ label }} (Optional)</label>
      <div ref="container" class="editor"></div>
    </div>
    <div class="error-message">
      <p>{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextEditor',
  props: {
    label: {
      type: String,
      default: '',
      required: true
    },
    errorMessage: {
      type: String,
      default: '',
      required: false
    },
    content: {
      type: String,
      default: '',
      required: false
    }
  },
  data() {
    return {
      editor: null
    }
  },
  mounted() {
    const Quill = require('quill')

    this.editor = new Quill(this.$refs.container, {
      theme: 'snow',
      debug: 'error',
      placeholder: 'Click and start editing content...'
    })
    this.editor.on('text-change', () => {
      this.$emit('editEditorContent', this.editor.root.innerHTML)
    })
    this.editor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
      const ops = []
      delta.ops.forEach((op) => {
        if (op.insert && typeof op.insert === 'string') {
          ops.push({
            insert: op.insert
          })
        }
      })
      delta.ops = ops
      return delta
    })
    setTimeout(() => {
      if (this.content) {
        this.editor.root.innerHTML = this.content
      }
    }, 1000)
  }
}
</script>
<style src="quill/dist/quill.core.css">
/* quill core styles */
</style>
<style src="quill/dist/quill.snow.css">
/* quill snow styles */
</style>
<style lang="scss">
.details.editor__cont {
  label {
    margin-bottom: 0.25rem;
  }
  .editor {
    min-height: 200px;
    border-radius: 0px 0px 10px 10px;
    border: var(--border-1);
  }
  .ql-toolbar.ql-snow {
    border-radius: 10px 10px 0px 0px;
    margin-top: 0.25rem;
    border: var(--border-1);
    background: var(--blue-3);
  }
  .ql-toolbar.ql-snow + .ql-container {
    background: var(--blue-3);
  }
  .ql-snow .ql-stroke {
    stroke: var(--grey-6);
  }
  .ql-editor.ql-blank::before {
    color: var(--grey-6);
    font-style: normal;
  }
  .ql-snow .ql-picker-options {
    background: var(--grey-2);
    border-radius: var(--border-radius-xs);
    border: none;
  }
}
</style>
