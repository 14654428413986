<template>
  <div v-click-outside="hideOptions" class="select-field__cont">
    <label :for="name">{{ label }}</label>
    <div class="select-field">
      <div
        :class="['select-field__input', showDropdown ? 'focused' : '']"
        @click.stop="toggleDropdown"
      >
        <input v-model="location.address" :placeholder="placeholder" />
        <button
          v-show="location.address"
          class="reset-btn"
          @click.stop.prevent="resetAddress"
        >
          <img src="@/assets/icons/round-close-24px.svg" alt="close" />
        </button>
      </div>
      <div
        v-show="showDropdown && options.length"
        class="select-field__options"
      >
        <p
          v-for="option in options"
          :key="option.placeId"
          :value="option.placeId"
          @click.stop="selectOption(option)"
        >
          {{ option.address }}
        </p>
        <div class="google">
          <img src="@/assets/custom-icons/google-powered.png" alt="" />
        </div>
      </div>
    </div>
    <div v-if="showErrorMessage" class="error-message">
      <p>{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import GetLocation from '@/graphql/common/GetLocation.gql'

export default {
  name: 'LocationField',
  props: {
    label: {
      type: String,
      default: ''
    },
    errorMessage: {
      type: String,
      default: '',
      required: false
    },
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    name: {
      type: String,
      default: '',
      required: true
    },
    showErrorMessage: {
      type: Boolean,
      required: false,
      default: true
    },
    currentLocation: {
      type: Object,
      required: false,
      default() {
        return {
          placeId: ''
        }
      }
    }
  },
  data() {
    return {
      showDropdown: false,
      location: {
        address: '',
        placeId: ''
      },
      isSelection: false,
      options: []
    }
  },
  watch: {
    async 'location.address'(text) {
      try {
        if (this.isSelection) return
        if (!text) {
          this.options = []
          this.showDropdown = false
          return
        }
        const data = await this.graphqlQuery({
          query: GetLocation,
          variables: {
            text
          }
        })
        this.options = data.getLocation
        if (this.options.length) {
          this.showDropdown = true
        }
      } catch (error) {}
    }
  },
  created() {
    this.isSelection = true
    setTimeout(() => {
      this.isSelection = false
    }, 500)
    this.location = this.currentLocation
  },
  methods: {
    selectOption(option) {
      const { address, placeId } = option
      this.location = { address, placeId }
      this.$emit('update', this.location)
      this.isSelection = true
      setTimeout(() => {
        this.isSelection = false
      }, 500)
      this.showDropdown = false
    },
    resetAddress() {
      this.location = {
        placeId: '',
        address: ''
      }
      this.$emit('update', this.location)
    },
    hideOptions() {
      this.showDropdown = false
    },
    toggleDropdown() {
      if (!this.showDropdown) {
        this.showDropdown = true
      } else {
        this.showDropdown = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@include select-field;
.select-field__options {
  max-height: 280px;

  .google {
    @include flex($main: flex-end);
    padding: 1rem;
    img {
      width: 120px;
    }
  }
}
.select-field__input {
  padding-right: 3.5rem;
  input {
    text-transform: capitalize;
  }
  button {
    position: absolute;
    right: 0.5rem;
    @include flex;
    top: 50%;
    background: var(--grey-2);
    border-radius: 4px;
    padding: 0.5rem;
    transform: translateY(-50%);
    img {
      width: 1.5rem;
    }
  }
}
</style>
