<template>
  <div :class="['notification-item', { read: isRead }]">
    <div
      v-if="type === 'follow'"
      @click.stop="$emit('navigateToProfile', details.fromUser.username)"
    >
      <div class="notification-icon">
        <svg
          id="follow"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="11.964"
          height="11.964"
          viewBox="0 0 11.964 11.964"
        >
          <defs>
            <linearGradient
              id="linear-gradient-follow"
              x1="0.5"
              x2="-0.244"
              y2="1.543"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#9ad8ff" />
              <stop offset="1" stop-color="#7240f9" />
            </linearGradient>
          </defs>
          <g
            id="Group_2008"
            data-name="Group 2008"
            transform="translate(7.509)"
          >
            <g id="Group_2007" data-name="Group 2007">
              <path
                id="Path_2813"
                data-name="Path 2813"
                d="M325.439,1.877h-1.527V.351a.35.35,0,1,0-.7,0V1.877h-1.527a.351.351,0,1,0,0,.7h1.527V4.1a.35.35,0,1,0,.7,0V2.578h1.527a.351.351,0,1,0,0-.7Z"
                transform="translate(-321.334)"
                fill="url(#linear-gradient-follow)"
              />
            </g>
          </g>
          <g
            id="Group_2010"
            data-name="Group 2010"
            transform="translate(0 7.509)"
          >
            <g id="Group_2009" data-name="Group 2009">
              <path
                id="Path_2814"
                data-name="Path 2814"
                d="M4.1,321.334a4.109,4.109,0,0,0-4.1,4.1.351.351,0,0,0,.351.351H7.859a.351.351,0,0,0,.351-.351A4.109,4.109,0,0,0,4.1,321.334Z"
                transform="translate(0 -321.334)"
                fill="url(#linear-gradient-follow)"
              />
            </g>
          </g>
          <g
            id="Group_2012"
            data-name="Group 2012"
            transform="translate(1.877 1.502)"
          >
            <g id="Group_2011" data-name="Group 2011">
              <path
                id="Path_2815"
                data-name="Path 2815"
                d="M82.562,64.267a2.228,2.228,0,1,0,2.228,2.228A2.23,2.23,0,0,0,82.562,64.267Z"
                transform="translate(-80.334 -64.267)"
                fill="url(#linear-gradient-follow)"
              />
            </g>
          </g>
        </svg>

        <img
          v-if="details.fromUser.image.url"
          :src="
            getImage(details.fromUser.image.url, {
              size: 'small',
              name: details.fromUser.firstName
            })
          "
          alt=""
        />
      </div>
      <div :class="['details', { read: isRead }]">
        <p>
          <span class="creator"> </span>
          {{ details.fromUser.firstName }}
          {{ details.fromUser.lastName }}
          followed you
        </p>
        <p>{{ formatDate(createdAt) }} ago</p>
      </div>
    </div>
    <div
      v-if="type === 'application'"
      @click.stop="$emit('openApplication', details.job.id)"
    >
      <div class="notification-icon">
        <svg
          width="24"
          height="24"
          viewBox="0 0 121 121"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M30.1439 10.906C24.621 10.906 20.1439 15.3831 20.1439 20.906V100.906C20.1439 106.429 24.621 110.906 30.1439 110.906H90.1439C95.6667 110.906 100.144 106.429 100.144 100.906V20.906C100.144 15.3831 95.6667 10.906 90.1439 10.906H30.1439ZM45.2621 80.9966L52.7615 76.6654L45.2608 72.3364L45.2621 80.9966ZM50.2963 72.1393L54.5678 74.6054L77.1016 35.5756C78.4636 33.2165 77.6554 30.2 75.2963 28.838C72.9373 27.476 69.9208 28.2843 68.5588 30.6433L46.0249 69.6732L50.2963 72.1393ZM35.1439 85.906C32.3824 85.906 30.1439 88.1445 30.1439 90.906C30.1439 93.6674 32.3824 95.906 35.1439 95.906H85.1439C87.9053 95.906 90.1439 93.6674 90.1439 90.906C90.1439 88.1445 87.9053 85.906 85.1439 85.906H35.1439Z"
            fill="url(#application1234)"
          />
          <defs>
            <linearGradient
              id="application1234"
              x1="20.1439"
              y1="10.906"
              x2="112.729"
              y2="24.3709"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#64FFBF" />
              <stop offset="1" stop-color="#00CCFF" />
            </linearGradient>
          </defs>
        </svg>
        <img
          v-if="details.fromUser.image.url"
          :src="
            getImage(details.fromUser.image.url, {
              size: 'small',
              name: details.fromUser.firstName
            })
          "
          alt=""
        />
      </div>
      <div :class="['details', { read: isRead }]">
        <p>
          <span class="creator">
            {{ details.fromUser.firstName }}
            {{ details.fromUser.lastName }}
          </span>
          applied for
          <span class="creator">
            {{ details.job && details.job.title }}
          </span>
        </p>
        <p>{{ formatDate(createdAt) }} ago</p>
      </div>
    </div>
    <div
      v-else-if="type === 'message' && details.fromCompany"
      @click.stop="$emit('openMessage', details.fromCompany.id)"
    >
      <div class="notification-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fill="url(#purplegradient)"
            d="M20 6h-1v8c0 .55-.45 1-1 1H6v1c0 1.1.9 2 2 2h10l4 4V8c0-1.1-.9-2-2-2zm-3 5V4c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v13l4-4h9c1.1 0 2-.9 2-2z"
          />
        </svg>
        <img
          v-if="details.fromCompany.logo.url"
          :src="
            getImage(details.fromCompany.logo.url, {
              size: 'small',
              name: details.fromCompany.companyName
            })
          "
          alt=""
        />
      </div>
      <div :class="['details', { read: isRead }]">
        <p>
          <span class="creator">
            {{ details.fromCompany.companyName }}
          </span>

          sent you a message
        </p>
        <p>{{ formatDate(createdAt) }} ago</p>
      </div>
    </div>
    <div
      v-else-if="type === 'message'"
      @click.stop="$emit('openMessage', details.fromUser.id)"
    >
      <div class="notification-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fill="url(#bluegradient)"
            d="M20 6h-1v8c0 .55-.45 1-1 1H6v1c0 1.1.9 2 2 2h10l4 4V8c0-1.1-.9-2-2-2zm-3 5V4c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v13l4-4h9c1.1 0 2-.9 2-2z"
          />
        </svg>
        <img
          v-if="details.fromUser.image.url"
          :src="
            getImage(details.fromUser.image.url, {
              size: 'small',
              name: details.fromUser.firstName
            })
          "
          alt=""
        />
      </div>
      <div :class="['details', { read: isRead }]">
        <p>
          <span class="creator">
            {{ details.fromUser.firstName }}
            {{ details.fromUser.lastName }}
          </span>

          sent you a message
        </p>
        <p>{{ formatDate(createdAt) }} ago</p>
      </div>
    </div>
    <div
      v-else-if="type === 'like'"
      @click.stop="$emit('showPost', details.post)"
    >
      <div class="notification-icon">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="url(#redgradient)"
            d="M13.3497 20.13C12.5897 20.82 11.4197 20.82 10.6597 20.12L10.5497 20.02C5.29967 15.27 1.86966 12.16 1.99966 8.28004C2.05966 6.58004 2.92966 4.95004 4.33966 3.99004C6.97967 2.19004 10.2397 3.03004 11.9997 5.09004C13.7597 3.03004 17.0197 2.18004 19.6597 3.99004C21.0697 4.95004 21.9397 6.58004 21.9997 8.28004C22.1397 12.16 18.6997 15.27 13.4497 20.04L13.3497 20.13Z"
          />
        </svg>
        <img
          v-if="details.fromUser.image.url"
          :src="
            getImage(details.fromUser.image.url, {
              size: 'small',
              name: details.fromUser.firstName
            })
          "
          alt=""
        />
      </div>
      <div :class="['details', { read: isRead }]">
        <p>
          <span class="creator">
            {{ details.fromUser.firstName }}
            {{ details.fromUser.lastName }}
          </span>
          liked your post
          <span class="creator">
            {{ details.post && details.post.title }}
          </span>
        </p>
        <p>{{ formatDate(createdAt) }} ago</p>
      </div>
    </div>
    <div
      v-else-if="type === 'comment'"
      @click.stop="$emit('showPost', details.post)"
    >
      <div class="notification-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fill="url(#greengradient)"
            d="M21.99 4c0-1.1-.89-2-1.99-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4-.01-18zM17 14H7c-.55 0-1-.45-1-1s.45-1 1-1h10c.55 0 1 .45 1 1s-.45 1-1 1zm0-3H7c-.55 0-1-.45-1-1s.45-1 1-1h10c.55 0 1 .45 1 1s-.45 1-1 1zm0-3H7c-.55 0-1-.45-1-1s.45-1 1-1h10c.55 0 1 .45 1 1s-.45 1-1 1z"
          />
        </svg>
        <img
          v-if="details.fromUser.image.url"
          :src="
            getImage(details.fromUser.image.url, {
              size: 'small',
              name: details.fromUser.firstName
            })
          "
          alt=""
        />
      </div>
      <div :class="['details', { read: isRead }]">
        <p>
          <span class="creator">
            {{ details.fromUser.firstName }}
            {{ details.fromUser.lastName }}
          </span>

          commented on your post
          <span class="creator">
            {{ details.post && details.post.title }}
          </span>
        </p>
        <p>{{ formatDate(createdAt) }} ago</p>
      </div>
    </div>
    <div
      v-else-if="type === 'repost'"
      @click.stop="$emit('showPost', details.post)"
    >
      <div class="notification-icon">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="url(#bluegradient)"
            d="M14.4075 9.00002V7.41002C14.4075 6.52002 15.4875 6.07002 16.1175 6.70002L20.7075 11.29C21.0975 11.68 21.0975 12.31 20.7075 12.7L16.1175 17.29C15.4875 17.92 14.4075 17.48 14.4075 16.59V14.9C9.4075 14.9 5.9075 16.5 3.4075 20C4.4075 15 7.4075 10 14.4075 9.00002Z"
          />
        </svg>
        <img
          v-if="details.fromUser.image.url"
          :src="
            getImage(details.fromUser.image.url, {
              size: 'small',
              name: details.fromUser.firstName
            })
          "
          alt=""
        />
      </div>
      <div :class="['details', { read: isRead }]">
        <p>
          <span class="creator">
            {{ details.fromUser.firstName }}
            {{ details.fromUser.lastName }}
          </span>
          reshared your post
          <span class="creator">
            {{ details.post && details.post.title }}
          </span>
        </p>
        <p>{{ formatDate(createdAt) }} ago</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotificationDropdownItem',
  props: {
    type: {
      type: String,
      required: true,
      default: ''
    },
    details: {
      type: undefined,
      required: true,
      default() {
        return {
          fromUser: {
            image: {}
          },
          post: {
            title: ''
          },
          job: {},
          fromCompany: {
            logo: {}
          }
        }
      }
    },
    id: {
      type: String,
      required: true,
      default: ''
    },
    createdAt: {
      type: String,
      required: true,
      default: ''
    },
    isRead: {
      type: Boolean,
      required: true,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.notification-item {
  > div {
    display: flex;
    padding: 0.75rem;
    border-radius: 0.5rem;
    cursor: pointer;
    &:hover {
      background: var(--color-grey--2);
    }
    &:not(:last-child) {
      border-bottom: var(--border-line);
    }
    p,
    span {
      font-size: 14px;
    }
    p {
      flex: 1;
    }
    .text {
      width: 100%;
      padding-right: 0.5rem;
    }
  }
}

.notification-icon {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  position: relative;
  border: 2px solid var(--color-grey--5);
  @include flex;
  svg {
    width: 20px;
    height: 20px;
  }
  img {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    position: absolute;
    right: -0.5rem;
    bottom: -0.5rem;
  }
}
.notification-item.read {
  .notification-icon {
    border-color: var(--color-grey--3);
  }
}
.details {
  flex: 1;
  p:nth-child(2) {
    font-size: 12px;
    color: var(--color-grey--5);
  }
  p {
    color: var(--color-grey--5);
  }
  .creator {
    color: var(--color-grey);
  }
}
#app.dark-theme {
  .notification-icon {
    border-color: var(--color-white);
  }
  .notification-item.read {
    .notification-icon {
      border-color: rgba(255, 255, 255, 0.1);
    }
  }
  .details {
    p:nth-child(2) {
      color: var(--color-grey--4);
    }
    p {
      color: var(--color-grey--4);
    }
    .creator {
      color: var(--color-grey);
    }
  }
}
</style>
