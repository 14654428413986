export default {
  data() {
    return {
      showPlaceholder: true
    }
  },
  methods: {
    tagUsername(element) {
      element.addEventListener('input', (e) => {
        if (e.data === ' ') {
          element.innerHTML = this.formatTextWithUsername(element.textContent)
          this.setEndOfContenteditable(element)
        }
      })
    },
    formatTextWithUsername(text) {
      if (!text) return ''
      return text.replace(
        /(\B@\w+)/gi,
        (x) =>
          `<span style="color: var(--blue-7);font-weight: bold;font-size: inherit;">${x}</span>`
      )
    },
    togglePlaceholder(element) {
      const content = element.textContent
      if (content.length === 0) {
        this.showPlaceholder = true
      } else {
        this.showPlaceholder = false
      }
    },
    loginput(elementRef) {
      this.togglePlaceholder(elementRef)
    },
    setEndOfContenteditable(contentEditableElement) {
      let range
      let selection
      if (document.createRange) {
        // Firefox, Chrome, Opera, Safari, IE 9+
        range = document.createRange() // Create a range (a range is a like the selection but invisible)
        range.selectNodeContents(contentEditableElement) // Select the entire contents of the element with the range
        range.collapse(false) // collapse the range to the end point. false means collapse to end rather than the start
        selection = window.getSelection() // get the selection object (allows you to change selection)
        selection.removeAllRanges() // remove any selections already made
        selection.addRange(range) // make the range you have just created the visible selection
      } else if (document.selection) {
        // IE 8 and lower
        range = document.body.createTextRange() // Create a range (a range is a like the selection but invisible)
        range.moveToElementText(contentEditableElement) // Select the entire contents of the element with the range
        range.collapse(false) // collapse the range to the end point. false means collapse to end rather than the start
        range.select() // Select the range (make it the visible selection
      }
    }
  }
}
