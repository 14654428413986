<template>
  <transition name="slide-up" appear>
    <div class="message-modal">
      <div class="modal-header">
        <div class="img-wrapper">
          <poster-thumbnail
            v-if="toUser.id"
            :username="toUser.username"
            :image="toUser.image"
            size="extra-small"
            padding="nopadding"
          />
        </div>
        <p>{{ toUser.firstName }} {{ toUser.lastName }}</p>
        <div class="message-actions">
          <button
            id="close-button"
            data-test-id="close-message"
            @click.stop="handleCurrentMessenger({ show: false })"
          >
            <svg
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <use href="#closeicon" width="24" height="24" />
            </svg>
          </button>
        </div>
      </div>
      <div ref="messageContents" class="modal-content">
        <transition-group tag="div" name="message-in">
          <message-modal-item
            v-for="(content, index) in currentMessages"
            :key="content.id"
            :content="content"
            :show-date="showDate(index)"
          />
        </transition-group>
      </div>
      <div class="message-input-cont">
        <form
          id="message-modal__input"
          class="modal__input"
          @submit.stop.prevent="postMessage"
        >
          <label class="message-modal-button attach-image" for="attach-image">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              enable-background="new 0 0 24 24"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <g>
                <path
                  d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M6.6,16.2l2-2.67 c0.2-0.27,0.6-0.27,0.8,0L11.25,16l2.6-3.47c0.2-0.27,0.6-0.27,0.8,0l2.75,3.67c0.25,0.33,0.01,0.8-0.4,0.8H7 C6.59,17,6.35,16.53,6.6,16.2z"
                />
              </g>
            </svg>
            <input
              id="attach-image"
              accept="image/*"
              type="file"
              @change="attachImage"
            />
          </label>
          <div
            ref="messageInput"
            data-test-id="message-input"
            class="text"
            contenteditable
            @keypress.enter.prevent="postMessage"
            @input="loginput($refs.messageInput)"
          />
          <p
            v-show="showPlaceholder"
            class="placeholder u-color-grey--5"
            @click="$refs.messageInput.focus()"
          >
            Type a message...
          </p>
          <button @click.stop="postMessage">
            <img src="@/assets/custom-icons/send-message.svg" alt srcset />
          </button>
        </form>
      </div>
    </div>
  </transition>
</template>

<script>
import getMessagesMixin from './_getMessagesMixin'
import atUsernameMixin from '@/mixins/atUsername'

export default {
  name: 'MessageModal',
  mixins: [getMessagesMixin, atUsernameMixin]
}
</script>

<style lang="scss" scoped>
.message-modal {
  width: 380px;
  position: fixed;
  display: flex;
  flex-direction: column;
  right: 4rem;
  border-radius: 16px 16px 0px 0px;
  background-color: var(--black-2);
  box-shadow: var(--box-shadow-3);
  border: var(--border-2);
  bottom: 0;
  z-index: 50;
  height: 474px;
  max-height: 100vh;
  background-clip: padding-box;
}
.modal-header {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 16px 16px 0px 0px;
  padding: 0.75rem;
  height: 54px;
  z-index: 5;
}

.modal-content {
  width: 100%;
  background-color: var(--message-modal-bg);
  border-radius: 8px;
  flex: 1;
  padding: 0.75rem;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0.5rem;
  }
  &::-webkit-scrollbar-track {
    border-radius: 0px 8px 8px 0px;
    background-color: var(--grey-1);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0px 8px 8px 0px;
    background-color: rgb(201, 201, 201);
  }
}
.message-modal-button {
  @include flex;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  cursor: pointer;
  transition: var(--transition-1);
  background: var(--grey-1);
  svg {
    height: 20px;
    width: 20px;
    fill: var(--grey-6);
  }
  &:hover {
    transform: scale(1.1);
    svg {
      fill: var(--white);
    }
  }
  &:not(:last-child) {
    margin-right: 0.75rem;
  }
}
.message-actions {
  display: flex;
  margin-left: auto;
  button {
    @extend .message-modal-button;
  }
}
.img-wrapper {
  margin-right: 0.5rem;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: var(--grey--2);
  img {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    object-fit: cover;
  }
}
.close {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  cursor: pointer;
  img {
    height: 20px;
    width: 20px;
  }
}
.placeholder {
  top: 1.375rem;
  line-height: 150%;
  color: var(--grey-5);
  position: absolute;
  left: calc(2.25rem + 42px);
}
.modal__input {
  @include flex;
  border-radius: 16px;
  z-index: 5;
  padding: 0.75rem;
  position: relative;
  .text {
    @include hide-scroll;
    border-radius: inherit;
    width: calc(100% - 1.5rem - 46px);
    max-width: calc(100% - 1.5rem - 46px);
    margin-right: 0.75rem;
    border-radius: 16px;
    min-height: 36px;
    max-height: 64px;
    background: var(--grey-2);
    flex: 1;
    font-size: 16px;
    padding: 0.5rem 1rem;
    overflow-y: auto;
  }
  button {
    background: var(--blue-gradient);
    @include flex;
    height: 36px;
    width: 36px;
    border-radius: 2rem;
    img {
      width: 2rem;
      height: 2rem;
      object-fit: contain;
    }
  }
  .attach-image {
    margin-right: 0.75rem;
    width: 36px;
    height: 36px;
    background-color: var(--grey-2);
    cursor: pointer;
    input {
      display: none;
    }
  }
}
</style>
