<template>
  <div>
    <div v-if="showDate" class="message-date">
      <p v-if="showDate">{{ readableDate(content.createdAt) }}</p>
    </div>
    <div class="align-flex-end">
      <div
        :class="[
          'message-item',
          `message-item--${content.messageType}`,
          messageAlignment
        ]"
      >
        <div>
          <p
            v-if="content.content"
            class="formatted-text"
            v-html="formatTextContent(content.content)"
          ></p>
          <div
            v-if="content.messageType === 'application'"
            class="message-applications"
          >
            <a
              target="_blank"
              rel="noopener"
              :href="content.details.resume.fileUrl"
              >Resume</a
            >
            <a
              target="_blank"
              rel="noopener"
              :href="extractLink(content.details.portfolio[0])"
              >Project 1</a
            >
            <a
              target="_blank"
              rel="noopener"
              :href="extractLink(content.details.portfolio[1])"
              >Project 2</a
            >
          </div>
          <div v-if="content.messageType === 'image'" class="message-image">
            <a
              target="_blank"
              rel="noopener"
              :href="content.details.images[0].url"
            >
              <img
                :src="
                  getImage(content.details.images[0].url, { size: 'medium' })
                "
                alt
              />
            </a>
          </div>
        </div>
        <span v-if="!isSystemMessage && showTime" class="message-time">
          {{ readableTime(content.createdAt) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageModalItem',
  props: {
    content: {
      type: Object,
      required: true,
      default() {
        return {
          details: {},
          messageType: 'text'
        }
      }
    },
    showDate: {
      type: Boolean,
      required: false,
      default: false
    },
    showTime: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    isFromCurrentUser() {
      const id =
        typeof this.content.fromUser === 'string'
          ? this.content.fromUser
          : this.content.fromUser.id
      return this.companyId === id
    },
    isSystemMessage() {
      return (
        this.content.fromUser === this.content.toUser && !this.content.sender
      )
    },
    messageAlignment() {
      return this.isSystemMessage
        ? 'centralize'
        : this.isFromCurrentUser
        ? 'float-right'
        : 'float-left'
    }
  },
  methods: {
    extractLink(post) {
      return `${process.env.VUE_APP_HOSTNAME}/posts/${post.id}`
    }
  }
}
</script>

<style lang="scss" scoped>
.message-item {
  max-width: 82%;
  display: flex;
  margin-bottom: 0.5rem;
  width: fit-content;
  position: relative;
  min-width: 60px;
  flex-direction: column;
  > div {
    color: var(--white);
    padding: 0.5rem 0.75rem;
    margin-bottom: 0.25rem;
    min-width: 4rem;
    font-size: 14px;
  }
  &.float-left {
    margin-right: auto;
    color: var(--white);
    > div {
      border-radius: 1rem 1rem 1rem 0.25rem;
      background-color: var(--purple-1);
      box-shadow: 0px 0px 10px rgba(0, 202, 255, 0.3);
    }
  }
  &.float-right {
    margin-left: auto;
    color: var(--white);
    & > div {
      border-radius: 1rem 1rem 0.25rem 1rem;
      background-color: var(--blue-7);
      box-shadow: 0px 0px 10px rgba(79, 129, 255, 0.3);
    }
    & > span {
      text-align: right;
    }
  }
  &.centralize {
    margin: 0rem auto 1rem;
    padding: 0;
    @include flex;
    & > div {
      border-radius: 1rem;
      background-color: var(--opacity-black-10);
    }
    p {
      font-size: 12px;
      color: var(--grey-8);
    }
  }
  &--image {
    div {
      padding: 1.5px;
    }
  }
  .message-time {
    font-size: 12px;
  }
}
.message-date {
  border-radius: 1.5rem;
  // background-color: var(--grey-3);
  padding: 0.5rem;
  margin: 1rem auto;
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 12px;
    color: var(--grey-6);
  }
}
.message-applications {
  margin: 0.5rem 0rem;
  display: flex;
  flex-wrap: wrap;
  a {
    text-decoration: none;
    padding: 0.25rem 0.75rem;
    background: var(--white);
    border-radius: 2rem;
    color: var(--grey-3);
    transition: background 0.2s var(--ease-out-quint);

    &:not(:last-child) {
      margin-right: 0.5rem;
    }
    &:hover {
      background: var(--grey-7);
    }
    @media screen and (max-width: 360px) {
      margin-bottom: 0.5rem;
    }
  }
}

.message-image {
  a {
    width: 100%;
    @include flex;
  }
  img {
    height: auto;
    width: 100%;
    border-radius: 0.875rem;
  }
}

.group-sender {
  position: relative;
  bottom: 30px;
  margin-right: 0.25rem;
}
#app.dark-theme {
  .message-date {
    // background-color: var(--grey-3);
    border: var(--border-6);
  }
  .message-item {
    &.float-left {
      & > div {
        box-shadow: none;
      }
    }
    &.float-right {
      & > div {
        box-shadow: none;
      }
    }
  }
}
</style>
