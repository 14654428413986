<template>
  <div :class="['icon-cont', { 'has-background': hasBgClr }]">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      viewBox="0 0 24 24"
    >
      <path
        :fill="color"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"
      />
    </svg>
  </div>
</template>

<script>
import iconMixins from './_iconMixins'
export default {
  mixins: [iconMixins]
}
</script>

<style lang="scss" scoped>
.icon-cont {
  @include flex;
}
.has-background {
  @include flex;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: var(--grey-8);
  transition: all 0.3s var(--ease-out-quint);
  &:hover {
    background-color: var(--grey-7);
  }
}
</style>
