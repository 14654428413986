<template>
  <overlay-wrapper
    :show-inner-modal="showInnerModal"
    @hideInnerModal="hideInnerModal"
  >
    <transition name="scale-in" appear="">
      <div v-if="showInnerModal" class="overlay__content modal">
        <h4>Update job status</h4>
        <p>Click on any of the two options below</p>
        <button
          v-for="option in options"
          :key="option.value"
          :class="[
            { selected: option.value === selectedOption.value },
            'in-option status-btn'
          ]"
          @click="handleSelect(option)"
        >
          <component
            :is="option.comp"
            width="36"
            height="36"
            color="var(--grey-6)"
          />
          <div class="details">
            <h5>{{ option.title }}</h5>
            <p>{{ option.text }}</p>
          </div>
        </button>
        <base-button
          text="Confirm"
          type="primary"
          class="mt-md"
          :is-disabled="!selectedOption.value"
          :is-loading="btnDisabled"
          @click="$emit('click', { jobId, status: selectedOption.value })"
        />
      </div>
    </transition>
  </overlay-wrapper>
</template>

<script>
import IconOpen from '../icons/IconOpen.vue'
import IconClosed from '../icons/IconClosed.vue'
import innerModal from '@/mixins/innerModal'

export default {
  name: 'UpdateStatus',
  components: { IconOpen, IconClosed },
  mixins: [innerModal],
  props: {
    btnDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    jobId: {
      type: String,
      required: true,
      default: ''
    }
  },
  data() {
    return {
      selectedOption: {},
      options: [
        {
          value: 'open',
          title: 'Open',
          comp: 'icon-open',
          text: 'You are actively hiring for this role and it should be visible to new applicants'
        },
        {
          value: 'closed',
          title: 'Closed',
          comp: 'icon-closed',
          text: 'This position is closed and you are not accepting new applications'
        }
      ]
    }
  },
  methods: {
    hideInnerModal() {
      this.showInnerModal = false
      setTimeout(() => {
        this.$emit('hideModal')
      }, 300)
    },
    handleSelect(option) {
      this.selectedOption = option
    }
  }
}
</script>

<style lang="scss" scoped>
@include popup-modal;
.modal {
  @include flex($direction: column);
  padding: 3rem 2rem;
  border-radius: 1.25rem;
  text-align: center;
  height: fit-content;
  h4 {
    font-size: 1.5rem;
  }
  p {
    font-size: 14px;
  }
  .status-btn {
    border: none;
    background: var(--black-2);
    padding: 0.75rem 1rem 1rem 0.75rem;
    margin-bottom: 2rem;
    @include flex($main: flex-start, $cross: flex-start);
    width: 100%;
    border-radius: 0.5rem;
    border: 1px solid transparent;
    &.selected {
      border: 1px solid var(--blue-6);
    }
    &:hover {
      background: var(--grey-2);
    }
    .details {
      flex: 1;
      margin-left: 0.75rem;
    }
    h5,
    p {
      text-align: left;
      color: white;
    }
    p {
      opacity: 0.7;
    }
  }
}
</style>
