<template>
  <div :class="['tab__cont']">
    <div
      v-for="tab in tabs"
      :key="tab.value"
      :class="['tab', tab.value === activeTab ? 'tab--active' : '']"
      @click="$emit('changeTab', tab)"
    >
      <div v-if="tab.value === activeTab" :class="['indicator']"></div>
      <div>
        <h4>{{ tab.name }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SidePanel',
  props: {
    activeTab: {
      type: String,
      required: true
    },
    tabs: {
      required: true,
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tab {
  position: relative;
  padding: 1rem;
  border-radius: 8px;
  margin: 0.75rem 0.75rem 0rem;
  cursor: pointer;
  line-height: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  overflow: hidden;
  &::after {
    content: '';
    height: 100%;
    position: absolute;
    border-radius: inherit;
    left: 0px;
    top: 0px;
    z-index: -1;
    background-color: var(--black-2);
    width: 0%;
  }

  &:hover::after {
    transition: all 0.3s var(--ease-out-quint);
    width: 100%;
  }
  p {
    color: var(--grey-6);
    font-size: 15px;
  }
  .indicator {
    @include absolute;
    width: 4px;
    background: var(--blue-gradient);
  }
  &--active {
    background-color: var(--black-2);
    &::after {
      background: none;
    }
  }
  &__cont {
    display: flex;
    flex-direction: column;
    width: 270px;
    position: fixed;
    top: 56px;
    left: 240px;
    z-index: 20;
    height: calc(100% - 56px);
    background-color: var(--grey-1);
    padding: 4rem 1rem;
  }
}
</style>
