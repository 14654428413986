<template>
  <div class="item in-application">
    <div class="item__details">
      <poster-thumbnail
        :username="username"
        :image="profileImage"
        padding="pl-none"
      />
      <div class="flex-1">
        <div class="item__header">
          <div>
            <p>
              {{ applicantFirstName }}
              {{ applicantLastName }}
            </p>
            <span class="job-title">{{ jobTitle }}</span>
          </div>

          <div class="item__actions">
            <a target="_blank" :href="resumeUrl" rel="noopenner">
              <icon-pdf-small color="url(#greengradient)" class="mr-xsm" />
              <p>Resume</p>
            </a>
            <a target="_blank" :href="userUrl" rel="noopenner">
              <icon-account class="mr-xsm" color="url(#greengradient)" />
              <p>Profile</p>
            </a>
          </div>
        </div>
        <form-skill-array
          v-if="applicantSkills.length && showDetails"
          class="mt-sm"
          tag-color="grey--2"
          show-icon
          show-remainder
          :skills="applicantSkills"
        />
        <div v-if="!showDetails">
          <div v-if="!shortListed" class="flex mb-xsm mt-sm">
            <base-button
              key="1"
              type="primary small"
              class="mr-md"
              text="Shortlist"
              @click="$emit('shortListApplication')"
            />
            <base-button
              key="2"
              type="secondary small"
              text="Message"
              class="mr-md"
              @click="$emit('handleCurrentMessenger')"
            />
            <base-button
              key="4"
              type="secondary small"
              class="mr-md"
              text="Details"
              @click="onShowApplicationDetail"
            />
          </div>
          <div v-else class="flex mb-xsm mt-sm">
            <base-button
              key="3"
              type="primary small"
              class="mr-md"
              text="Message"
              @click="$emit('handleCurrentMessenger')"
            />
            <base-button
              key="4"
              type="secondary small"
              class="mr-md"
              text="Unshortlist"
              @click="$emit('shortListApplication')"
            />
            <base-button
              key="4"
              type="secondary small"
              class="mr-md"
              text="Details"
              @click="onShowApplicationDetail"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="card__cont">
      <application-card
        v-for="a in portfolio"
        :id="a.id"
        :key="a.id"
        :cover-image-url="a.coverImage.url"
        :type="a.type"
        :summary="a.summary"
        :description="a.description"
        :title="a.title"
      />
    </div>
    <div v-if="showDetails && questions.length" class="questions">
      <div v-for="(item, index) in questions" :key="index">
        <p>{{ item.question }}</p>
        <p>{{ item.answer || 'Unanswered' }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import ApplicationCard from './ApplicationCard'
import FormSkillArray from '@/components/common/FormSkillArray'
import IconAccount from '@/components/icons/IconAccount'
import IconPdfSmall from '@/components/icons/IconPdfSmall'

export default {
  name: 'ApplicationItem',
  components: {
    ApplicationCard,
    FormSkillArray,
    IconAccount,
    IconPdfSmall
  },
  props: {
    jobTitle: {
      type: String,
      required: true,
      default: ''
    },
    applicantFirstName: {
      type: String,
      required: true,
      default: ''
    },
    showDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    applicantId: {
      type: String,
      required: true,
      default: ''
    },
    applicantLastName: {
      type: String,
      required: true,
      default: ''
    },
    shortListed: {
      type: Boolean,
      required: true,
      default: false
    },
    username: {
      type: String,
      required: true,
      default: ''
    },
    resumeUrl: {
      type: undefined,
      required: true,
      default: ''
    },
    portfolio: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    questions: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    profileImage: {
      type: Object,
      default() {
        return {
          id: '',
          url: ''
        }
      }
    },
    applicantSkills: {
      type: Array,
      required: false,
      default() {
        return []
      }
    }
  },
  computed: {
    userUrl() {
      return `${process.env.VUE_APP_USER_FRONTEND}/user/${this.username}`
    }
  },
  methods: {
    onShowApplicationDetail() {
      this.$store.commit('onShowApplicationDetail', {
        show: true,
        details: this.$props
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  width: 100%;
  border-radius: 16px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  background: var(--grey-2);
  position: relative;
  z-index: 1;
  &__details {
    display: flex;
  }
  &__img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 0.5rem;
    object-fit: cover;
  }
  &__header {
    @include flex($main: space-between, $cross: flex-start);
    flex: 1;
    margin-bottom: 0.75rem;
  }
  &__actions {
    display: flex;
    margin-left: auto;
    > div,
    > a {
      @include flex($cross: flex-start);
    }
    > *:not(:last-child) {
      margin-right: 0.75rem;
    }
    p {
      &:hover {
        color: var(--blue-6);
      }
    }
  }
}
.job-title {
  color: var(--grey-5);
}
.questions {
  @include hide-scroll;
  margin-top: var(--spacing-lg);
  background: var(--grey-1);
  padding: 1rem;
  border-radius: var(--border-radius-sm);
  div {
    margin-bottom: var(--spacing-sm);
  }
  p:first-child {
    font-size: 13px;
    color: var(--grey-5);
  }
}
.card__cont {
  display: flex;
  justify-content: space-between;
  margin-top: var(--spacing-base);
}
</style>
