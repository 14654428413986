<template>
  <div class="group">
    <div class="group-header">
      <h6>
        {{ title }} <span>({{ numOfUsers }})</span>
      </h6>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'GroupBoard',
  props: {
    id: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    numOfUsers: {
      type: Number,
      required: true,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.group {
  width: 320px;
  background: var(--grey-1);
  border-radius: var(--border-radius-base);
  border: var(--border-2);
  margin-right: var(--spacing-base);
}
.group-header {
  border-bottom: var(--border-2);
  padding-bottom: var(--spacing-2xs);
  padding: var(--spacing-base) var(--spacing-base) var(--spacing-2xs)
    var(--spacing-base);
  span {
    color: var(--grey-5);
  }
}
</style>
