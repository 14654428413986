<template>
  <div :class="['user-layout']">
    <navbar />
    <sidebar />
    <div class="body-wrapper">
      <router-view />
    </div>
    <message-modal v-show="showMessageModal" />
    <share-post-modal v-if="toggleSharePostModal" />
    <application-detail v-if="showApplicationDetails" />
    <navigation-icons />
    <auth />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import MessageModal from '../components/messages/MessageModal.vue'
import ApplicationDetail from '../components/applications/ApplicationDetail.vue'
import Auth from '../components/auth/index.vue'
import Navbar from '@/components/bars/Navbar'
import Sidebar from '@/components/bars/Sidebar'
import NavigationIcons from '@/components/bars/NavigationIcons'
import SharePostModal from '@/components/common/SharePostModal'
import GET_SKILLS from '@/graphql/skills/GetSkills.gql'

export default {
  name: 'UserLayout',
  components: {
    Navbar,
    Sidebar,
    MessageModal,
    SharePostModal,
    NavigationIcons,
    ApplicationDetail,
    Auth
  },
  apollo: {
    getSkills: {
      query: GET_SKILLS,
      manual: true,
      prefetch: false,
      result({ data, error }) {
        if (!error) {
          this.setSkills(data.getSkills)
        }
      }
    }
  },
  computed: {
    notifyBar() {
      return this.$store.state.notifyBar
    },
    showMessageModal() {
      return (
        this.$route.name !== 'messages' && this.$store.state.message.toUser.id
      )
    },
    toggleSharePostModal() {
      return this.$store.state.sharePostModal.show
    },
    showApplicationDetails() {
      return this.$store.state.currentApplication.show
    }
  },
  watch: {
    $route(to, from) {
      window.scrollTo(0, 0)
      if (from.name === 'messages') {
        this.$store.commit('message/handleCurrentMessenger', { show: false })
      }
    }
  },
  created() {
    if (window.innerWidth < 1200) {
      const viewPort = document.getElementById('view-port')
      viewPort.setAttribute('content', 'width=1280')
    }
  },
  methods: {
    ...mapMutations(['setSkills'])
  }
}
</script>

<style lang="scss">
.user-layout {
  min-height: 100vh;
  background-color: var(--black-2);
}
.body-wrapper {
  padding-top: 56px;
  margin-left: 240px;
}
</style>
