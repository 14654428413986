<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1904 3.17163C14.4402 2.42149 13.4228 2.00006 12.3619 2.00006H6C4.89543 2.00006 4 2.89549 4 4.00006V20.0001C4 21.1046 4.89543 22.0001 6 22.0001H18C19.1046 22.0001 20 21.1046 20 20.0001V9.63812C20 8.57725 19.5786 7.55984 18.8284 6.80969L15.1904 3.17163Z"
      :fill="color"
    />
  </svg>
</template>

<script>
import iconMixins from './_iconMixins'

export default {
  mixins: [iconMixins]
}
</script>

<style lang="scss" scoped></style>
