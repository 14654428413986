import { mapMutations, mapState } from 'vuex'
import MessageSideBarItem from './MessageSideBarItem'
import GET_COMPANY_MESSAGES from '@/graphql/messages/GetCompanyMessages.gql'
import GET_USER_MESSENGER_STATUS from '@/graphql/users/GetUserMessengerStatus.gql'
import NEW_MESSAGE_ADDED from '@/graphql/messages/NewMessageAdded.gql'
import { AUTHENTICATION_ERROR } from '@/utils/constants'

export default {
  components: { MessageSideBarItem },
  apollo: {
    getCompanyMessages: {
      query: GET_COMPANY_MESSAGES,
      variables() {
        return {
          companyId: this.companyId
        }
      },
      // manual: true,
      // skip() {
      //   return !this.companyId
      // },
      result({ data, error }) {
        if (error) {
          if (!error.message.includes(AUTHENTICATION_ERROR)) {
            this.showNotifyBar({
              message: 'Unable to fetch messages, try again',
              type: 'error'
            })
          }
        } else {
          this.setMessengers(data.getCompanyMessages)
        }
      },
      subscribeToMore: [
        {
          document: GET_USER_MESSENGER_STATUS,
          variables() {
            const messengers = this.messengers.map((user) => user.id)
            return {
              messengers
            }
          },
          // Mutate the previous result
          updateQuery: (previousResult, { subscriptionData }) => {
            const { id, isOnline } =
              subscriptionData.data.getUserMessengerStatus
            previousResult.getCompanyMessages.users =
              previousResult.getCompanyMessages.users.map((item) => {
                if (item.id === id) {
                  item.isOnline = isOnline
                }
                return item
              })
            return previousResult
          }
        }
      ]
    },
    $subscribe: {
      newMessageAdded: {
        query: NEW_MESSAGE_ADDED,
        variables() {
          return {
            userId: this.companyId
          }
        },
        manual: true,
        skip() {
          return !this.companyId
        },
        result({ data, error }) {
          if (!error) {
            this.updateCurrentMessages({
              payload: data.newMessageAdded,
              companyId: this.companyId
            })
          }
        }
      }
    }
  },
  computed: {
    ...mapState('message', ['messengers', 'toUser'])
  },

  methods: {
    ...mapMutations('message', [
      'setMessengers',
      'handleCurrentMessenger',
      'updateCurrentMessages'
    ])
  }
}
