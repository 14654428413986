<template>
  <overlay-wrapper
    :show-inner-modal="showInnerModal"
    @hideInnerModal="hideInnerModal"
  >
    <transition name="scale-in" appear>
      <form
        v-if="showInnerModal"
        class="overlay__content modal in-contact"
        @input="monitorErrors"
      >
        <h4>Contact us</h4>
        <p>Type your message below, we would reply you soon</p>
        <textarea-input-field
          v-model="state.message"
          name="message"
          placeholder="How can we serve you better?"
          :error-message="errorMessages.message"
        />
        <base-button
          id="loginButton"
          text="Send"
          :is-loading="btnDisabled"
          @click="sendUsAMessage"
        />
      </form>
    </transition>
  </overlay-wrapper>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'
import innerModalMixin from '@/mixins/innerModal'
import CONTACT_US from '@/graphql/services/ContactUs.gql'

export default {
  name: 'ContactUs',
  mixins: [validationMixin, innerModalMixin],
  data() {
    return {
      state: {
        email: '',
        message: ''
      },
      showErrors: false,
      errorMessages: {
        email: '',
        message: ''
      },
      btnDisabled: false
    }
  },
  computed: {
    ...mapState(['user'])
  },
  mounted() {
    this.state.email = this.user.email
  },
  methods: {
    async sendUsAMessage() {
      try {
        this.showErrors = true
        const isValid = await this.checkErrors()
        this.btnDisabled = true
        if (!isValid) return
        const data = await this.graphqlMutation({
          mutation: CONTACT_US,
          variables: {
            email: this.state.email,
            text: this.state.message
          }
        })
        if (data && data.contactUs.code === 200) {
          this.showNotifyBar({
            message: 'Message successfully sent',
            type: 'success'
          })
          this.state.email = ''
          this.state.message = ''
          this.hideInnerModal()
        }
      } catch (error) {
        this.showNotifyBar({
          message: 'Unable to send message',
          type: 'error'
        })
      } finally {
        this.btnDisabled = false
      }
    },

    checkErrors() {
      const arr = ['email', 'message']

      arr.forEach((value) => {
        switch (value) {
          case 'email':
            if (!this.$v.state.email.required || !this.$v.state.email.email) {
              this.errorMessages.email = 'Please enter a valid email address'
            } else {
              this.errorMessages.email = ''
            }
            break
          case 'message':
            if (!this.$v.state.message.required) {
              this.errorMessages.message = 'Message can not be empty'
            } else {
              this.errorMessages.message = ''
            }
            break

          default:
            break
        }
      })
      return !this.$v.state.$invalid
    },
    monitorErrors() {
      if (this.showErrors) this.checkErrors()
    },
    hideInnerModal() {
      this.showInnerModal = false
      setTimeout(() => {
        this.$emit('close')
      }, 300)
    }
  },
  validations: {
    state: {
      email: {
        required,
        email
      },
      message: {
        required
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@include popup-modal;
</style>
