<template>
  <svg
    width="754"
    height="685"
    viewBox="0 0 754 685"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <g style="mix-blend-mode: multiply" opacity="0.2">
        <path
          d="M74.8877 258.647C74.8877 258.319 74.8877 257.992 74.8877 257.665C74.8877 123.544 208.966 -48.5239 344.788 34.784C382.287 57.7918 416.406 77.7464 445.402 99.0095C467.639 115.366 497.071 118.419 522.361 107.297C644.993 53.103 791.28 256.575 719.772 369.978C639.761 497.012 787.683 604.745 680.966 613.141C667.885 614.123 654.804 615.867 641.941 618.266L310.124 680.529C258.237 690.234 204.279 684.018 156.534 661.338C154.899 660.574 153.373 659.811 151.846 659.048C51.2332 608.78 6.86743 489.379 47.418 384.481C58.6457 355.476 70.5274 313.276 74.8877 258.647Z"
          fill="url(#paint0_linear)"
        />
      </g>
      <path
        d="M525.849 544.772L661.344 609.107L666.14 613.687L310.233 679.766L145.96 592.751L304.783 573.777L300.205 565.599C300.205 565.599 290.285 567.453 280.256 560.147C270.337 552.95 267.612 537.466 267.612 537.466L249.517 532.014L243.739 537.903C243.739 537.903 229.568 541.937 215.942 551.969C202.317 561.892 177.463 556.222 177.463 556.222L163.401 562.764L128.083 546.408C128.083 546.408 127.211 540.083 139.856 538.23C152.5 536.376 162.529 519.256 162.529 519.256L189.672 522.855C189.672 522.855 255.73 509.225 270.228 509.225C284.726 509.225 368.661 522.528 368.661 522.528L442.023 553.059L500.232 544.554H525.849V544.772Z"
        fill="#D1D3D4"
      />
      <path
        d="M465.241 502.573C465.241 502.573 474.616 508.352 476.36 510.751C478.213 513.15 503.502 510.751 503.502 510.751L502.303 528.307C502.303 528.307 488.459 536.158 472.762 530.161C457.065 524.163 436.572 525.363 436.572 525.363C436.572 525.363 431.122 527.217 413.027 524.709C394.932 522.201 392.424 510.097 392.424 510.097C392.424 510.097 395.477 504.1 385.775 504.1C376.073 504.1 360.376 498.648 369.424 494.395C378.472 490.142 385.775 490.142 389.917 491.996C394.169 493.85 404.415 498.648 404.415 498.648L465.241 502.573Z"
        fill="#D1D3D4"
      />
      <path
        d="M473.308 533.65C471.563 538.448 459.028 535.94 459.028 535.94L457.938 530.27L455.648 504.536L458.919 501.047L465.241 497.775C465.241 497.775 465.786 501.81 466.113 502.137C466.549 502.464 467.857 508.025 467.857 508.025C467.857 508.025 475.27 527.98 473.308 533.65Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M464.587 487.417L465.241 502.464C465.241 502.464 473.089 521.765 473.089 527.217C473.089 532.669 458.265 530.27 458.265 530.27L454.667 498.212L464.587 487.417Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M430.686 500.61L412.918 508.68C409.539 509.988 406.159 510.424 403.325 509.334L400.382 507.916C400.164 507.807 399.946 507.698 399.728 507.589C393.406 503.882 394.605 492.759 401.254 489.815L411.174 485.454L426.762 489.379L430.686 500.61Z"
        fill="url(#paint3_linear)"
      />
      <path
        d="M417.06 470.079V479.129L398.638 491.124C396.457 492.541 395.258 495.049 395.803 497.557C396.239 499.411 397.438 501.155 399.51 501.919L402.562 503.336C405.178 504.427 408.339 503.882 410.41 501.919L428.069 493.195L421.42 470.297L417.06 470.079Z"
        fill="url(#paint4_linear)"
      />
      <path
        d="M396.022 231.931L397.439 233.458L395.15 234.548L391.334 231.386L396.022 231.931ZM385.993 234.33L385.557 236.838L386.429 236.729L385.993 234.33Z"
        fill="url(#paint5_linear)"
      />
      <path
        d="M396.022 231.931L397.439 233.458L395.15 234.548L391.334 231.386L396.022 231.931ZM385.993 234.33L385.557 236.838L386.429 236.729L385.993 234.33Z"
        fill="url(#paint6_linear)"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="400"
        y="247"
        width="82"
        height="252"
      >
        <path
          d="M478.758 247.416L481.156 314.149L405.832 317.311C394.169 266.607 405.396 249.814 405.396 249.814L478.758 247.416ZM444.203 398.329C447.473 400.728 446.601 414.795 446.601 417.303C446.601 419.81 447.037 434.64 448.236 438.238C449.435 441.837 454.667 498.211 454.667 498.211H475.815L472.762 396.803L444.203 398.329ZM421.856 472.478H415.534L417.496 455.576L421.856 472.478Z"
          fill="url(#paint7_linear)"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M478.758 247.416L481.156 314.149L405.832 317.311C394.169 266.607 405.396 249.814 405.396 249.814L478.758 247.416ZM444.203 398.329C447.473 400.728 446.601 414.795 446.601 417.303C446.601 419.81 447.037 434.64 448.236 438.238C449.435 441.837 454.667 498.211 454.667 498.211H475.815L472.762 396.803L444.203 398.329ZM421.856 472.478H415.534L417.496 455.576L421.856 472.478Z"
          fill="url(#paint8_linear)"
        />
        <g style="mix-blend-mode: multiply">
          <path
            d="M480.829 232.04L438.207 328.651V391.023V425.262L444.639 387.425L450.307 338.356L457.174 389.497L479.848 511.296C479.848 511.296 412.155 497.448 410.956 497.012C409.757 496.576 385.993 480.11 385.557 478.911C385.121 477.711 375.528 306.189 375.528 306.189C375.528 306.189 377.164 242.181 378.363 239.782C379.562 237.384 439.188 224.517 439.188 224.517L434.392 246.652"
            fill="url(#paint9_linear)"
          />
        </g>
      </g>
      <path
        d="M338.466 499.847C338.466 499.847 333.343 496.249 333.343 507.589C333.343 507.698 333.343 507.698 333.343 507.807L333.561 510.315C333.67 511.405 334.542 512.278 335.632 512.278H336.504C337.594 512.278 338.575 511.405 338.575 510.315C338.684 506.608 339.011 499.302 338.466 499.847Z"
        fill="url(#paint10_linear)"
      />
      <path
        d="M341.736 469.316L336.94 471.169V507.698H333.343V525.472L339.12 525.145V527.435C339.12 528.634 340.101 529.616 341.3 529.616H346.315V463.754L341.736 469.316Z"
        fill="url(#paint11_linear)"
      />
      <path
        d="M365.936 531.796C365.936 531.796 384.467 536.812 386.429 552.078C386.429 552.405 386.756 552.732 387.083 552.841L390.789 554.368C390.898 554.477 391.116 554.477 391.334 554.477H395.586C396.131 554.477 396.458 554.041 396.458 553.605C396.022 549.352 392.642 531.142 366.59 523.727L362.339 522.855L365.936 531.796Z"
        fill="url(#paint12_linear)"
      />
      <path
        d="M368.879 522.746C368.879 522.746 405.396 516.967 406.595 532.123C406.595 532.669 407.031 533.214 407.685 533.323L409.212 533.65C409.321 533.65 409.43 533.65 409.539 533.65L414.989 533.868C415.752 533.868 416.406 533.105 416.188 532.341C414.771 527.98 409.648 515.985 394.605 515.222C376.182 514.35 368.334 513.695 368.334 513.695L368.879 522.746Z"
        fill="url(#paint13_linear)"
      />
      <path
        d="M300.423 504.972C300.423 504.972 268.702 497.994 268.702 518.602L275.46 519.802C276.332 519.911 277.095 519.366 277.204 518.493C277.531 515.004 280.474 507.371 299.224 512.823L300.423 504.972Z"
        fill="url(#paint14_linear)"
      />
      <path
        d="M360.158 319.819L408.775 337.702L409.32 337.048L408.775 336.503"
        fill="url(#paint15_linear)"
      />
      <path
        d="M660.254 368.779L661.453 609.107L666.25 613.686V376.303L660.254 368.779Z"
        fill="url(#paint16_linear)"
      />
      <path
        d="M500.559 544.772H525.849V369.106H500.559V544.772Z"
        fill="url(#paint17_linear)"
      />
      <path
        d="M153.373 348.825L173.321 352.423L170.923 594.605L154.027 597.003C154.027 597.112 153.373 347.08 153.373 348.825Z"
        fill="url(#paint18_linear)"
      />
      <path
        d="M147.974 335.175L508.081 307.17L685.544 357.33L307.944 394.731L147.974 335.175Z"
        fill="url(#paint19_linear)"
      />
      <path
        d="M685.544 357.33V613.687H666.249V376.303L333.016 408.034L333.888 675.295L310.342 679.766V394.731L685.544 357.33Z"
        fill="url(#paint20_linear)"
      />
      <path
        d="M310.342 679.766L297.698 673.441V405.308L157.297 353.622L154.027 597.112L146.178 592.859L148.974 335.175L310.342 394.731V679.766Z"
        fill="url(#paint21_linear)"
      />
      <mask
        id="mask1"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="385"
        y="230"
        width="31"
        height="35"
      >
        <path
          d="M395.041 231.277L395.259 234.549C395.259 234.549 400.6 239.347 402.017 245.671L401.472 245.78C401.472 245.78 402.235 248.833 397.766 247.088L396.676 247.525L399.292 257.229L401.254 258.32L407.358 257.775C407.358 257.775 408.23 258.429 408.448 258.756C408.448 258.756 409.975 259.301 410.411 259.41C410.411 259.41 414.662 258.647 415.316 262.027C415.316 262.027 415.316 263.445 413.79 263.881C412.155 264.317 411.174 264.535 411.174 264.535C411.174 264.535 403.979 265.189 401.254 264.317C398.529 263.445 397.003 259.846 395.15 258.538C393.188 257.229 393.079 255.485 393.079 255.485C393.079 255.485 391.661 254.83 391.116 253.522C390.462 252.213 387.083 243.49 387.083 242.509C387.083 241.527 387.41 238.91 387.41 238.91L385.884 234.985L388.5 230.187L395.041 231.277Z"
          fill="url(#paint22_linear)"
        />
      </mask>
      <g mask="url(#mask1)">
        <path
          d="M395.041 231.277L395.259 234.549C395.259 234.549 400.6 239.347 402.017 245.671L401.472 245.78C401.472 245.78 402.235 248.833 397.766 247.088L396.676 247.525L399.292 257.229L401.254 258.32L407.358 257.775C407.358 257.775 408.23 258.429 408.448 258.756C408.448 258.756 409.975 259.301 410.411 259.41C410.411 259.41 414.662 258.647 415.316 262.027C415.316 262.027 415.316 263.445 413.79 263.881C412.155 264.317 411.174 264.535 411.174 264.535C411.174 264.535 403.979 265.189 401.254 264.317C398.529 263.445 397.003 259.846 395.15 258.538C393.188 257.229 393.079 255.485 393.079 255.485C393.079 255.485 391.661 254.83 391.116 253.522C390.462 252.213 387.083 243.49 387.083 242.509C387.083 241.527 387.41 238.91 387.41 238.91L385.884 234.985L388.5 230.187L395.041 231.277Z"
          fill="url(#paint23_linear)"
        />
        <g style="mix-blend-mode: multiply">
          <path
            d="M399.837 247.743C396.24 246.761 393.297 244.144 391.988 240.546L388.282 230.95L383.486 233.894L388.5 260.282L398.529 266.498C398.529 266.498 412.046 269.66 412.373 269.551C412.7 269.442 417.823 267.261 418.041 266.934C418.259 266.607 412.482 249.596 412.482 249.596L400.055 247.415"
            fill="url(#paint24_linear)"
          />
        </g>
        <g style="mix-blend-mode: multiply">
          <path
            d="M390.026 228.77C390.026 228.77 402.671 246.325 402.453 247.416C402.235 248.506 408.23 243.817 408.23 243.163C408.23 242.509 404.633 240.219 402.78 237.493C400.927 234.767 394.932 227.57 394.387 227.025C393.842 226.48 390.026 228.77 390.026 228.77Z"
            fill="url(#paint25_linear)"
          />
        </g>
      </g>
      <mask
        id="mask2"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="230"
        y="229"
        width="46"
        height="101"
      >
        <path
          d="M274.37 229.642L240.142 230.514L230.876 236.075L236.109 329.088L275.133 326.689L274.37 229.642Z"
          fill="url(#paint26_linear)"
        />
      </mask>
      <g mask="url(#mask2)">
        <path
          d="M274.37 229.642L240.142 230.514L230.876 236.075L236.109 329.088L275.133 326.689L274.37 229.642Z"
          fill="url(#paint27_linear)"
        />
        <g style="mix-blend-mode: multiply">
          <path
            d="M283.745 240.109L254.422 241.2C252.242 241.309 250.607 243.053 250.716 245.234L255.403 328.978L289.195 330.178L283.745 240.109Z"
            fill="url(#paint28_linear)"
          />
        </g>
      </g>
      <mask
        id="mask3"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="241"
        y="385"
        width="58"
        height="53"
      >
        <path
          d="M244.938 385.898L297.698 405.308L298.134 437.039C298.134 437.039 267.503 427.77 259.545 428.207C251.37 428.534 234.91 424.499 244.938 385.898Z"
          fill="url(#paint29_linear)"
        />
      </mask>
      <g mask="url(#mask3)">
        <path
          d="M244.938 385.898L297.698 405.308L298.134 437.039C298.134 437.039 267.503 427.77 259.545 428.207C251.37 428.534 234.91 424.499 244.938 385.898Z"
          fill="url(#paint30_linear)"
        />
        <g style="mix-blend-mode: multiply">
          <path
            d="M297.698 405.308L255.839 392.768V381.101L297.698 405.308Z"
            fill="url(#paint31_linear)"
          />
        </g>
      </g>
      <mask
        id="mask4"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="333"
        y="402"
        width="102"
        height="57"
      >
        <path
          d="M333.016 408.034L394.278 402.8L434.174 408.034L430.904 451.541C430.904 451.541 342.717 462.446 333.452 457.212L333.016 408.034Z"
          fill="url(#paint32_linear)"
        />
      </mask>
      <g mask="url(#mask4)">
        <path
          d="M333.561 405.417L394.823 400.183L434.719 405.417L431.449 448.925C431.449 448.925 343.262 459.829 333.997 454.595L333.561 405.417Z"
          fill="url(#paint33_linear)"
        />
        <path
          d="M457.72 399.529C457.72 399.529 387.737 432.786 301.295 408.579C214.852 384.372 360.485 363.872 385.775 368.125C411.065 372.377 457.72 399.529 457.72 399.529Z"
          fill="url(#paint34_linear)"
        />
      </g>
      <path
        d="M188.255 493.958L187.601 517.184C187.601 517.184 188.473 527.107 193.923 528.197C199.373 529.288 212.018 530.923 217.033 532.559C222.047 534.194 236.981 537.902 243.848 538.011C249.081 538.011 251.588 526.671 249.299 523.944C247.009 521.218 215.179 507.588 212.781 506.389C210.383 505.189 207.658 494.285 207.658 494.285L188.255 493.958Z"
        fill="url(#paint35_linear)"
      />
      <mask
        id="mask5"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="187"
        y="493"
        width="64"
        height="46"
      >
        <path
          d="M188.255 493.958L187.601 517.184C187.601 517.184 188.473 527.107 193.923 528.198C199.373 529.288 212.018 530.924 217.033 532.559C222.047 534.195 236.981 537.902 243.848 538.011C249.081 538.011 251.588 526.671 249.299 523.945C247.009 521.219 215.179 507.589 212.781 506.389C210.383 505.19 207.658 494.286 207.658 494.286L188.255 493.958Z"
          fill="url(#paint36_linear)"
        />
      </mask>
      <g mask="url(#mask5)">
        <g style="mix-blend-mode: multiply">
          <path
            d="M198.065 491.996L197.52 501.592C197.193 507.371 200.79 512.605 206.35 514.349C216.924 517.73 230.44 525.145 236.654 527.543C246.137 531.142 250.389 525.145 250.389 525.145C250.389 525.145 253.768 514.24 252.351 513.477C250.934 512.714 219.104 495.376 216.924 494.177C214.743 492.977 196.43 488.725 196.43 488.725L197.084 491.342L198.065 491.996Z"
            fill="url(#paint37_linear)"
          />
        </g>
      </g>
      <path
        d="M172.885 359.292L172.667 402.799C172.667 402.799 178.88 392.985 181.169 376.847L185.421 367.143C185.421 367.143 184.767 385.898 181.387 391.459C178.008 397.02 174.302 411.195 178.117 437.583C181.932 463.972 183.785 500.391 183.785 500.391L211.146 500.828C211.146 500.828 216.596 412.831 217.142 406.616C217.796 400.4 221.938 377.174 221.938 377.174L172.885 359.292Z"
        fill="url(#paint38_linear)"
      />
      <mask
        id="mask6"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="172"
        y="359"
        width="50"
        height="142"
      >
        <path
          d="M172.885 359.292L172.667 402.8C172.667 402.8 178.88 392.986 181.169 376.848L185.421 367.143C185.421 367.143 184.767 385.898 181.387 391.459C178.008 397.02 174.302 411.196 178.117 437.584C181.932 463.972 183.785 500.392 183.785 500.392L211.146 500.828C211.146 500.828 216.596 412.831 217.142 406.616C217.796 400.401 221.938 377.175 221.938 377.175L172.885 359.292Z"
          fill="url(#paint39_linear)"
        />
      </mask>
      <g mask="url(#mask6)">
        <g style="mix-blend-mode: multiply">
          <path
            d="M201.118 348.279L192.615 402.036C192.615 402.036 198.065 488.397 199.809 505.953C201.663 523.509 165.363 506.607 165.363 506.607C165.363 506.607 161.766 345.989 162.311 343.59C163.074 340.973 201.118 348.279 201.118 348.279Z"
            fill="url(#paint40_linear)"
          />
        </g>
      </g>
      <mask
        id="mask7"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="127"
        y="507"
        width="52"
        height="57"
      >
        <path
          d="M147.704 513.695L147.05 554.804C147.05 554.804 138.766 541.937 132.225 537.248C125.685 532.559 127.211 526.235 129.391 521.546C131.462 516.857 135.931 507.916 135.931 507.916L147.704 513.695ZM173.103 562.982L175.719 563.309C176.918 563.418 178.117 562.655 178.444 561.456C178.771 559.711 178.553 557.966 177.681 556.44L171.686 546.081L173.103 562.982Z"
          fill="url(#paint41_linear)"
        />
      </mask>
      <g mask="url(#mask7)">
        <path
          d="M147.704 513.695L147.05 554.804C147.05 554.804 138.766 541.937 132.225 537.248C125.685 532.559 127.211 526.235 129.391 521.546C131.462 516.857 135.931 507.916 135.931 507.916L147.704 513.695ZM173.103 562.982L175.719 563.309C176.918 563.418 178.117 562.655 178.444 561.456C178.771 559.711 178.553 557.966 177.681 556.44L171.686 546.081L173.103 562.982Z"
          fill="url(#paint42_linear)"
        />
        <g style="mix-blend-mode: multiply">
          <path
            d="M146.178 512.06C146.178 512.06 140.183 520.783 139.965 524.6C139.856 526.453 170.705 563.855 171.359 564.509C172.013 565.163 180.515 563.418 181.496 562.219C182.368 561.019 176.373 551.097 176.373 551.097L170.814 533.105L148.576 510.206L146.178 512.06Z"
            fill="url(#paint43_linear)"
          />
        </g>
      </g>
      <path
        d="M149.339 335.739L227.606 329.851C229.568 287.761 222.81 275.112 223.028 261.155C223.246 247.307 220.63 236.184 220.63 236.184L219.213 230.296L153.591 222.227L150.538 223.208C150.538 223.208 140.074 256.466 145.306 276.203C145.306 276.203 139.42 316.112 144.325 362.782C144.325 362.782 143.889 375.104 139.747 383.936C135.495 392.768 128.628 402.691 129.282 440.856C129.936 479.02 128.519 511.951 128.519 511.951L147.813 513.586L149.339 335.739Z"
        fill="url(#paint44_linear)"
      />
      <mask
        id="mask8"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="128"
        y="222"
        width="100"
        height="292"
      >
        <path
          d="M149.339 335.739L227.606 329.851C229.568 287.761 222.81 275.112 223.028 261.155C223.246 247.307 220.63 236.184 220.63 236.184L219.213 230.296L153.591 222.227L150.538 223.208C150.538 223.208 140.074 256.466 145.306 276.203C145.306 276.203 139.42 316.112 144.325 362.782C144.325 362.782 143.889 375.104 139.747 383.936C135.495 392.768 128.628 402.691 129.282 440.856C129.936 479.02 128.519 511.951 128.519 511.951L147.813 513.586L149.339 335.739Z"
          fill="url(#paint45_linear)"
        />
      </mask>
      <g mask="url(#mask8)">
        <g style="mix-blend-mode: multiply">
          <path
            d="M207.549 264.972C207.113 268.243 201.881 265.299 199.046 267.043L197.738 285.472C197.738 287.87 197.847 290.924 199.046 291.251L201.445 291.142C201.99 296.594 195.558 310.115 188.364 323.418C184.331 330.942 179.207 336.067 175.501 339.12C175.61 338.793 198.065 339.665 198.392 339.665L207.549 264.972Z"
            fill="url(#paint46_linear)"
          />
        </g>
        <g style="mix-blend-mode: multiply">
          <path
            d="M140.074 205.652L225.862 224.408L229.459 276.966C229.459 276.966 175.719 269.769 166.671 273.912C157.624 278.165 153.373 368.124 148.576 378.374C143.78 388.624 135.931 492.541 138.875 515.44C141.818 538.339 105.083 523.291 105.083 523.291L140.074 205.652Z"
            fill="url(#paint47_linear)"
          />
        </g>
      </g>
      <path
        d="M195.449 104.789L196.43 100.1C196.43 100.1 218.777 104.789 221.175 114.821C223.573 124.853 227.606 132.158 226.843 144.589C226.843 144.589 241.341 178.828 239.706 187.661C239.706 187.661 239.052 204.671 221.393 199.546C221.393 199.546 215.615 198.456 212.454 194.966L209.293 191.804C209.293 191.804 223.137 181.554 226.189 181.118C226.189 181.118 228.042 180.682 226.298 177.084C224.663 173.485 219.54 168.905 213.326 169.669L195.449 104.789Z"
        fill="url(#paint48_linear)"
      />
      <path
        d="M215.397 142.19C215.397 142.19 218.995 154.621 219.431 160.618C219.867 166.615 223.791 171.086 228.587 179.155C228.587 179.155 230.44 182.426 232.62 186.134C232.62 186.134 228.478 180.246 224.663 182.645C220.848 185.043 209.075 192.676 209.075 192.676L201.663 167.924C201.663 167.924 210.274 139.682 210.71 139.682C211.146 139.682 215.397 142.19 215.397 142.19Z"
        fill="url(#paint49_linear)"
      />
      <path
        d="M226.843 274.348C226.843 274.348 226.407 275.33 224.881 277.402L224.445 271.731L226.843 274.348Z"
        fill="url(#paint50_linear)"
      />
      <path
        d="M143.344 269.551C143.344 269.551 138.875 278.383 139.093 281C139.311 283.617 144.543 283.617 144.543 283.617L145.306 276.42L143.344 269.551Z"
        fill="url(#paint51_linear)"
      />
      <path
        d="M189.018 274.24C197.738 275.221 207.549 275.657 218.123 275.003C221.066 274.785 224.009 274.567 226.843 274.349C228.042 248.942 222.701 236.293 221.502 199.655C219.976 199.11 218.341 198.347 216.487 197.365C213.435 195.62 211.037 193.658 209.402 192.022C211.691 190.605 213.98 189.078 216.269 187.66L216.051 166.943C216.051 166.943 218.341 157.674 216.705 148.405C215.07 139.137 195.558 95.6292 195.558 95.6292L173.866 70.6587C173.866 70.6587 165.799 91.5947 159.804 96.3925C153.809 101.19 133.206 106.533 140.51 149.169C147.704 191.477 138.33 258.865 139.311 281C146.396 278.492 155.553 276.093 166.235 274.894C174.738 273.803 182.477 273.803 189.018 274.24Z"
        fill="url(#paint52_linear)"
      />
      <path
        d="M188.691 158.765C188.691 158.765 194.904 166.398 195.885 169.451C196.757 172.504 201.663 173.594 201.663 173.594L192.724 177.738L188.691 158.765Z"
        fill="url(#paint53_linear)"
      />
      <path
        d="M162.747 93.3392C162.747 93.3392 186.402 117.874 194.795 98.3551L182.695 68.3687L166.344 73.3846L165.69 78.9457C165.363 81.5627 164.818 84.2887 164.055 86.7967C163.619 88.9775 163.074 91.1584 162.747 93.3392Z"
        fill="url(#paint54_linear)"
      />
      <path
        d="M192.07 34.2388C199.591 37.0739 208.53 43.6164 209.729 58.5551C209.729 58.5551 209.511 69.6774 207.767 73.2758C206.023 76.7651 205.042 89.632 196.43 100.209C195.558 101.299 194.141 101.845 192.833 101.518C188.473 100.754 178.662 97.7011 170.923 85.7065C170.16 84.5071 169.724 83.1986 169.506 81.8901L168.743 76.2199C168.743 76.2199 168.306 78.0736 165.363 75.5656C162.311 73.1667 158.932 66.7333 160.894 62.6987C160.894 62.6987 157.079 45.9063 169.833 36.7468C176.264 32.167 184.658 31.4038 192.07 34.2388Z"
        fill="url(#paint55_linear)"
      />
      <path
        opacity="0.1"
        d="M171.032 67.4966C171.032 67.4966 170.596 76.8742 172.34 79.2731C174.193 81.672 186.729 87.0151 191.634 88.6507C196.648 90.2863 198.283 89.8501 198.719 90.1773C199.155 90.5044 200.791 90.8315 201.772 90.0682C202.644 89.3049 203.298 94.757 202.862 95.6293C202.426 96.5017 197.084 103.807 196.321 103.917C195.558 104.026 181.06 100.645 174.302 96.1745C167.543 91.7038 165.69 85.2704 165.69 83.9619C165.69 82.6534 168.961 78.6188 168.743 77.7465C168.525 76.8742 171.032 67.4966 171.032 67.4966Z"
        fill="url(#paint56_linear)"
      />
      <path
        d="M151.955 101.845C144.543 106.97 140.51 115.584 141.164 124.525C141.6 129.759 142.69 136.193 145.088 143.717C155.226 175.557 177.681 222.99 192.179 209.578C203.298 199.328 209.838 191.913 209.838 191.913C209.838 191.913 191.525 204.344 178.444 182.208C165.363 160.073 155.662 99.2275 155.662 99.2275L151.955 101.845Z"
        fill="url(#paint57_linear)"
      />
      <path
        d="M151.955 101.954C156.534 97.9192 163.292 97.5921 168.197 101.081C172.122 103.917 176.7 109.369 180.733 119.291C185.312 130.632 186.62 149.496 187.819 154.076C189.018 158.655 192.833 176.647 192.615 177.629C192.615 177.629 208.966 169.233 215.724 169.342C223.355 169.56 228.696 175.775 227.061 180.573C227.061 180.573 207.113 193.658 196.321 200.855C185.53 208.051 170.923 191.368 162.311 168.687C154.027 147.424 129.064 121.908 151.955 101.954Z"
        fill="url(#paint58_linear)"
      />
      <path
        d="M200.682 92.14C198.719 92.249 196.103 92.249 190.98 90.1772C190.98 90.1772 193.596 94.757 196.757 95.4112C198.174 95.7384 200.682 92.14 200.682 92.14Z"
        fill="white"
      />
      <g opacity="0.9">
        <path
          opacity="0.9"
          d="M194.577 77.8558C195.3 77.8558 195.885 77.2699 195.885 76.5473C195.885 75.8246 195.3 75.2388 194.577 75.2388C193.855 75.2388 193.269 75.8246 193.269 76.5473C193.269 77.2699 193.855 77.8558 194.577 77.8558Z"
          fill="url(#paint59_linear)"
        />
        <path
          opacity="0.9"
          d="M202.753 76.5474C202.644 77.2017 202.971 77.8559 203.516 77.965C204.061 78.074 204.606 77.5288 204.715 76.8746C204.824 76.2203 204.497 75.5661 203.952 75.457C203.407 75.348 202.862 75.7841 202.753 76.5474Z"
          fill="url(#paint60_linear)"
        />
      </g>
      <g opacity="0.9">
        <g opacity="0.9">
          <g opacity="0.9">
            <path
              opacity="0.9"
              d="M206.568 73.6027C205.587 73.4937 204.606 73.3846 203.625 73.6027C202.753 73.8208 202.208 74.257 202.099 74.9112C201.881 75.6745 201.99 76.5469 202.208 77.5282C202.317 78.5096 202.644 79.3819 202.753 80.3633C202.971 81.3447 203.189 82.3261 203.407 83.3074L203.516 84.0707C203.516 84.3979 203.516 84.725 203.516 84.9431C203.407 85.4883 203.298 86.1425 203.08 86.5787C202.862 87.1239 202.535 87.5601 202.099 87.9962C201.663 88.4324 201.118 88.6505 200.463 88.7595C200.136 88.7595 199.918 88.7595 199.591 88.5414L198.937 88.2143C198.501 87.9962 197.956 87.8872 197.52 87.7782C197.52 87.7782 197.52 87.7782 197.52 87.6691C197.52 87.6691 197.52 87.6691 197.629 87.6691C198.174 87.7782 198.61 87.8872 199.155 88.1053C199.591 88.2143 200.136 88.4324 200.573 88.3234C201.554 88.1053 202.208 87.2329 202.535 86.2516C202.862 85.2702 202.862 84.2888 202.535 83.3074L201.663 80.6904C201.445 79.7091 201.227 78.7277 201.118 77.7463C201.009 76.7649 200.9 75.6745 201.227 74.6931C201.336 74.257 201.663 73.7118 202.099 73.4937C202.535 73.1666 203.08 73.0575 203.516 73.0575C204.606 72.9485 205.587 73.2756 206.568 73.6027C206.568 73.4937 206.568 73.6027 206.568 73.6027Z"
              fill="url(#paint61_linear)"
            />
          </g>
          <g opacity="0.9">
            <path
              opacity="0.9"
              d="M189.345 72.8396C189.999 72.5125 190.544 72.2944 191.198 72.1853C191.852 71.9672 192.397 71.8582 193.051 71.8582C193.705 71.7492 194.25 71.7492 194.904 71.7492C195.667 71.6401 196.539 71.9672 197.193 72.4034L197.084 72.2944C197.193 72.5125 197.302 72.7305 197.411 73.1667V73.2757L196.866 73.1667C196.321 72.8396 195.667 72.7305 195.122 72.8396C194.468 72.8396 193.814 72.9486 193.269 72.9486L189.454 73.0577C189.345 72.9486 189.345 72.9486 189.345 72.8396C189.345 72.9486 189.345 72.9486 189.345 72.8396Z"
              fill="url(#paint62_linear)"
            />
          </g>
        </g>
        <g opacity="0.9">
          <path
            opacity="0.9"
            d="M197.52 96.7197C196.975 96.7197 196.539 96.7197 196.212 96.6106C195.776 96.5016 195.34 96.3926 194.904 96.2835C195.122 96.1745 195.34 96.0654 195.558 96.0654C195.776 96.0654 195.994 96.0654 196.321 96.0654C196.757 96.1745 197.193 96.3926 197.52 96.7197Z"
            fill="url(#paint63_linear)"
          />
        </g>
      </g>
      <path
        d="M159.368 49.5049C159.804 42.9624 164.382 35.3295 172.013 32.2763C179.752 29.2231 184.876 29.2231 184.876 29.2231C186.729 29.3322 197.738 29.4412 205.042 38.3826C210.056 44.598 212.89 51.6857 212.781 56.5926C212.781 57.2469 212.781 58.1192 212.781 58.7734C212.563 60.8452 212.345 61.8266 212.236 63.3532C212.018 66.2973 207.985 71.9675 207.985 71.9675C208.966 68.9143 208.966 66.8425 208.966 65.425C208.966 65.2069 208.966 63.7894 208.966 63.4622C209.184 65.8612 205.151 67.4968 203.625 67.6058C202.099 67.8239 200.245 68.042 198.501 68.151C189.236 68.151 182.15 64.1165 178.335 62.0447L176.264 60.6272C175.174 59.8639 173.648 60.8452 173.975 62.1537C174.193 63.4622 174.302 64.7707 173.648 65.316C172.122 66.6245 171.468 68.151 171.141 71.6404C170.814 75.1297 172.34 76.4382 170.16 76.9834C169.942 77.0925 168.961 76.6563 168.961 76.6563C168.634 75.0207 168.634 73.167 169.07 71.5313C169.942 67.8239 170.051 64.5527 168.852 63.3532C167.216 61.7176 162.856 58.1192 161.112 62.917C161.221 63.0261 158.932 55.9384 159.368 49.5049Z"
        fill="url(#paint64_linear)"
      />
      <path
        d="M479.194 241.963L484.535 242.836L485.843 251.559L401.254 258.211L399.292 257.229L396.676 247.525L403.979 247.197L479.194 241.963Z"
        fill="#A7A9AC"
      />
      <path
        d="M402.998 245.126L396.676 247.525L399.728 248.833L484.535 242.836L472.653 240.001L402.998 245.126Z"
        fill="#D1D3D4"
      />
      <path
        d="M483.538 223.196L482.827 226.928L480.696 225.151L482.561 221.863L483.538 223.196ZM490.91 228.349L490.555 229.593L491.532 229.86L491.621 228.349H490.91Z"
        fill="url(#paint65_linear)"
      />
      <path
        d="M486.469 223.552H487.713V222.396H486.469V223.552Z"
        fill="url(#paint66_linear)"
      />
      <path
        d="M485.226 222.752L484.386 228.794L480.175 226.261L483.183 220.264L485.226 222.752ZM490.91 228.349L490.555 229.593L493.712 232.041L491.621 228.349H490.91Z"
        fill="url(#paint67_linear)"
      />
      <mask
        id="mask9"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="467"
        y="223"
        width="28"
        height="36"
      >
        <path
          d="M480.829 232.041C480.829 232.15 480.829 232.259 480.72 232.368C479.848 233.24 476.469 238.365 475.488 239.019C472.217 241.091 476.469 242.291 478.431 241.854C479.63 241.636 480.175 241.309 480.393 241.091C480.502 240.982 480.72 240.982 480.938 241.091L484.426 242.618C484.644 242.727 484.753 242.836 484.753 243.054L485.952 250.905C485.952 251.232 485.734 251.45 485.516 251.559L472.871 252.759C472.653 252.759 469.274 253.413 468.402 254.612C467.748 255.485 467.53 256.575 467.53 257.011C467.53 257.229 467.53 257.338 467.748 257.447L468.729 258.32C468.838 258.429 468.947 258.429 469.056 258.429L482.355 257.883C482.464 257.883 482.682 257.774 482.682 257.665C483.336 256.902 485.952 254.067 487.696 252.322C488.568 251.45 489.222 250.251 489.441 249.051L489.659 247.743L492.711 237.275C493.038 235.966 493.038 234.549 492.602 233.349C492.602 233.24 492.602 233.131 492.602 233.022L494.237 226.37C494.346 226.043 494.128 225.825 493.801 225.716L484.753 223.753C484.535 223.753 484.317 223.862 484.208 224.081L483.336 228.442L480.829 232.041Z"
          fill="url(#paint68_linear)"
        />
      </mask>
      <g mask="url(#mask9)">
        <path
          d="M480.829 232.041C480.829 232.15 480.829 232.259 480.72 232.368C479.848 233.24 476.469 238.365 475.488 239.019C472.217 241.091 476.469 242.291 478.431 241.854C479.63 241.636 480.175 241.309 480.393 241.091C480.502 240.982 480.72 240.982 480.938 241.091L484.426 242.618C484.644 242.727 484.753 242.836 484.753 243.054L485.952 250.905C485.952 251.232 485.734 251.45 485.516 251.559L472.871 252.759C472.653 252.759 469.274 253.413 468.402 254.612C467.748 255.485 467.53 256.575 467.53 257.011C467.53 257.229 467.53 257.338 467.748 257.447L468.729 258.32C468.838 258.429 468.947 258.429 469.056 258.429L482.355 257.883C482.464 257.883 482.682 257.774 482.682 257.665C483.336 256.902 485.952 254.067 487.696 252.322C488.568 251.45 489.222 250.251 489.441 249.051L489.659 247.743L492.711 237.275C493.038 235.966 493.038 234.549 492.602 233.349C492.602 233.24 492.602 233.131 492.602 233.022L494.237 226.37C494.346 226.043 494.128 225.825 493.801 225.716L484.753 223.753C484.535 223.753 484.317 223.862 484.208 224.081L483.336 228.442L480.829 232.041Z"
          fill="url(#paint69_linear)"
        />
        <g style="mix-blend-mode: multiply">
          <path
            d="M491.839 221.354L488.786 235.094L476.687 244.362L467.094 248.397L463.824 260.719C463.824 260.719 485.952 260.719 486.824 260.5C487.696 260.282 504.047 248.833 504.047 248.833L505.465 234.985L500.559 222.663L491.839 221.354Z"
            fill="url(#paint70_linear)"
          />
        </g>
      </g>
      <mask
        id="mask10"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="367"
        y="96"
        width="135"
        height="150"
      >
        <path
          d="M383.05 110.459L398.638 101.518L427.634 96.502C427.634 96.502 445.075 102.281 451.506 104.571C454.776 105.552 472.108 111.332 478.649 127.47C488.132 150.696 501.54 159.528 500.995 179.156C500.45 198.674 493.692 231.932 493.692 231.932L491.512 229.86C489.004 227.461 485.516 226.044 482.028 226.044H480.284C480.284 226.044 479.957 213.504 480.829 202.6C481.592 192.241 480.284 184.499 481.156 178.828C482.028 173.267 460.772 154.512 458.047 140.882C458.047 140.882 461.535 156.475 463.715 182.536C464.369 190.278 465.241 198.02 467.421 205.544C471.127 218.302 475.488 236.184 472.544 240.001L402.235 245.344C402.235 245.344 403.652 234.222 403.107 228.333C402.562 222.445 402.889 209.142 402.235 204.78C401.363 199.001 404.306 185.916 398.529 175.121C395.15 168.688 394.169 159.092 394.169 159.092C394.169 159.092 393.514 158.001 392.424 156.148C391.334 165.307 389.808 180.573 391.443 183.19C393.624 186.898 397.439 233.567 397.439 233.567L393.296 232.477C390.68 231.823 387.846 232.913 386.429 235.312L385.448 236.948C385.448 236.948 374.656 202.927 371.713 192.895C368.77 182.863 369.097 152.004 369.097 152.004C361.357 118.419 383.05 110.459 383.05 110.459Z"
          fill="url(#paint71_linear)"
        />
      </mask>
      <g mask="url(#mask10)">
        <path
          d="M383.05 110.459L398.638 101.518L427.634 96.502C427.634 96.502 445.075 102.281 451.506 104.571C454.776 105.552 472.108 111.332 478.649 127.47C488.132 150.696 501.54 159.528 500.995 179.156C500.45 198.674 493.692 231.932 493.692 231.932L491.512 229.86C489.004 227.461 485.516 226.044 482.028 226.044H480.284C480.284 226.044 479.957 213.504 480.829 202.6C481.592 192.241 480.284 184.499 481.156 178.828C482.028 173.267 460.772 154.512 458.047 140.882C458.047 140.882 461.535 156.475 463.715 182.536C464.369 190.278 465.241 198.02 467.421 205.544C471.127 218.302 475.488 236.184 472.544 240.001L402.235 245.344C402.235 245.344 403.652 234.222 403.107 228.333C402.562 222.445 402.889 209.142 402.235 204.78C401.363 199.001 404.306 185.916 398.529 175.121C395.15 168.688 394.169 159.092 394.169 159.092C394.169 159.092 393.514 158.001 392.424 156.148C391.334 165.307 389.808 180.573 391.443 183.19C393.624 186.898 397.439 233.567 397.439 233.567L393.296 232.477C390.68 231.823 387.846 232.913 386.429 235.312L385.448 236.948C385.448 236.948 374.656 202.927 371.713 192.895C368.77 182.863 369.097 152.004 369.097 152.004C361.357 118.419 383.05 110.459 383.05 110.459Z"
          fill="url(#paint72_linear)"
        />
        <g style="mix-blend-mode: multiply">
          <path
            d="M398.747 101.518L427.743 96.502C427.743 96.502 434.283 114.494 438.752 116.129C443.222 117.765 458.156 140.882 458.156 140.882C458.156 140.882 469.601 176.211 467.312 191.804C467.312 191.804 477.341 233.785 472.653 240.001L402.344 245.344C402.344 245.344 403.761 234.222 403.216 228.333C402.998 225.934 402.562 222.663 402.235 219.174C401.472 212.522 401.145 205.762 401.472 199.001C401.69 195.076 401.363 189.951 400.6 181.882C400.382 179.374 399.728 176.866 398.638 174.685C395.368 168.142 394.278 159.201 394.278 159.201C394.278 159.201 387.301 148.733 384.031 134.012C382.505 126.925 381.742 118.965 383.159 110.568L398.747 101.518Z"
            fill="url(#paint73_linear)"
          />
        </g>
        <g style="mix-blend-mode: multiply" opacity="0.5">
          <path
            d="M396.785 85.4889C396.785 85.4889 399.401 104.789 404.851 111.768C410.302 118.747 425.562 131.832 428.397 110.896C430.795 93.1218 422.183 73.0581 422.183 73.0581L396.785 85.4889Z"
            fill="#64FFBF"
          />
        </g>
        <g style="mix-blend-mode: multiply">
          <path
            d="M458.047 132.158C458.047 129.541 458.81 128.996 460.99 130.523C464.369 132.813 468.62 136.629 475.488 141.1C485.08 147.315 491.73 171.413 491.73 174.467C491.076 202.381 500.123 223.208 498.161 230.405C494.891 242.509 480.502 228.006 480.502 228.006L459.028 143.935C459.028 143.935 458.047 139.682 458.047 132.158Z"
            fill="url(#paint74_linear)"
          />
        </g>
        <g style="mix-blend-mode: multiply">
          <mask
            id="mask11"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="367"
            y="107"
            width="31"
            height="130"
          >
            <g style="mix-blend-mode: multiply">
              <path
                d="M391.443 107.951L383.05 110.459C383.05 110.459 361.249 118.419 369.097 151.895C369.097 151.895 368.77 182.754 371.713 192.786C374.656 202.817 385.448 236.838 385.448 236.838C387.41 233.567 391.334 231.932 395.041 232.913L397.33 233.458C397.33 233.458 393.624 186.788 391.334 183.081C389.154 179.373 393.188 149.169 393.188 149.169L391.443 107.951Z"
                fill="url(#paint75_linear)"
              />
            </g>
          </mask>
          <g mask="url(#mask11)">
            <g style="mix-blend-mode: multiply">
              <path
                d="M385.121 138.483L381.96 167.924C381.96 167.924 372.694 190.496 377.927 211.432C383.159 232.368 384.685 237.602 384.685 237.602H398.093L401.363 229.097L392.533 156.039C391.007 153.313 390.135 151.895 388.718 148.188C387.083 144.48 385.993 141.645 385.121 138.483Z"
                fill="url(#paint76_linear)"
              />
            </g>
          </g>
        </g>
      </g>
      <path
        d="M403.107 105.443C403.107 105.443 408.121 112.531 413.245 114.712C418.368 116.893 423.273 111.332 426.544 103.59C429.705 95.9568 434.283 81.1271 426.871 62.8081C420.112 46.1247 386.647 49.7231 391.988 76.7654C391.988 76.7654 389.263 75.1298 389.263 76.7654C389.263 78.4011 391.443 89.5233 395.368 90.0685C395.15 90.1776 397.548 97.5924 403.107 105.443Z"
        fill="url(#paint77_linear)"
      />
      <path
        d="M388.718 76.7656C388.718 76.7656 389.59 74.4758 391.661 77.3109C393.842 80.1459 394.06 84.3986 394.605 86.2523C395.259 88.3241 395.913 90.5049 395.259 90.723C393.188 91.2682 386.32 82.3268 388.718 76.7656Z"
        fill="url(#paint78_linear)"
      />
      <path
        d="M431.776 71.7494C431.776 71.7494 430.25 69.8957 429.16 73.276C428.07 76.6563 429.269 80.5818 429.705 82.4355C430.141 84.2892 429.705 87.2333 430.359 87.0152C430.904 86.7971 434.828 78.7281 431.776 71.7494Z"
        fill="url(#paint79_linear)"
      />
      <path
        d="M394.06 78.6189C394.06 78.6189 400.6 87.2332 411.828 88.3236C411.828 88.3236 411.392 88.9779 407.685 87.9965C407.685 87.9965 414.444 91.0497 419.022 89.414C422.401 88.2146 429.269 82.8715 429.705 77.7466L429.923 85.3795C429.923 85.3795 432.866 71.4222 432.866 66.9515C432.866 62.4807 431.667 46.2335 415.643 44.0527C404.197 42.5261 396.022 45.4702 391.225 52.667C388.064 57.4648 387.083 63.3531 388.173 69.0232C388.391 70.4408 389.263 72.5126 389.263 72.8397C389.481 73.8211 390.462 75.4567 391.116 77.5285C391.77 79.6003 394.823 86.2518 394.823 86.2518C394.823 86.2518 394.605 82.2173 395.259 82.1083C395.913 81.9992 394.06 78.6189 394.06 78.6189Z"
        fill="url(#paint80_linear)"
      />
      <path
        d="M418.913 109.042C418.913 109.042 414.88 111.659 410.302 109.151C410.302 109.151 413.245 112.422 415.425 112.64C416.624 112.749 418.913 109.042 418.913 109.042Z"
        fill="white"
      />
      <g opacity="0.9">
        <path
          opacity="0.9"
          d="M424.145 89.4141C423.055 90.3954 421.856 91.0497 420.766 91.5949L419.894 92.0311C419.567 92.1401 419.349 92.2491 419.131 92.4672C418.695 92.7944 418.259 93.3396 417.714 93.8848C417.823 93.2305 418.041 92.4672 418.586 91.922C419.131 91.2678 419.785 91.0497 420.33 90.7226C421.42 90.0683 422.728 89.5231 424.145 89.4141Z"
          fill="url(#paint81_linear)"
        />
      </g>
      <g opacity="0.9">
        <path
          opacity="0.9"
          d="M401.581 90.5044C403.87 90.6134 406.159 90.7225 408.339 91.1586C408.884 91.2677 409.43 91.2677 410.084 91.8129C410.629 92.2491 410.956 92.7943 411.283 93.2304C411.828 94.3209 412.155 95.5203 412.264 96.7198C412.482 99.1187 411.937 101.3 412.046 103.48C412.046 104.571 412.264 105.661 413.136 106.206C413.572 106.534 414.117 106.643 414.662 106.643C415.207 106.643 415.752 106.424 416.188 106.097C415.425 106.97 414.008 107.297 412.918 106.752C412.373 106.534 411.937 105.988 411.61 105.334C411.392 104.789 411.283 104.135 411.174 103.589C411.065 102.39 411.065 101.3 411.174 100.1C411.283 99.0097 411.283 97.9192 411.174 96.8288C411.065 95.7384 410.847 94.648 410.411 93.7756C410.193 93.3395 409.866 92.9033 409.539 92.5762C409.321 92.3581 408.666 92.14 408.121 92.031C406.05 91.4858 403.87 91.0496 401.581 90.5044Z"
          fill="url(#paint82_linear)"
        />
      </g>
      <path
        opacity="0.9"
        d="M420.439 95.8475C421.162 95.8475 421.747 95.2616 421.747 94.539C421.747 93.8163 421.162 93.2305 420.439 93.2305C419.717 93.2305 419.131 93.8163 419.131 94.539C419.131 95.2616 419.717 95.8475 420.439 95.8475Z"
        fill="url(#paint83_linear)"
      />
      <path
        opacity="0.9"
        d="M406.813 96.0657C407.536 96.0657 408.121 95.4799 408.121 94.7572C408.121 94.0346 407.536 93.4487 406.813 93.4487C406.091 93.4487 405.505 94.0346 405.505 94.7572C405.505 95.4799 406.091 96.0657 406.813 96.0657Z"
        fill="url(#paint84_linear)"
      />
      <path
        d="M463.852 535.902L465.966 538.548C465.966 538.548 449.877 543.675 443.61 545.99C437.343 548.305 438.135 545.371 438.135 545.371C438.135 545.371 456.537 528.17 457.601 528.948C458.559 529.724 463.852 535.902 463.852 535.902Z"
        fill="url(#paint85_linear)"
      />
      <path
        d="M368.334 527.107L370.187 528.198C370.187 528.198 367.898 529.615 364.846 529.833C361.794 530.052 345.334 531.469 340.755 527.98C336.177 524.6 368.334 527.107 368.334 527.107Z"
        fill="url(#paint86_linear)"
      />
      <mask
        id="mask12"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="440"
        y="533"
        width="67"
        height="43"
      >
        <path
          d="M443.767 533.323L441.041 542.7C441.041 542.7 441.477 546.408 441.259 552.623C441.259 553.495 441.15 554.368 441.041 555.349C440.387 563.2 441.369 575.849 450.743 575.631C460.118 575.304 469.492 572.905 476.142 573.777C482.791 574.65 490.64 577.048 494.891 575.304C499.142 573.559 502.739 572.578 502.739 572.578L506.337 568.652L506.664 562.873V560.147L505.137 559.82L500.886 558.62C500.886 558.62 496.09 557.094 492.384 557.421C488.677 557.748 478.213 552.623 474.616 550.224C471.018 547.825 463.17 543.027 463.17 543.027L458.919 534.304L443.767 533.323Z"
          fill="url(#paint87_linear)"
        />
      </mask>
      <g mask="url(#mask12)">
        <path
          d="M443.767 533.323L441.041 542.7C441.041 542.7 441.477 546.408 441.259 552.623C441.259 553.495 441.15 554.368 441.041 555.349C440.387 563.2 441.369 575.849 450.743 575.631C460.118 575.304 469.492 572.905 476.142 573.777C482.791 574.65 490.64 577.048 494.891 575.304C499.142 573.559 502.739 572.578 502.739 572.578L506.337 568.652L506.664 562.873V560.147L505.137 559.82L500.886 558.62C500.886 558.62 496.09 557.094 492.384 557.421C488.677 557.748 478.213 552.623 474.616 550.224C471.018 547.825 463.17 543.027 463.17 543.027L458.919 534.304L443.767 533.323Z"
          fill="url(#paint88_linear)"
        />
        <g style="mix-blend-mode: multiply">
          <path
            d="M449.217 534.304C449.217 534.304 448.781 545.535 455.212 550.006C461.644 554.477 477.341 560.91 485.843 561.892C494.346 562.873 499.142 563.091 498.924 560.038C498.706 556.985 498.488 555.022 500.123 554.804C501.758 554.586 521.707 563.636 522.906 566.471C524.105 569.307 526.939 577.376 504.81 581.955C482.682 586.535 466.549 593.078 449.217 585.336C431.885 577.703 419.022 577.703 418.586 570.07C418.15 562.437 431.013 531.469 433.847 527.216C436.79 522.964 449.217 534.304 449.217 534.304Z"
            fill="url(#paint89_linear)"
          />
        </g>
      </g>
      <mask
        id="mask13"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="345"
        y="520"
        width="36"
        height="55"
      >
        <path
          d="M362.23 522.746L367.026 537.248L375.746 555.349L379.017 563.963L380.652 571.923L378.69 574.758L376.836 574.322L373.566 573.995L371.495 573.341L368.77 574.867L366.372 574.213H362.448L371.604 570.397L362.121 570.288C362.121 570.288 356.125 568.325 355.471 565.054C354.817 561.782 351.547 558.838 348.495 556.112C345.443 553.386 347.841 541.937 347.841 541.937C347.841 541.937 345.443 534.413 345.443 525.581C345.661 517.075 362.23 522.746 362.23 522.746Z"
          fill="url(#paint90_linear)"
        />
      </mask>
      <g mask="url(#mask13)">
        <path
          d="M362.23 522.746L367.026 537.248L375.746 555.349L379.017 563.963L380.652 571.923L378.69 574.758L376.836 574.322L373.566 573.995L371.495 573.341L368.77 574.867L366.372 574.213H362.448L371.604 570.397L362.121 570.288C362.121 570.288 356.125 568.325 355.471 565.054C354.817 561.782 351.547 558.838 348.495 556.112C345.443 553.386 347.841 541.937 347.841 541.937C347.841 541.937 345.443 534.413 345.443 525.581C345.661 517.075 362.23 522.746 362.23 522.746Z"
          fill="url(#paint91_linear)"
        />
        <g style="mix-blend-mode: multiply">
          <path
            d="M353.945 525.363C353.945 525.363 353.836 533.323 353.836 536.049C353.836 538.775 356.234 545.208 358.632 551.642C359.831 554.804 365.936 559.275 369.315 561.783C372.04 563.745 374.983 559.384 375.201 556.003C375.201 556.003 382.287 567.344 382.396 568.434C382.505 569.525 383.922 576.285 381.197 578.139C378.472 579.993 370.078 581.955 368.225 581.301C366.372 580.647 352.964 573.45 348.168 568.325C343.371 563.2 337.267 558.511 336.395 554.477C335.523 550.442 335.196 537.466 336.286 534.413C337.376 531.36 347.732 521.437 348.495 521.546C349.258 521.655 353.945 525.363 353.945 525.363Z"
            fill="url(#paint92_linear)"
          />
        </g>
      </g>
      <path
        d="M326.693 148.514C326.693 148.514 313.831 140.663 306.2 148.733C298.57 156.802 308.598 178.501 304.238 192.567C299.769 206.634 293.01 220.7 293.01 220.7L325.167 227.57L326.693 148.514Z"
        fill="url(#paint93_linear)"
      />
      <path
        d="M376.182 248.615C376.182 248.615 389.917 259.083 413.572 283.072L416.188 282.418L412.918 304.008C412.918 304.008 405.505 302.372 403.107 298.556C403.107 298.556 371.495 279.255 366.699 270.423C361.903 261.482 376.182 248.615 376.182 248.615Z"
        fill="url(#paint94_linear)"
      />
      <path
        d="M351.002 256.03C353.4 261.046 360.049 268.679 370.514 278.928L389.59 257.011C383.595 250.796 376.727 244.689 374.329 241.745C367.244 232.913 362.448 230.623 362.448 230.623L355.58 229.642L351.002 256.03Z"
        fill="url(#paint95_linear)"
      />
      <path
        d="M359.831 243.163C359.831 243.163 357.978 248.615 363.756 255.811C369.533 263.008 385.775 276.966 385.775 276.966L372.803 280.564L356.234 267.588L348.386 257.665C348.386 257.665 345.115 251.341 346.86 247.96C348.713 244.689 359.831 243.163 359.831 243.163Z"
        fill="url(#paint96_linear)"
      />
      <path
        d="M316.665 203.908C316.665 203.908 314.594 208.16 305.546 211.759C296.498 215.357 301.186 227.57 302.385 229.096C307.726 235.857 325.167 246.107 330.727 244.907C336.286 243.708 339.338 236.402 339.338 236.402L340.646 232.15L316.665 203.908Z"
        fill="url(#paint97_linear)"
      />
      <path
        d="M353.509 254.94C352.092 256.357 351.438 258.32 351.547 260.283C351.765 263.009 352.31 266.934 353.836 270.205C356.452 276.094 365.609 297.248 356.234 303.681L347.187 312.186L349.149 321.346L265.104 327.234C265.104 327.234 270.991 301.391 269.029 281.982C267.067 262.463 267.176 250.905 267.176 250.905C267.176 250.905 265.759 234.985 270.228 229.315C271.536 227.679 273.389 226.043 275.787 224.517C287.342 216.666 308.598 210.232 308.598 210.232C308.598 210.232 300.314 214.485 305.001 223.536C309.579 232.586 316.447 240.437 325.494 241.636C334.542 242.836 337.594 240.655 339.229 230.187L353.291 224.953L362.557 230.623C362.448 230.623 366.154 241.636 353.509 254.94Z"
        fill="url(#paint98_linear)"
      />
      <path
        d="M303.148 291.141L346.642 302.591L347.514 316.766L293.555 320.801C286.143 321.346 279.166 317.529 275.678 310.878C272.081 304.008 270.228 285.035 269.029 274.131C266.631 252.649 262.706 225.825 262.706 225.825L297.371 251.232L302.385 270.096C303.148 278.492 301.84 282.418 300.532 287.434C300.096 289.287 301.295 291.032 303.148 291.141Z"
        fill="url(#paint99_linear)"
      />
      <path
        d="M354.599 164.653C354.599 164.653 368.443 178.283 363.102 200.418C363.102 200.418 361.249 206.198 360.703 209.36C360.049 212.522 357.651 221.027 352.746 226.261C347.841 231.495 345.552 238.474 331.272 226.698C317.101 214.921 310.778 189.841 310.778 189.841C310.778 189.841 306.091 167.052 323.859 158.328C341.627 149.496 354.599 164.653 354.599 164.653Z"
        fill="url(#paint100_linear)"
      />
      <path
        d="M296.062 275.984C296.062 275.984 299.333 291.468 297.48 295.939C297.48 295.939 306.091 295.721 317.428 298.12C328.655 300.519 363.756 304.553 363.756 304.553C363.756 304.553 374.547 299.319 381.197 299.21C387.846 299.101 391.879 304.226 393.296 304.989C394.605 305.753 396.022 308.261 393.951 310.551C393.951 310.551 394.823 311.641 394.387 312.84C393.951 314.04 393.624 314.912 393.624 314.912L391.007 316.112L391.116 317.42L380.434 318.511L378.581 318.074C378.581 318.074 371.386 320.473 364.41 317.202C364.41 317.202 357.978 316.439 353.836 316.875C347.95 317.529 336.068 318.402 325.058 318.074C292.465 317.093 283.854 323.527 279.057 303.354C274.261 283.181 274.588 276.748 274.588 276.748L296.062 275.984Z"
        fill="url(#paint101_linear)"
      />
      <path
        d="M267.285 250.032C267.285 250.032 270.01 269.006 273.062 284.926L300.423 280.564C300.641 276.53 300.75 272.604 300.532 270.096C299.878 263.445 299.987 242.836 295.081 234.112C288.868 222.881 274.588 226.807 274.588 226.807C265.323 228.442 267.285 250.032 267.285 250.032Z"
        fill="url(#paint102_linear)"
      />
      <path
        d="M320.262 195.839C320.262 195.839 314.376 186.025 310.342 193.876C306.2 201.727 318.409 212.74 319.172 208.378L320.262 195.839Z"
        fill="url(#paint103_linear)"
      />
      <mask
        id="mask14"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="335"
        y="398"
        width="131"
        height="149"
      >
        <path
          d="M373.864 404.762C373.864 404.762 409.727 416.975 413.324 415.339C413.324 415.339 410.926 439.111 418.557 466.044C426.187 492.977 437.851 546.189 437.851 546.189L465.975 538.993C465.975 538.993 451.913 428.643 451.913 422.209C451.913 415.776 451.477 400.51 447.88 398.438L336.802 408.143C336.802 408.143 333.967 441.509 337.565 466.48C341.162 491.451 342.034 528.089 342.034 528.089C346.394 528.416 350.973 528.525 355.769 528.634C361.219 528.743 366.452 528.525 371.466 528.307C372.774 501.919 373.646 474.658 373.864 446.743C373.973 432.459 373.973 418.502 373.864 404.762Z"
          fill="url(#paint104_linear)"
        />
      </mask>
      <g mask="url(#mask14)"></g>
      <mask
        id="mask15"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="335"
        y="398"
        width="132"
        height="148"
      >
        <path
          d="M374.053 404.327C374.053 404.327 409.916 416.54 413.513 414.904C413.513 414.904 411.115 438.675 418.745 465.608C426.376 492.542 438.04 545.754 438.04 545.754L466.163 538.557C466.163 538.557 452.101 428.207 452.101 421.774C452.101 415.34 451.665 400.074 448.068 398.002L336.99 407.707C336.99 407.707 334.156 441.074 337.753 466.044C341.351 491.015 342.223 527.653 342.223 527.653C346.583 527.98 351.161 528.089 355.957 528.198C361.408 528.307 366.64 528.089 371.654 527.871C372.963 501.483 373.835 474.223 374.053 446.308C374.162 432.023 374.162 418.066 374.053 404.327Z"
          fill="url(#paint105_linear)"
        />
      </mask>
      <g mask="url(#mask15)">
        <path
          d="M373.864 404.762C373.864 404.762 409.727 416.975 413.324 415.339C413.324 415.339 410.926 439.111 418.557 466.044C426.187 492.977 437.851 546.189 437.851 546.189L465.975 538.993C465.975 538.993 451.913 428.643 451.913 422.209C451.913 415.776 451.477 400.51 447.88 398.438L336.802 408.143C336.802 408.143 333.967 441.509 337.565 466.48C341.162 491.451 342.034 528.089 342.034 528.089C346.394 528.416 350.973 528.525 355.769 528.634C361.219 528.743 366.452 528.525 371.466 528.307C372.774 501.919 373.646 474.658 373.864 446.743C373.973 432.459 373.973 418.502 373.864 404.762Z"
          fill="url(#paint106_linear)"
        />
        <g style="mix-blend-mode: multiply">
          <path
            d="M354.461 403.782L441.23 402.364C441.23 402.364 406.239 403.564 427.931 432.569C439.268 447.726 447.88 499.629 450.823 527.435C453.875 555.241 427.931 555.241 427.931 555.241C427.931 555.241 393.485 454.377 393.485 451.978C393.485 451.215 388.907 542.265 386.618 536.704C384.547 531.688 372.12 539.866 373.319 534.305C403.514 397.457 369.286 407.053 369.286 407.053L354.461 403.782Z"
            fill="url(#paint107_linear)"
          />
        </g>
        <g style="mix-blend-mode: multiply">
          <path
            d="M368.85 397.566C368.85 397.566 362.854 422.319 362.2 463.428C361.546 504.536 365.797 554.041 365.797 554.041L328.408 546.844L326.555 397.675H368.85V397.566Z"
            fill="url(#paint108_linear)"
          />
        </g>
        <g style="mix-blend-mode: multiply">
          <path
            d="M352.935 401.383L377.352 408.143L383.675 547.499L366.342 546.299L359.257 526.89L352.935 401.383Z"
            fill="url(#paint109_linear)"
          />
        </g>
      </g>
      <path
        d="M326.911 162.036C331.186 162.036 334.651 158.569 334.651 154.294C334.651 150.018 331.186 146.552 326.911 146.552C322.637 146.552 319.172 150.018 319.172 154.294C319.172 158.569 322.637 162.036 326.911 162.036Z"
        fill="url(#paint110_linear)"
      />
      <path
        d="M360.594 197.801C354.817 199.001 342.717 199.873 328.655 190.714C328.655 190.714 326.693 193.985 324.622 195.293C322.66 196.711 322.333 201.727 321.788 201.836C319.608 202.381 321.243 195.839 319.063 193.876C316.883 191.913 314.921 189.514 312.631 190.932C310.451 192.349 310.56 193.549 310.56 193.549C310.56 193.549 308.162 186.897 307.29 178.501C306.745 172.286 313.068 162.581 316.556 157.783C317.973 155.82 319.935 154.403 322.115 153.639C329.637 151.131 347.514 146.77 357.106 157.892C368.334 170.868 368.661 182.317 365.173 193.44C364.628 195.729 362.775 197.365 360.594 197.801Z"
        fill="url(#paint111_linear)"
      />
      <mask
        id="mask16"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="440"
        y="552"
        width="73"
        height="25"
      >
        <path
          d="M441.041 552.187C441.041 552.187 449.544 553.496 454.34 557.421C459.137 561.347 481.156 566.799 490.858 565.599C500.559 564.4 499.578 558.075 499.578 558.075C499.578 558.075 506.773 560.474 511.351 562.328C515.929 564.182 507.754 572.578 500.45 574.977C493.147 577.376 477.777 574.104 469.928 575.304C462.08 576.503 449.871 576.394 444.748 575.195C439.733 573.995 441.041 552.187 441.041 552.187Z"
          fill="url(#paint112_linear)"
        />
      </mask>
      <g mask="url(#mask16)">
        <path
          d="M441.041 552.187C441.041 552.187 449.544 553.496 454.34 557.421C459.137 561.347 481.156 566.799 490.858 565.599C500.559 564.4 499.578 558.075 499.578 558.075C499.578 558.075 506.773 560.474 511.351 562.328C515.929 564.182 507.754 572.578 500.45 574.977C493.147 577.376 477.777 574.104 469.928 575.304C462.08 576.503 449.871 576.394 444.748 575.195C439.733 573.995 441.041 552.187 441.041 552.187Z"
          fill="url(#paint113_linear)"
        />
        <g style="mix-blend-mode: multiply">
          <path
            d="M444.53 573.559C444.53 573.559 444.203 567.78 448.781 567.562C453.359 567.235 480.829 572.251 484.753 571.815C488.677 571.378 512.659 560.474 512.659 564.836C512.659 569.198 506.336 576.722 498.161 578.902C489.985 581.083 469.819 587.189 462.189 584.027C454.667 580.865 446.056 578.03 445.62 577.485C445.184 576.721 444.53 573.559 444.53 573.559Z"
            fill="url(#paint114_linear)"
          />
        </g>
      </g>
      <mask
        id="mask17"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="345"
        y="536"
        width="37"
        height="45"
      >
        <path
          d="M346.751 536.049C346.751 536.049 344.897 553.714 346.097 556.221C347.296 558.729 353.509 565.926 358.741 571.705C366.917 580.865 375.419 579.993 375.419 579.993C375.419 579.993 383.922 579.011 380.434 563.854C380.434 563.854 375.855 554.368 373.566 548.37C371.277 542.373 366.917 537.248 366.917 537.248C366.917 537.248 375.528 556.985 375.528 560.038C375.528 560.91 375.419 561.783 375.31 562.655C374.983 564.509 373.13 565.708 371.277 565.272C368.334 564.509 363.756 563.091 360.485 560.692C355.253 556.658 353.291 548.37 351.874 542.155C350.566 535.831 346.751 536.049 346.751 536.049Z"
          fill="url(#paint115_linear)"
        />
      </mask>
      <g mask="url(#mask17)">
        <path
          d="M346.751 536.049C346.751 536.049 344.897 553.714 346.097 556.221C347.296 558.729 353.509 565.926 358.741 571.705C366.917 580.865 375.419 579.993 375.419 579.993C375.419 579.993 383.922 579.011 380.434 563.854C380.434 563.854 375.855 554.368 373.566 548.37C371.277 542.373 366.917 537.248 366.917 537.248C366.917 537.248 375.528 556.985 375.528 560.038C375.528 560.91 375.419 561.783 375.31 562.655C374.983 564.509 373.13 565.708 371.277 565.272C368.334 564.509 363.756 563.091 360.485 560.692C355.253 556.658 353.291 548.37 351.874 542.155C350.566 535.831 346.751 536.049 346.751 536.049Z"
          fill="url(#paint116_linear)"
        />
        <g style="mix-blend-mode: multiply">
          <path
            d="M377.818 551.751C377.818 551.751 381.088 567.344 379.889 570.506C378.69 573.668 364.083 574.867 357.433 564.945C350.784 555.022 348.495 552.514 349.258 546.953C350.021 541.392 352.746 532.886 352.746 532.886C352.746 532.886 339.665 531.687 339.011 532.014C338.357 532.341 337.921 579.992 338.357 580.32C338.793 580.647 359.613 589.261 361.576 589.261C363.538 589.261 374.002 587.952 374.002 587.952L385.448 587.189C385.448 587.189 392.424 579.229 393.406 574.649C394.496 570.07 394.496 562.219 394.496 562.219L377.818 551.751Z"
            fill="url(#paint117_linear)"
          />
        </g>
      </g>
      <g opacity="0.9">
        <path
          opacity="0.9"
          d="M338.248 202.272C340.428 201.945 342.499 202.054 344.57 202.163L346.097 202.272C346.533 202.381 347.187 202.381 347.732 202.708C348.822 203.363 349.367 204.562 349.367 205.544C348.931 204.562 348.277 203.69 347.405 203.472C346.969 203.363 346.533 203.363 345.988 203.363L344.461 203.254C342.39 203.036 340.319 202.817 338.248 202.272Z"
          fill="url(#paint118_linear)"
        />
      </g>
      <g opacity="0.9">
        <path
          opacity="0.9"
          d="M361.358 202.818C360.158 202.818 359.068 202.927 357.978 203.363C357.433 203.581 356.888 203.799 356.561 204.126L356.343 204.453L356.125 204.78C356.016 204.998 355.907 205.325 355.798 205.544C355.362 206.634 355.253 207.615 355.253 208.815C355.253 209.905 355.362 211.105 355.362 212.304V212.195C355.689 213.286 356.016 214.376 356.125 215.466C356.234 216.557 356.234 217.756 355.689 218.956C355.471 219.501 355.035 220.046 354.599 220.373C354.054 220.7 353.509 220.918 352.964 221.027C351.765 221.137 350.675 220.918 349.694 220.591C350.784 220.7 351.874 220.809 352.855 220.482C353.836 220.264 354.599 219.61 354.926 218.629C355.253 217.756 355.253 216.666 355.144 215.575C355.035 214.594 354.817 213.504 354.381 212.522V212.413V212.304C354.272 211.105 354.272 210.014 354.272 208.815C354.272 207.615 354.49 206.307 355.035 205.216C355.144 204.889 355.253 204.671 355.471 204.453L355.798 204.017L356.125 203.69C356.67 203.254 357.215 203.036 357.869 202.927C359.068 202.599 360.267 202.599 361.358 202.818Z"
          fill="url(#paint119_linear)"
        />
      </g>
      <path
        d="M343.153 224.408L349.04 223.645L349.694 224.626L351.656 223.972L351.547 226.152C351.656 226.262 347.732 226.262 343.153 224.408Z"
        fill="#FD6B43"
      />
      <path
        d="M343.371 224.735C343.371 224.735 345.77 227.352 346.315 227.788C346.86 228.224 348.713 228.442 348.713 228.442L351.22 226.37L343.371 224.735Z"
        fill="#FD6B43"
      />
      <path
        opacity="0.9"
        d="M344.57 208.379C345.534 208.379 346.315 207.597 346.315 206.634C346.315 205.67 345.534 204.889 344.57 204.889C343.607 204.889 342.826 205.67 342.826 206.634C342.826 207.597 343.607 208.379 344.57 208.379Z"
        fill="url(#paint120_linear)"
      />
      <path
        opacity="0.9"
        d="M357.215 206.961C356.997 207.943 357.542 208.815 358.305 208.924C359.068 209.033 359.831 208.379 359.94 207.397C360.158 206.416 359.613 205.544 358.85 205.435C358.087 205.326 357.433 205.98 357.215 206.961Z"
        fill="url(#paint121_linear)"
      />
      <path
        d="M404.415 344.136L408.012 319.056L348.059 323.2"
        fill="url(#paint122_linear)"
      />
      <path
        d="M406.377 326.362L374.874 328.979L404.851 339.011L406.377 326.362Z"
        fill="url(#paint123_linear)"
      />
      <path
        d="M404.197 343.7L405.069 341.083L406.05 344.899V346.426H404.633L348.168 326.253V323.2L404.197 343.7Z"
        fill="url(#paint124_linear)"
      />
      <path
        d="M529.01 252.868L530.427 251.559L528.138 249.706L415.752 256.03L417.932 257.557L529.01 252.868Z"
        fill="url(#paint125_linear)"
      />
      <path
        d="M415.752 256.03L417.932 257.556L418.695 258.865L406.05 344.899L404.197 342.282L415.752 256.03Z"
        fill="url(#paint126_linear)"
      />
      <path
        d="M517.455 334.976L517.237 338.465L404.633 346.534V344.245L406.05 344.899L517.455 334.976Z"
        fill="url(#paint127_linear)"
      />
      <path
        d="M530.427 251.559L417.932 257.556L406.05 344.899L519.09 337.266L530.427 251.559Z"
        fill="url(#paint128_linear)"
      />
      <path
        d="M364.737 351.114L375.637 351.878L370.405 368.67L319.063 381.973L318.845 358.311L364.737 351.114Z"
        fill="url(#paint130_linear)"
      />
      <path
        d="M375.637 351.877L317.319 364.962L244.72 344.572L302.385 333.667L375.637 351.877Z"
        fill="url(#paint131_linear)"
      />
      <path
        d="M370.841 338.138L384.249 341.518C384.249 341.518 376.945 353.404 385.23 360.274L313.504 366.271L306.745 339.556L370.841 338.138Z"
        fill="#F1F2F2"
      />
      <path
        d="M265.105 325.707L254.204 324.508L256.166 341.954L313.504 366.162L308.816 341.3L265.105 325.707Z"
        fill="url(#paint132_linear)"
      />
      <path
        d="M254.204 324.508L309.034 348.17L384.14 341.409L329.528 320.037L254.204 324.508Z"
        fill="url(#paint133_linear)"
      />
      <path
        d="M27.1534 535.488C17.9969 506.265 14.8357 474.642 17.6698 441.385C22.4661 384.901 43.3954 342.811 43.6135 342.375L44.8125 343.029C44.5945 343.465 23.7742 385.228 18.9779 441.494C14.5086 493.398 23.2292 568.636 89.7233 631.99L88.7423 632.971C59.2014 604.838 38.4901 572.017 27.1534 535.488ZM58.5474 249.58H59.9645C59.9645 160.82 116.212 103.464 116.757 102.919L115.776 101.938C115.231 102.483 58.5474 160.275 58.5474 249.58ZM7.53221 535.052L8.84029 534.615C-13.3971 460.576 14.5086 373.779 14.8357 372.907L13.5276 372.47C13.3096 373.343 -14.8142 460.685 7.53221 535.052ZM748.887 345.755C763.712 268.772 735.043 209.344 708.446 173.142C679.668 133.887 646.857 112.515 646.53 112.297L645.767 113.496C646.094 113.714 678.687 134.978 707.356 174.014C733.844 209.998 762.186 268.99 747.579 345.537L748.887 345.755ZM362.022 32.3693L362.894 31.3879C316.675 -8.95753 226.636 1.07431 225.764 1.18335L225.873 2.49185C226.854 2.38281 316.239 -7.53999 362.022 32.3693ZM638.354 106.081L639.009 104.882C638.572 104.664 598.022 83.837 572.623 86.7811L572.732 88.0896C597.695 85.1455 637.918 105.863 638.354 106.081ZM723.598 521.639L724.797 521.094C707.138 482.712 724.688 424.592 724.797 423.938L723.489 423.502C723.38 424.156 705.612 482.821 723.598 521.639Z"
        fill="url(#paint134_linear)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M415.207 454.703L397.548 440.528L426.435 396.693L386.429 429.842L371.277 412.941L445.402 373.031L415.207 454.703Z"
        fill="url(#paint135_linear)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M397.984 440.964L396.785 430.496L427.852 394.73L397.984 440.964Z"
        fill="url(#paint136_linear)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M396.785 431.041L386.429 429.406L426.871 395.712L396.785 431.041Z"
        fill="url(#paint137_linear)"
      />
      <path
        d="M68.8923 306.625C68.5653 308.806 68.2383 310.987 67.9113 313.058"
        stroke="url(#paint138_linear)"
        stroke-miterlimit="10"
      />
      <path
        d="M66.0581 325.816C52.3233 423.627 53.7404 473.35 138.766 538.775C152.501 549.352 253.986 575.086 315.684 515.44C354.49 477.93 377.273 453.395 388.391 440.855"
        stroke="url(#paint139_linear)"
        stroke-miterlimit="10"
        stroke-dasharray="11.8 11.8"
      />
      <path
        d="M392.643 435.949C395.586 432.568 396.894 430.933 396.894 430.933"
        stroke="url(#paint140_linear)"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="738.54"
        y1="348.935"
        x2="32.4388"
        y2="348.935"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7242FF" />
        <stop offset="1" stop-color="#D0C0FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="465.501"
        y1="517.164"
        x2="476.575"
        y2="517.289"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#404041" />
        <stop offset="0.0287117" stop-color="#3F3F40" />
        <stop offset="0.2857" stop-color="#39383A" />
        <stop offset="1" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="464.31"
        y1="509.422"
        x2="461.018"
        y2="509.973"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#404041" />
        <stop offset="1" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="397.764"
        y1="489.774"
        x2="421.838"
        y2="503.194"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.567" stop-color="#404041" />
        <stop offset="1" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="425.034"
        y1="499.246"
        x2="414.977"
        y2="490.312"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.5057" stop-color="#404041" />
        <stop offset="1" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1="387.925"
        y1="235.8"
        x2="391.707"
        y2="233.835"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#64FFBF" />
        <stop offset="1" stop-color="#00CCFF" />
      </linearGradient>
      <linearGradient
        id="paint6_linear"
        x1="387.925"
        y1="235.8"
        x2="391.707"
        y2="233.835"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#64FFBF" />
        <stop offset="1" stop-color="#00CCFF" />
      </linearGradient>
      <linearGradient
        id="paint7_linear"
        x1="377.947"
        y1="371.973"
        x2="523.651"
        y2="373.328"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2B3990" />
        <stop offset="1" stop-color="#262262" />
      </linearGradient>
      <linearGradient
        id="paint8_linear"
        x1="377.947"
        y1="371.973"
        x2="523.651"
        y2="373.328"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2B3990" />
        <stop offset="1" stop-color="#262262" />
      </linearGradient>
      <linearGradient
        id="paint9_linear"
        x1="449.609"
        y1="368.67"
        x2="375.055"
        y2="374.829"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2B3990" stop-opacity="0" />
        <stop offset="1" stop-color="#262262" />
      </linearGradient>
      <linearGradient
        id="paint10_linear"
        x1="335.796"
        y1="500.822"
        x2="336.338"
        y2="509.7"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A7A9AC" />
        <stop offset="1" stop-color="#E6E7E8" />
      </linearGradient>
      <linearGradient
        id="paint11_linear"
        x1="348.983"
        y1="497.021"
        x2="330.141"
        y2="496.274"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2735" stop-color="#A7A9AC" />
        <stop offset="0.8409" stop-color="#E6E7E8" />
      </linearGradient>
      <linearGradient
        id="paint12_linear"
        x1="377.962"
        y1="539.888"
        x2="383.378"
        y2="533.764"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A7A9AC" />
        <stop offset="1" stop-color="#E6E7E8" />
      </linearGradient>
      <linearGradient
        id="paint13_linear"
        x1="391.638"
        y1="525.602"
        x2="394.135"
        y2="516.608"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A7A9AC" />
        <stop offset="1" stop-color="#E6E7E8" />
      </linearGradient>
      <linearGradient
        id="paint14_linear"
        x1="284.842"
        y1="513.814"
        x2="283.051"
        y2="501.608"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A7A9AC" />
        <stop offset="0.0801044" stop-color="#ADAFB2" />
        <stop offset="0.6874" stop-color="#D6D7D9" />
        <stop offset="1" stop-color="#E6E7E8" />
      </linearGradient>
      <linearGradient
        id="paint15_linear"
        x1="409.268"
        y1="328.761"
        x2="360.162"
        y2="328.761"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00CAFF" />
        <stop offset="1" stop-color="#5219FC" />
      </linearGradient>
      <linearGradient
        id="paint16_linear"
        x1="687.347"
        y1="490.852"
        x2="652.658"
        y2="491.389"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A7A9AC" />
        <stop offset="1" stop-color="#E6E7E8" />
      </linearGradient>
      <linearGradient
        id="paint17_linear"
        x1="513.216"
        y1="544.826"
        x2="513.216"
        y2="369.075"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A7A9AC" />
        <stop offset="1" stop-color="#E6E7E8" />
      </linearGradient>
      <linearGradient
        id="paint18_linear"
        x1="173.297"
        y1="472.951"
        x2="153.373"
        y2="472.951"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A7A9AC" />
        <stop offset="1" stop-color="#E6E7E8" />
      </linearGradient>
      <linearGradient
        id="paint19_linear"
        x1="685.591"
        y1="350.956"
        x2="150.958"
        y2="350.956"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A7A9AC" />
        <stop offset="1" stop-color="#E6E7E8" />
      </linearGradient>
      <linearGradient
        id="paint20_linear"
        x1="310.352"
        y1="518.555"
        x2="685.591"
        y2="518.555"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A7A9AC" />
        <stop offset="1" stop-color="#E6E7E8" />
      </linearGradient>
      <linearGradient
        id="paint21_linear"
        x1="146.128"
        y1="507.985"
        x2="310.352"
        y2="507.985"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A7A9AC" />
        <stop offset="1" stop-color="#E6E7E8" />
      </linearGradient>
      <linearGradient
        id="paint22_linear"
        x1="415.155"
        y1="247.492"
        x2="385.52"
        y2="247.492"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C49A6C" />
        <stop offset="1" stop-color="#DCB575" />
      </linearGradient>
      <linearGradient
        id="paint23_linear"
        x1="415.155"
        y1="247.492"
        x2="385.52"
        y2="247.492"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C49A6C" />
        <stop offset="1" stop-color="#DCB575" />
      </linearGradient>
      <linearGradient
        id="paint24_linear"
        x1="386.968"
        y1="240.664"
        x2="410.604"
        y2="265.902"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C49A6C" stop-opacity="0" />
        <stop offset="1" stop-color="#DCB575" />
      </linearGradient>
      <linearGradient
        id="paint25_linear"
        x1="396.978"
        y1="237.778"
        x2="399.949"
        y2="236.233"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C49A6C" stop-opacity="0" />
        <stop offset="0.5298" stop-color="#DCB575" />
      </linearGradient>
      <linearGradient
        id="paint26_linear"
        x1="275.132"
        y1="279.387"
        x2="230.856"
        y2="279.387"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.5341" stop-color="#404041" />
        <stop offset="1" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint27_linear"
        x1="275.132"
        y1="279.387"
        x2="230.856"
        y2="279.387"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.5341" stop-color="#404041" />
        <stop offset="1" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint28_linear"
        x1="254.138"
        y1="283.936"
        x2="292.619"
        y2="285.39"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#404041" stop-opacity="0" />
        <stop offset="1" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint29_linear"
        x1="285.765"
        y1="413.954"
        x2="259.42"
        y2="409.334"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#404041" />
        <stop offset="1" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint30_linear"
        x1="285.765"
        y1="413.954"
        x2="259.42"
        y2="409.334"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#404041" />
        <stop offset="1" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint31_linear"
        x1="264.281"
        y1="404.991"
        x2="296.779"
        y2="374.214"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#404041" stop-opacity="0" />
        <stop offset="1" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint32_linear"
        x1="383.432"
        y1="421.197"
        x2="383.059"
        y2="459.942"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#404041" />
        <stop offset="0.5945" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint33_linear"
        x1="383.977"
        y1="418.581"
        x2="383.604"
        y2="457.325"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#404041" />
        <stop offset="0.5945" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint34_linear"
        x1="366.018"
        y1="417.974"
        x2="365.898"
        y2="382.679"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#404041" stop-opacity="0" />
        <stop offset="0.3738" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint35_linear"
        x1="250.213"
        y1="515.987"
        x2="187.636"
        y2="515.987"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2931" stop-color="#404041" />
        <stop offset="0.5501" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint36_linear"
        x1="250.213"
        y1="515.987"
        x2="187.636"
        y2="515.987"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2931" stop-color="#404041" />
        <stop offset="0.5501" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint37_linear"
        x1="249.075"
        y1="498.326"
        x2="196.062"
        y2="517.257"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#404041" />
        <stop offset="1" stop-color="#383638" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint38_linear"
        x1="186.158"
        y1="430.444"
        x2="209.912"
        y2="432.178"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#404041" />
        <stop offset="1" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint39_linear"
        x1="186.158"
        y1="430.445"
        x2="209.912"
        y2="432.179"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#404041" />
        <stop offset="1" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint40_linear"
        x1="195.033"
        y1="428.364"
        x2="176.013"
        y2="428.364"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#404041" stop-opacity="0" />
        <stop offset="1" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint41_linear"
        x1="178.613"
        y1="535.638"
        x2="127.56"
        y2="535.638"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2931" stop-color="#404041" />
        <stop offset="0.5501" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint42_linear"
        x1="178.613"
        y1="535.638"
        x2="127.56"
        y2="535.638"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2931" stop-color="#404041" />
        <stop offset="0.5501" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint43_linear"
        x1="183.759"
        y1="532.93"
        x2="144.202"
        y2="540.565"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#404041" />
        <stop offset="1" stop-color="#383638" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint44_linear"
        x1="176.693"
        y1="369.349"
        x2="234.747"
        y2="372.204"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2931" stop-color="#404041" />
        <stop offset="0.5501" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint45_linear"
        x1="176.693"
        y1="369.349"
        x2="234.747"
        y2="372.204"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2931" stop-color="#404041" />
        <stop offset="0.5501" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint46_linear"
        x1="197.17"
        y1="304.073"
        x2="239.676"
        y2="318.928"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#404041" stop-opacity="0" />
        <stop offset="1" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint47_linear"
        x1="158.931"
        y1="372.695"
        x2="92.1283"
        y2="356.373"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#404041" stop-opacity="0" />
        <stop offset="0.4371" stop-color="#3C3B3C" stop-opacity="0.6733" />
        <stop offset="0.6493" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint48_linear"
        x1="194.032"
        y1="173.278"
        x2="223.794"
        y2="140.318"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A7A9AC" />
        <stop offset="1" stop-color="#E6E7E8" />
      </linearGradient>
      <linearGradient
        id="paint49_linear"
        x1="189.557"
        y1="186.542"
        x2="225.097"
        y2="160.455"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A7A9AC" />
        <stop offset="0.9936" stop-color="#E6E7E8" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint50_linear"
        x1="223.893"
        y1="275.459"
        x2="225.477"
        y2="273.482"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A7A9AC" />
        <stop offset="1" stop-color="#E6E7E8" />
      </linearGradient>
      <linearGradient
        id="paint51_linear"
        x1="141.922"
        y1="276.17"
        x2="145.813"
        y2="277.955"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A7A9AC" />
        <stop offset="1" stop-color="#E6E7E8" />
      </linearGradient>
      <linearGradient
        id="paint52_linear"
        x1="149.095"
        y1="236.43"
        x2="220.022"
        y2="147.855"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A7A9AC" />
        <stop offset="1" stop-color="#E6E7E8" />
      </linearGradient>
      <linearGradient
        id="paint53_linear"
        x1="193.274"
        y1="174.727"
        x2="196.949"
        y2="158.014"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C49A6C" />
        <stop offset="1" stop-color="#DCB575" />
      </linearGradient>
      <linearGradient
        id="paint54_linear"
        x1="176.321"
        y1="84.103"
        x2="193.151"
        y2="94.3223"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C49A6C" />
        <stop offset="1" stop-color="#DCB575" />
      </linearGradient>
      <linearGradient
        id="paint55_linear"
        x1="179.461"
        y1="62.8758"
        x2="211.416"
        y2="75.9832"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C49A6C" />
        <stop offset="1" stop-color="#DCB575" />
      </linearGradient>
      <linearGradient
        id="paint56_linear"
        x1="203.036"
        y1="85.7453"
        x2="165.75"
        y2="85.7453"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2931" stop-color="#404041" />
        <stop offset="0.5501" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint57_linear"
        x1="179.052"
        y1="156.336"
        x2="250.118"
        y2="163.492"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A7A9AC" stop-opacity="0" />
        <stop offset="1" stop-color="#E6E7E8" />
      </linearGradient>
      <linearGradient
        id="paint58_linear"
        x1="242.117"
        y1="175.098"
        x2="158.365"
        y2="144.276"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A7A9AC" />
        <stop offset="1" stop-color="#E6E7E8" />
      </linearGradient>
      <linearGradient
        id="paint59_linear"
        x1="195.863"
        y1="76.5328"
        x2="193.297"
        y2="76.5328"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2931" stop-color="#404041" />
        <stop offset="0.5501" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint60_linear"
        x1="204.701"
        y1="76.6839"
        x2="202.722"
        y2="76.6839"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2931" stop-color="#404041" />
        <stop offset="0.5501" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint61_linear"
        x1="207.099"
        y1="80.9713"
        x2="196.978"
        y2="80.9713"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2931" stop-color="#404041" />
        <stop offset="0.5501" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint62_linear"
        x1="197.88"
        y1="72.4489"
        x2="188.802"
        y2="72.4489"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2931" stop-color="#404041" />
        <stop offset="0.5501" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint63_linear"
        x1="197.821"
        y1="96.4134"
        x2="194.609"
        y2="96.4134"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2931" stop-color="#404041" />
        <stop offset="0.5501" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint64_linear"
        x1="172.819"
        y1="40.6654"
        x2="196.921"
        y2="69.3097"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#404041" />
        <stop offset="1" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint65_linear"
        x1="491.65"
        y1="225.88"
        x2="480.674"
        y2="225.88"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#64FFBF" />
        <stop offset="1" stop-color="#00CCFF" />
      </linearGradient>
      <linearGradient
        id="paint66_linear"
        x1="487.715"
        y1="222.983"
        x2="486.444"
        y2="222.983"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#64FFBF" />
        <stop offset="1" stop-color="#00CCFF" />
      </linearGradient>
      <linearGradient
        id="paint67_linear"
        x1="491.651"
        y1="225.05"
        x2="479.598"
        y2="225.05"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#64FFBF" />
        <stop offset="1" stop-color="#00CCFF" />
      </linearGradient>
      <linearGradient
        id="paint68_linear"
        x1="493.931"
        y1="241.12"
        x2="467.194"
        y2="241.12"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C49A6C" />
        <stop offset="1" stop-color="#DCB575" />
      </linearGradient>
      <linearGradient
        id="paint69_linear"
        x1="493.931"
        y1="241.12"
        x2="467.194"
        y2="241.12"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C49A6C" />
        <stop offset="1" stop-color="#DCB575" />
      </linearGradient>
      <linearGradient
        id="paint70_linear"
        x1="486.668"
        y1="243.283"
        x2="464.98"
        y2="259.11"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C49A6C" stop-opacity="0" />
        <stop offset="1" stop-color="#DCB575" />
      </linearGradient>
      <linearGradient
        id="paint71_linear"
        x1="501.139"
        y1="170.883"
        x2="367.458"
        y2="170.883"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#64FFBF" />
        <stop offset="1" stop-color="#00CCFF" />
      </linearGradient>
      <linearGradient
        id="paint72_linear"
        x1="501.139"
        y1="170.883"
        x2="367.458"
        y2="170.883"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#64FFBF" />
        <stop offset="1" stop-color="#00CCFF" />
      </linearGradient>
      <linearGradient
        id="paint73_linear"
        x1="434.03"
        y1="169.138"
        x2="400.906"
        y2="201.831"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#64FFBF" stop-opacity="0" />
        <stop offset="1" stop-color="#00CCFF" />
      </linearGradient>
      <linearGradient
        id="paint74_linear"
        x1="490.242"
        y1="177.969"
        x2="463.596"
        y2="186.679"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.3233" stop-color="#64FFBF" stop-opacity="0" />
        <stop offset="0.42" stop-color="#4CF3CE" stop-opacity="0.143" />
        <stop offset="0.5817" stop-color="#2BE2E3" stop-opacity="0.3818" />
        <stop offset="0.7363" stop-color="#13D6F3" stop-opacity="0.6103" />
        <stop offset="0.8794" stop-color="#05CFFC" stop-opacity="0.8218" />
        <stop offset="1" stop-color="#00CCFF" />
      </linearGradient>
      <linearGradient
        id="paint75_linear"
        x1="382.516"
        y1="174.594"
        x2="368.115"
        y2="179.49"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#64FFBF" stop-opacity="0" />
        <stop offset="1" stop-color="#00CCFF" />
      </linearGradient>
      <linearGradient
        id="paint76_linear"
        x1="379.357"
        y1="187.746"
        x2="399.199"
        y2="188.697"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#64FFBF" stop-opacity="0" />
        <stop offset="0.1584" stop-color="#49F1D0" stop-opacity="0.1584" />
        <stop offset="0.3877" stop-color="#2AE1E4" stop-opacity="0.3877" />
        <stop offset="0.6094" stop-color="#13D5F3" stop-opacity="0.6094" />
        <stop offset="0.818" stop-color="#05CEFC" stop-opacity="0.818" />
        <stop offset="1" stop-color="#00CCFF" />
      </linearGradient>
      <linearGradient
        id="paint77_linear"
        x1="410.48"
        y1="92.2301"
        x2="411.155"
        y2="109.105"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C49A6C" />
        <stop offset="1" stop-color="#DCB575" />
      </linearGradient>
      <linearGradient
        id="paint78_linear"
        x1="398.554"
        y1="83.0825"
        x2="385.254"
        y2="83.5652"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C49A6C" />
        <stop offset="1" stop-color="#DCB575" />
      </linearGradient>
      <linearGradient
        id="paint79_linear"
        x1="425.055"
        y1="80.849"
        x2="436.538"
        y2="77.1552"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C49A6C" />
        <stop offset="1" stop-color="#DCB575" />
      </linearGradient>
      <linearGradient
        id="paint80_linear"
        x1="432.715"
        y1="66.7409"
        x2="387.633"
        y2="66.7409"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2931" stop-color="#404041" />
        <stop offset="0.5501" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint81_linear"
        x1="424.879"
        y1="91.1822"
        x2="416.886"
        y2="91.9909"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2931" stop-color="#404041" />
        <stop offset="0.5501" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint82_linear"
        x1="416.757"
        y1="98.7995"
        x2="401.076"
        y2="98.7995"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2931" stop-color="#404041" />
        <stop offset="0.5501" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint83_linear"
        x1="421.778"
        y1="94.379"
        x2="419.074"
        y2="94.6525"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2931" stop-color="#404041" />
        <stop offset="0.5501" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint84_linear"
        x1="408.212"
        y1="94.7773"
        x2="405.495"
        y2="94.7773"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2931" stop-color="#404041" />
        <stop offset="0.5501" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint85_linear"
        x1="455.051"
        y1="538.226"
        x2="464.141"
        y2="538.634"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#404041" />
        <stop offset="1" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint86_linear"
        x1="358.276"
        y1="528.455"
        x2="368.117"
        y2="528.768"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#404041" />
        <stop offset="1" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint87_linear"
        x1="476.033"
        y1="549.081"
        x2="467.338"
        y2="565.824"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C49A6C" />
        <stop offset="0.217" stop-color="#D1A871" />
        <stop offset="0.4876" stop-color="#D9B274" />
        <stop offset="0.8385" stop-color="#DCB575" />
      </linearGradient>
      <linearGradient
        id="paint88_linear"
        x1="476.033"
        y1="549.081"
        x2="467.338"
        y2="565.824"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C49A6C" />
        <stop offset="0.217" stop-color="#D1A871" />
        <stop offset="0.4876" stop-color="#D9B274" />
        <stop offset="0.8385" stop-color="#DCB575" />
      </linearGradient>
      <linearGradient
        id="paint89_linear"
        x1="471.133"
        y1="568.903"
        x2="477.685"
        y2="547.211"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C49A6C" />
        <stop offset="0.6898" stop-color="#DCB575" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint90_linear"
        x1="370.505"
        y1="546.231"
        x2="357.959"
        y2="549.833"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C49A6C" />
        <stop offset="0.7606" stop-color="#D6AE73" />
        <stop offset="1" stop-color="#DCB575" />
      </linearGradient>
      <linearGradient
        id="paint91_linear"
        x1="370.505"
        y1="546.231"
        x2="357.959"
        y2="549.833"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C49A6C" />
        <stop offset="0.7606" stop-color="#D6AE73" />
        <stop offset="1" stop-color="#DCB575" />
      </linearGradient>
      <linearGradient
        id="paint92_linear"
        x1="347.09"
        y1="557.467"
        x2="364.584"
        y2="549.938"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C49A6C" />
        <stop offset="0.3289" stop-color="#D1A971" stop-opacity="0.5356" />
        <stop offset="0.7081" stop-color="#DCB575" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint93_linear"
        x1="327.204"
        y1="158.966"
        x2="284.927"
        y2="262.338"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2931" stop-color="#404041" />
        <stop offset="0.5501" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint94_linear"
        x1="397.445"
        y1="268.782"
        x2="386.036"
        y2="289.4"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C49A6C" />
        <stop offset="0.7122" stop-color="#DCB575" />
      </linearGradient>
      <linearGradient
        id="paint95_linear"
        x1="374.778"
        y1="282.6"
        x2="368.031"
        y2="243.153"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F21905" />
        <stop offset="1" stop-color="#FF764B" />
      </linearGradient>
      <linearGradient
        id="paint96_linear"
        x1="366.162"
        y1="266.842"
        x2="365.354"
        y2="236.232"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1243" stop-color="#F21905" stop-opacity="0" />
        <stop offset="0.2772" stop-color="#F3210B" stop-opacity="0.1746" />
        <stop offset="0.5115" stop-color="#F6361B" stop-opacity="0.4421" />
        <stop offset="0.7965" stop-color="#FB5935" stop-opacity="0.7676" />
        <stop offset="1" stop-color="#FF764B" />
      </linearGradient>
      <linearGradient
        id="paint97_linear"
        x1="340.691"
        y1="224.475"
        x2="300.068"
        y2="224.475"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C49A6C" />
        <stop offset="1" stop-color="#DCB575" />
      </linearGradient>
      <linearGradient
        id="paint98_linear"
        x1="291.098"
        y1="268.972"
        x2="358.873"
        y2="270.613"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F21905" />
        <stop offset="1" stop-color="#FF764B" />
      </linearGradient>
      <linearGradient
        id="paint99_linear"
        x1="314.93"
        y1="254.447"
        x2="272.219"
        y2="327.763"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.3198" stop-color="#F21905" stop-opacity="0" />
        <stop offset="1" stop-color="#F21905" />
      </linearGradient>
      <linearGradient
        id="paint100_linear"
        x1="364.291"
        y1="194.352"
        x2="310.098"
        y2="194.352"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C49A6C" />
        <stop offset="1" stop-color="#DCB575" />
      </linearGradient>
      <linearGradient
        id="paint101_linear"
        x1="326.88"
        y1="316.504"
        x2="335.422"
        y2="291.829"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C49A6C" />
        <stop offset="1" stop-color="#DCB575" />
      </linearGradient>
      <linearGradient
        id="paint102_linear"
        x1="273.987"
        y1="249.712"
        x2="306.542"
        y2="279.507"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F21905" />
        <stop offset="1" stop-color="#FF764B" />
      </linearGradient>
      <linearGradient
        id="paint103_linear"
        x1="310.762"
        y1="198.224"
        x2="318.018"
        y2="200.694"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C49A6C" />
        <stop offset="1" stop-color="#DCB575" />
      </linearGradient>
      <linearGradient
        id="paint104_linear"
        x1="415.093"
        y1="471.366"
        x2="459.183"
        y2="472.77"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#404041" />
        <stop offset="1" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint105_linear"
        x1="415.282"
        y1="470.931"
        x2="459.372"
        y2="472.335"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#404041" />
        <stop offset="1" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint106_linear"
        x1="415.093"
        y1="471.366"
        x2="459.183"
        y2="472.77"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#404041" />
        <stop offset="1" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint107_linear"
        x1="437.463"
        y1="471.571"
        x2="381.046"
        y2="481.328"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00939858" stop-color="#404041" stop-opacity="0" />
        <stop offset="0.156" stop-color="#3D3D3E" stop-opacity="0.226" />
        <stop offset="0.6581" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint108_linear"
        x1="360.51"
        y1="475.363"
        x2="330.886"
        y2="473.729"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00558659" stop-color="#404041" stop-opacity="0" />
        <stop offset="0.2599" stop-color="#3D3C3E" stop-opacity="0.2558" />
        <stop offset="1" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint109_linear"
        x1="353.292"
        y1="475.066"
        x2="387.874"
        y2="473.604"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1586" stop-color="#404041" stop-opacity="0" />
        <stop offset="0.914" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint110_linear"
        x1="320.916"
        y1="146.992"
        x2="326.963"
        y2="154.271"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#404041" />
        <stop offset="1" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint111_linear"
        x1="328.413"
        y1="191.706"
        x2="350.292"
        y2="164.383"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2931" stop-color="#404041" />
        <stop offset="0.5501" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint112_linear"
        x1="512.709"
        y1="564.193"
        x2="440.904"
        y2="564.193"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F21905" />
        <stop offset="1" stop-color="#FF764B" />
      </linearGradient>
      <linearGradient
        id="paint113_linear"
        x1="512.709"
        y1="564.193"
        x2="440.904"
        y2="564.193"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F21905" />
        <stop offset="1" stop-color="#FF764B" />
      </linearGradient>
      <linearGradient
        id="paint114_linear"
        x1="512.613"
        y1="574.27"
        x2="444.534"
        y2="574.27"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F21905" />
        <stop offset="1" stop-color="#FF764B" />
      </linearGradient>
      <linearGradient
        id="paint115_linear"
        x1="381.44"
        y1="558.024"
        x2="345.76"
        y2="558.024"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F21905" />
        <stop offset="1" stop-color="#FF764B" />
      </linearGradient>
      <linearGradient
        id="paint116_linear"
        x1="381.44"
        y1="558.024"
        x2="345.76"
        y2="558.024"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F21905" />
        <stop offset="1" stop-color="#FF764B" />
      </linearGradient>
      <linearGradient
        id="paint117_linear"
        x1="394.577"
        y1="560.58"
        x2="338.243"
        y2="560.58"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F21905" />
        <stop offset="1" stop-color="#FF764B" />
      </linearGradient>
      <linearGradient
        id="paint118_linear"
        x1="349.99"
        y1="203.792"
        x2="337.731"
        y2="203.792"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2931" stop-color="#404041" />
        <stop offset="0.5501" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint119_linear"
        x1="361.915"
        y1="211.747"
        x2="349.051"
        y2="211.747"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2931" stop-color="#404041" />
        <stop offset="0.5501" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint120_linear"
        x1="345.257"
        y1="205.029"
        x2="343.923"
        y2="208.291"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2931" stop-color="#404041" />
        <stop offset="0.5501" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint121_linear"
        x1="359.533"
        y1="205.754"
        x2="357.712"
        y2="208.659"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2931" stop-color="#404041" />
        <stop offset="0.5501" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint122_linear"
        x1="407.972"
        y1="331.638"
        x2="348.006"
        y2="331.638"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00CAFF" />
        <stop offset="1" stop-color="#5219FC" />
      </linearGradient>
      <linearGradient
        id="paint123_linear"
        x1="406.408"
        y1="332.681"
        x2="374.831"
        y2="332.681"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2931" stop-color="#404041" />
        <stop offset="0.5501" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint124_linear"
        x1="348.179"
        y1="334.797"
        x2="406.002"
        y2="334.797"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2931" stop-color="#404041" />
        <stop offset="0.5501" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint125_linear"
        x1="415.736"
        y1="253.599"
        x2="530.455"
        y2="253.599"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00CAFF" />
        <stop offset="1" stop-color="#5219FC" />
      </linearGradient>
      <linearGradient
        id="paint126_linear"
        x1="418.749"
        y1="300.486"
        x2="404.148"
        y2="300.486"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00CAFF" />
        <stop offset="1" stop-color="#5219FC" />
      </linearGradient>
      <linearGradient
        id="paint127_linear"
        x1="404.612"
        y1="340.766"
        x2="517.477"
        y2="340.766"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00CAFF" />
        <stop offset="1" stop-color="#5219FC" />
      </linearGradient>
      <linearGradient
        id="paint128_linear"
        x1="530.455"
        y1="298.226"
        x2="406.002"
        y2="298.226"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2931" stop-color="#404041" />
        <stop offset="0.5501" stop-color="#383638" />
      </linearGradient>
      <linearGradient
        id="paint129_linear"
        x1="437.881"
        y1="301.418"
        x2="454.689"
        y2="301.418"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00CAFF" />
        <stop offset="1" stop-color="#4F81FF" />
      </linearGradient>
      <linearGradient
        id="paint130_linear"
        x1="375.655"
        y1="366.507"
        x2="318.868"
        y2="366.507"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00CAFF" />
        <stop offset="1" stop-color="#5219FC" />
      </linearGradient>
      <linearGradient
        id="paint131_linear"
        x1="375.655"
        y1="349.321"
        x2="244.741"
        y2="349.321"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00CAFF" />
        <stop offset="1" stop-color="#5219FC" />
      </linearGradient>
      <linearGradient
        id="paint132_linear"
        x1="313.464"
        y1="345.333"
        x2="254.172"
        y2="345.333"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7242FF" />
        <stop offset="1" stop-color="#D0C0FF" />
      </linearGradient>
      <linearGradient
        id="paint133_linear"
        x1="384.182"
        y1="334.106"
        x2="254.172"
        y2="334.106"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7242FF" />
        <stop offset="1" stop-color="#D0C0FF" />
      </linearGradient>
      <linearGradient
        id="paint134_linear"
        x1="752.908"
        y1="316.473"
        x2="-1.07936"
        y2="316.473"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7242FF" />
        <stop offset="1" stop-color="#D0C0FF" />
      </linearGradient>
      <linearGradient
        id="paint135_linear"
        x1="416.764"
        y1="456.349"
        x2="405.001"
        y2="367.553"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7242FF" />
        <stop offset="1" stop-color="#D0C0FF" />
      </linearGradient>
      <linearGradient
        id="paint136_linear"
        x1="412.798"
        y1="394.419"
        x2="411.694"
        y2="441.314"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7242FF" />
        <stop offset="1" stop-color="#D0C0FF" />
      </linearGradient>
      <linearGradient
        id="paint137_linear"
        x1="407.111"
        y1="395.233"
        x2="406.263"
        y2="431.234"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7242FF" />
        <stop offset="1" stop-color="#D0C0FF" />
      </linearGradient>
      <linearGradient
        id="paint138_linear"
        x1="68.4469"
        y1="306.566"
        x2="62.7048"
        y2="550.527"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7242FF" />
        <stop offset="1" stop-color="#D0C0FF" />
      </linearGradient>
      <linearGradient
        id="paint139_linear"
        x1="226.438"
        y1="310.285"
        x2="220.696"
        y2="554.246"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7242FF" />
        <stop offset="1" stop-color="#D0C0FF" />
      </linearGradient>
      <linearGradient
        id="paint140_linear"
        x1="397.525"
        y1="314.312"
        x2="391.783"
        y2="558.272"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7242FF" />
        <stop offset="1" stop-color="#D0C0FF" />
      </linearGradient>
      <clipPath id="clip0">
        <rect width="754" height="685" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
