<template>
  <div class="item">
    <div class="item__details in-search-item">
      <poster-thumbnail
        :username="user.username"
        :image="user.image"
        padding="pl-none"
      />
      <div class="flex-1">
        <div class="item__header">
          <div class="flex align-center">
            <a href="">{{ user.firstName }} {{ user.lastName }}</a>
            <div v-if="user.jobTitle" class="small dot"></div>
            <span>{{ user.jobTitle }}</span>
          </div>
          <div v-if="resume" class="item__actions">
            <a target="_blank" :href="resume.fileUrl" rel="noopenner">
              <icon-pdf-small color="url(#greengradient)" class="mr-xsm" />
              <p>Resume</p>
            </a>
          </div>
        </div>
        <form-skill-array
          v-if="skills.length"
          class="mt-sm"
          :skills="skills"
          :show-icon="true"
          show-remainder
          tag-color="grey--2"
        />
        <div class="flex mb-md mt-md">
          <base-button
            type="primary small"
            text="Message"
            class="mr-md"
            @click="$emit('handleCurrentMessenger')"
          />
          <base-button
            type="secondary small"
            text="Profile"
            @click="viewUser(user.username)"
          />
        </div>
      </div>
    </div>
    <div
      v-for="post in posts"
      :key="post.id"
      class="blog-post"
      @click.stop="viewPost(post.id)"
    >
      <img
        v-lazy="
          getImage(post.coverImage.url, { size: 'medium', name: post.title })
        "
        :alt="post.title"
        srcset=""
        class="blog-img"
      />
      <div class="type-icon">
        <svg width="24" height="24">
          <use :href="getPostIcon(post.type)" width="24" height="24" />
        </svg>
      </div>
      <div class="details">
        <p class="title">
          {{ formatTextLength(post.title, 100) }}
        </p>
        <p class="description">
          {{ formatTextLength(post.summary || post.description, 240) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import FormSkillArray from '@/components/common/FormSkillArray'
import IconPdfSmall from '@/components/icons/IconPdfSmall'

export default {
  name: 'SearchItem',
  components: {
    FormSkillArray,
    IconPdfSmall
  },
  props: {
    skills: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    posts: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    resumes: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    user: {
      type: Object,
      required: true,
      default() {
        return {
          id: '',
          skills: [],
          image: {
            url: ''
          }
        }
      }
    }
  },
  computed: {
    userUrl() {
      return `${process.env.VUE_APP_USER_FRONTEND}/user/${this.user.username}`
    },
    resume() {
      if (this.resumes.length) {
        return this.resumes[0]
      } else {
        return null
      }
    }
  },
  methods: {
    getPostIcon(type) {
      const obj = {
        video: '#videotab',
        picture: '#picturetab',
        blog: '#writetab'
      }
      return obj[type]
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  width: 100%;
  height: 332px;
  padding: 1.5rem;
  position: relative;
  z-index: 1;
  border-bottom: var(--border-1);
  &__details {
    display: flex;
    margin-bottom: 0.5rem;
  }
  &__img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 0.5rem;
    object-fit: cover;
  }
  &__header {
    @include flex($main: space-between, $cross: flex-start);
    flex: 1;
    margin-bottom: 0.5rem;
  }
  &__actions {
    display: flex;
    margin-left: auto;
    > div,
    > a {
      @include flex($cross: flex-start);
    }
    > *:not(:last-child) {
      margin-right: 0.75rem;
    }
  }
}
.blog-post {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  cursor: pointer;
  width: 100%;
  position: relative;
  .type-icon {
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
    z-index: 10;
  }
}
.details {
  font-size: 0.8rem;
  padding: 0.5rem;
  padding-left: 1rem;
  height: 150px;
  flex: 1;
  border-radius: 0px 0.75rem 0.75rem 0px;
  position: relative;
  background-color: var(--grey-1);
  left: -2px;
  .description {
    color: var(--grey-5);
  }
}

.blog-img {
  border-radius: 0.75rem 0rem 0rem 0.75rem;
  width: 180px;
  height: 150px;
  z-index: 5;
  border: var(--border-1);
}
</style>
