<template>
  <div class="chat__cont">
    <div class="chat__user">
      <poster-thumbnail
        :username="toUser.username"
        :first-name="toUser.firstName"
        :image="toUser.image"
        size="small"
      />
      <div class="chat__header">
        <div class="chat__name">
          <h4>{{ toUser.firstName }} {{ toUser.lastName }}</h4>
          <p>{{ toUser.jobTitle }}</p>
        </div>
        <div class="item__actions">
          <div v-if="resume && resume.id">
            <icon-pdf-small color="url(#greengradient)" class="mr-xsm" />
            <a target="_blank" :href="resume.fileUrl">Resume</a>
          </div>
          <div>
            <icon-account class="mr-xsm" color="url(#greengradient)" />
            <a target="_blank" :href="userUrl">Profile</a>
          </div>
        </div>
      </div>
    </div>
    <div ref="messageContents" class="modal-content">
      <transition-group tag="div" name="message-in">
        <message-modal-item
          v-for="(content, index) in currentMessages"
          :key="content.id"
          :content="content"
          :show-date="showDate(index)"
        />
      </transition-group>
    </div>
    <form
      id="message-modal__input"
      class="modal__input"
      @submit.stop.prevent="postMessage"
    >
      <label class="message-modal-button attach-image" for="attach-image">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enable-background="new 0 0 24 24"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <g>
            <path
              d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M6.6,16.2l2-2.67 c0.2-0.27,0.6-0.27,0.8,0L11.25,16l2.6-3.47c0.2-0.27,0.6-0.27,0.8,0l2.75,3.67c0.25,0.33,0.01,0.8-0.4,0.8H7 C6.59,17,6.35,16.53,6.6,16.2z"
            />
          </g>
        </svg>
        <input
          id="attach-image"
          accept="image/*"
          type="file"
          @change="attachImage"
        />
      </label>
      <div
        ref="messageInput"
        data-test-id="message-input"
        class="text"
        contenteditable
        @keypress.enter.prevent="postMessage"
        @input="loginput($refs.messageInput)"
      />
      <p
        v-show="showPlaceholder"
        class="placeholder"
        @click="$refs.messageInput.focus()"
      >
        Type a message...
      </p>
      <button @click.stop="postMessage">
        <img src="@/assets/custom-icons/send-message.svg" alt srcset />
      </button>
    </form>
  </div>
</template>

<script>
import getMessagesMixin from './_getMessagesMixin'
import atUsernameMixin from '@/mixins/atUsername'

import IconAccount from '@/components/icons/IconAccount'
import IconPdfSmall from '@/components/icons/IconPdfSmall'
import GET_RESUMES from '@/graphql/resumes/GetResumes.gql'

export default {
  name: 'MessageChatBox',
  components: {
    IconAccount,
    IconPdfSmall
  },
  mixins: [getMessagesMixin, atUsernameMixin],
  data() {
    return {
      resume: {}
    }
  },
  apollo: {
    getResumes: {
      query: GET_RESUMES,
      variables() {
        return {
          userId: this.toUser.id
        }
      },
      manual: true,
      skip() {
        return !this.toUser.id
      },
      result({ data, error }) {
        if (error) {
          this.showNotifyBar({
            message: 'Unable to get resumes',
            type: 'error'
          })
        } else if (data && data.getResumes) {
          this.resume = data.getResumes[0]
        }
      }
    }
  },

  computed: {
    userUrl() {
      return `${process.env.VUE_APP_USER_FRONTEND}/user/${this.toUser.username}`
    }
  },
  watch: {
    targetUser(value) {
      this.$apollo.queries.getResumes.refetch({
        userId: value.id
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.chat {
  &__cont {
    position: absolute;
    width: 600px;
    height: 100%;
    left: 0px;
    background: var(--black-2);
    top: 0px;
  }
  &__user {
    width: 100%;
    position: absolute;
    top: 0px;
    border-bottom: var(--border-2);
    left: 0px;
    height: 68px;
    @include flex($main: flex-start);
    padding: 1rem;
  }
  &__input {
    width: 100%;
    position: absolute;
    bottom: 0px;
    border-top: var(--border-2);
    left: 0px;
    @include flex;
    height: 80px;
    padding: 0rem 1.5rem;
    input {
      flex: 1;
      height: 40px;
      border-radius: 30px;
      background: var(--grey-2);
      padding: 0rem 1rem;
      font-size: 15px;
      &::placeholder {
        color: var(--grey-5);
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: var(--grey-5);
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: var(--grey-5);
      }
    }
    button {
      border-radius: 50%;
      background: var(--blue-gradient);
      @include flex;
      height: 40px;
      padding: 0rem;
      width: 40px;
      margin-left: 1rem;
    }
  }
  &__header {
    @include flex($main: space-between);
    flex: 1;
    padding-right: 1rem;
    h4 {
      line-height: 100%;
    }
  }
  &__name {
    position: relative;
    top: 2px;
    h4 {
      font-size: 15px;
      margin-bottom: 0.25rem;
    }
    p {
      color: var(--grey-5);
      font-size: 13px;
    }
  }
}
.item__actions {
  display: flex;
  margin-left: auto;
  > div {
    @include flex($cross: flex-start);
  }
  > *:first-child {
    margin-right: 0.75rem;
  }
}
.modal-content {
  &::-webkit-scrollbar {
    width: 0.5rem;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--grey-2);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(201, 201, 201);
  }
  width: 100%;
  height: calc(100% - 148px);
  position: relative;
  top: 68px;
  padding: 0.5rem 2rem;
  overflow: auto;
  > div {
    min-height: 100%;
  }
}
.modal__input {
  @include flex;
  z-index: 5;
  position: absolute;
  bottom: 0px;
  width: 100%;
  border-top: var(--border-2);
  left: 0px;
  @include flex;
  height: 80px;
  padding: 0rem 1.5rem;
  .text {
    @include hide-scroll;
    border-radius: inherit;
    width: calc(100% - 1.5rem - 46px);
    max-width: calc(100% - 1.5rem - 46px);
    margin-right: 0.75rem;
    border-radius: 16px;
    min-height: 36px;
    max-height: 64px;
    background: var(--grey-2);
    flex: 1;
    font-size: 16px;
    padding: 0.5rem 1rem;
    overflow-y: auto;
  }
  button {
    background: var(--blue-gradient);
    @include flex;
    height: 36px;
    width: 36px;
    border-radius: 2rem;
    img {
      width: 2rem;
      height: 2rem;
      object-fit: contain;
    }
  }
  .attach-image {
    margin-right: 0.75rem;
    width: 36px;
    height: 36px;
    background-color: var(--grey-2);
    cursor: pointer;
    input {
      display: none;
    }
  }
}
.placeholder {
  top: 2rem;
  line-height: 150%;
  color: var(--grey-5);
  position: absolute;
  left: calc(3rem + 42px);
}
.attach-image {
  margin-right: 0.75rem;
  width: 36px;
  height: 36px;
  background-color: var(--grey-2);
  cursor: pointer;
  input {
    display: none;
  }
}
.message-modal-button {
  @include flex;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  cursor: pointer;
  transition: var(--transition-1);
  background: var(--grey-1);
  svg {
    height: 20px;
    width: 20px;
    fill: var(--grey-6);
  }
  &:hover {
    transform: scale(1.1);
    svg {
      fill: var(--white);
    }
  }
  &:not(:last-child) {
    margin-right: 0.75rem;
  }
}
</style>
