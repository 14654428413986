let observer

export default {
  bind(el, binding) {
    const { value } = binding
    const handler = (e) => {
      value(e[0].isIntersecting)
    }
    observer = new IntersectionObserver(handler)
    observer.observe(el)
  },
  unbind(el) {
    observer.unobserve(el)
  }
}
