<template>
  <div class="modal__body in-modal-confirmation">
    <form @submit.prevent="confirmCode" @input="monitorErrors">
      <h4>Confirmation</h4>
      <p>Please enter the code sent to your company email address</p>
      <input-field
        v-model.trim="state.code"
        label="Verification Code"
        :error-message="errorMessages.code"
      />
      <base-button
        text="Submit"
        class="mt-md mb-md"
        :is-loading="btnDisabled"
        @click="confirmCode"
      />
      <button class="btn reset-btn" @click.stop.prevent="resendCode">
        Resend
      </button>
    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapMutations } from 'vuex'
import {
  VERIFICATION_CODE_EXPIRED,
  VERIFICATION_CODE_INVALID
} from '../../utils/constants'
import ConfirmCompanyResetCode from '@/graphql/companies/ConfirmCompanyResetCode.gql'
import ResendCompanyVerificationCode from '@/graphql/companies/ResendCompanyVerificationCode.gql'
import innerModal from '@/mixins/innerModal'

export default {
  name: 'DomainVerification',
  mixins: [innerModal, validationMixin],
  data() {
    return {
      btnDisabled: false,
      currentModal: 'verification',
      showErrors: false,
      errorMessages: {
        code: ''
      },
      state: {
        code: ''
      }
    }
  },
  methods: {
    ...mapMutations(['setCompany']),
    ...mapMutations('auth', ['showModal']),
    async resendCode() {
      try {
        await this.graphqlMutation({
          mutation: ResendCompanyVerificationCode,
          variables: {
            companyId: this.companyId
          }
        })
        this.showNotifyBar({
          message: 'Verification code resent',
          type: 'success'
        })
      } catch (error) {
        this.showNotifyBar({
          message: 'An error occured, please try again',
          type: 'error'
        })
      }
    },

    async confirmCode() {
      try {
        this.showErrors = true
        const isValid = await this.checkErrors()
        if (!isValid) return
        this.btnDisabled = true
        const data = await this.graphqlMutation({
          mutation: ConfirmCompanyResetCode,
          variables: {
            companyId: this.companyId,
            verificationCode: this.state.code
          }
        })
        this.setCompany(data.confirmCompanyResetCode)
        this.showModal('domainSuccessful')
      } catch (error) {
        const message = error.message || ''
        switch (true) {
          case message.includes(VERIFICATION_CODE_INVALID):
            this.showNotifyBar({
              message: `Verification code is invalid`,
              type: 'error'
            })
            break
          case message.includes(VERIFICATION_CODE_EXPIRED):
            this.showNotifyBar({
              message: `Verification code has expired`,
              type: 'error'
            })
            break
          default:
            this.showNotifyBar({
              message: 'An error occured, please try again',
              type: 'error'
            })
            break
        }
      } finally {
        this.btnDisabled = false
      }
    },
    monitorErrors() {
      if (this.showErrors) this.checkErrors()
    },
    checkErrors() {
      if (!this.$v.state.code.required) {
        this.errorMessages.code = 'Please enter a valid code'
      } else {
        this.errorMessages.code = ''
      }
      return !this.$v.state.$invalid
    }
  },
  validations: {
    state: {
      code: {
        required
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
