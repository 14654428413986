<template>
  <div
    :class="['page-info']"
    :style="{ position: isFixed ? 'fixed' : 'absolute' }"
  >
    <slot />
    <div class="info-list">
      <h6>{{ title }}</h6>
      <p v-if="description">{{ description }}</p>
      <ul v-if="list && list.length">
        <li v-for="(item, index) in list" :key="index">
          <svg
            class="info-icon"
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <use :href="`#${item.svg}`" width="24" height="24" />
          </svg>
          <p>{{ item.text }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageInfo',
  props: {
    list: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
    isFixed: {
      type: Boolean,
      required: false,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1320px) {
  .page-info {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  .page-info {
    position: absolute;
    top: calc(4rem + 56px);
    width: calc(var(--page-info-width) - 60px);
    right: 60px;
  }
}
.page-info.page-info {
  align-items: flex-start;
  h6 {
    margin-bottom: 0.5rem;
  }
  p,
  li {
    color: var(--grey-5);
  }
  ul {
    margin-top: 2rem;
  }
  li {
    display: flex;
    p {
      flex: 1;
    }
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
}
.info-list {
  background: var(--grey-2);
  padding: 2rem 2rem 3rem;
  border-radius: 16px;
  position: sticky;
  top: 0px;
}
.info-icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--grey-8);
  margin-right: 1rem;
  @include flex;
}
</style>
