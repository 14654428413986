<template>
  <div>
    <div class="sidebar">
      <router-link
        v-for="nav in navs"
        :key="nav.path"
        :class="['sidebar__item', `in-${nav.name.toLowerCase()}`]"
        :to="nav.path"
      >
        <svg>
          <use :href="nav.icon" />
        </svg>
        <h4>{{ nav.name }}</h4>
      </router-link>
      <div class="sidebar-products">
        <button class="reset-btn space-banner" @click="goToSpace">
          <div>
            <h4>Creatives</h4>
            <p>Showcase your work</p>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import userMessagesMixin from '@/components/messages/_userMessagesMixin'

export default {
  name: 'SideBar',
  mixins: [userMessagesMixin],
  data() {
    return {
      navs: [
        {
          path: '/dashboard',
          id: 'dashboard',
          icon: '#iconhome',
          name: 'Dashboard'
        },
        {
          path: '/jobs',
          id: 'jobs',
          icon: '#iconjob',
          name: 'Jobs'
        },
        {
          path: '/messages',
          id: 'messages',
          icon: '#iconmessage',
          name: 'Messages'
        },
        {
          path: '/talent-pool',
          id: 'talent-pool',
          icon: '#icontalentpool',
          name: 'Talent Pool'
        },
        {
          path: '/settings',
          id: 'settings',
          icon: '#iconsettings',
          name: 'Settings'
        }
      ]
    }
  },
  computed: {
    ...mapState('message', {
      noUnread: (state) => state.allMessageSummary.noUnread
    })
  },
  methods: {
    goToSpace() {
      location.href = `${process.env.VUE_APP_USER_FRONTEND}`
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  width: 240px;
  height: calc(100% - 56px);
  padding-top: 4rem;
  top: 56px;
  left: 0px;
  position: fixed;
  display: flex;
  flex-direction: column;
  background: var(--grey-2);
  box-shadow: var(--box-shadow-3);
  > a {
    margin: 0.75rem;
    margin-bottom: 0rem;
    padding: 1rem;
    height: 54px;
    position: relative;
    border-radius: 8px;
    @include flex($main: flex-start);
    line-height: 100%;
    overflow: hidden;
    &::after {
      content: '';
      height: 100%;
      position: absolute;
      border-radius: inherit;
      background-color: white;
      left: 0px;
      top: 0px;
      z-index: -1;
      background-color: var(--black-4);
      width: 0%;
    }
    &:hover::after {
      transition: all 0.3s var(--ease-out-quint);
      width: 100%;
    }
    &.router-link-active {
      background-color: var(--black-4);
      &::after {
        width: 100%;
      }
    }
  }
  svg {
    width: 24px;
    height: 24px;
    position: relative;
    top: -2px;
    margin-right: 0.5rem;
  }
}
.sidebar-products {
  border-radius: 12px;
  background: var(--black-2);
  margin: 1.25rem;
  margin-top: auto;
  button {
    padding: 1rem;
    @include flex($main: flex-start);
  }

  p {
    color: var(--grey-5);
    font-size: 14px;
  }
  h4,
  p {
    text-align: left;
  }
  svg {
    width: 3rem;
    height: 3rem;
  }
}
</style>
