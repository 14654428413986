<template>
  <div :class="['icon-cont', { 'has-background': hasBgClr }]">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 24 24"
      :width="width"
      :height="height"
      viewBox="0 0 24 24"
    >
      <g><rect fill="none" height="24" width="24" /></g>
      <g>
        <g>
          <path
            d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M4,12c0-4.42,3.58-8,8-8c1.85,0,3.55,0.63,4.9,1.69 L5.69,16.9C4.63,15.55,4,13.85,4,12z M12,20c-1.85,0-3.55-0.63-4.9-1.69L18.31,7.1C19.37,8.45,20,10.15,20,12 C20,16.42,16.42,20,12,20z"
            :fill="color"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import iconMixins from './_iconMixins'
export default {
  mixins: [iconMixins]
}
</script>

<style lang="scss" scoped>
.icon-cont {
  @include flex;
}
.has-background {
  @include flex;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: var(--grey-8);
  transition: all 0.3s var(--ease-out-quint);
  &:hover {
    background-color: var(--grey-7);
  }
}
</style>
