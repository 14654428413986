<template>
  <overlay-wrapper
    :show-inner-modal="showInnerModal"
    @hideInnerModal="hideInnerModal"
  >
    <transition name="scale-in" appear>
      <application-item
        v-if="showInnerModal"
        class="overlay__content application"
        show-details
        v-bind="application"
      />
    </transition>
  </overlay-wrapper>
</template>

<script>
import ApplicationItem from './ApplicationItem.vue'
import innerModalMixin from '@/mixins/innerModal'

export default {
  name: 'ApplicationDetail',
  components: { ApplicationItem },
  mixins: [innerModalMixin],
  computed: {
    application() {
      return this.$store.state.currentApplication.details
    }
  },
  methods: {
    hideInnerModal() {
      this.showInnerModal = false
      setTimeout(() => {
        this.$store.commit('onShowApplicationDetail', {
          show: false
        })
      }, 300)
    }
  }
}
</script>

<style lang="scss" scoped>
// @include popup-modal;
.application {
  width: 600px;
  z-index: 300;
  max-height: 90vh;
  overflow: auto;
}
</style>
