<template>
  <div class="right-panel">
    <div class="header">
      <h4>Job Summary</h4>
    </div>
    <div class="job__summary">
      <div>
        <p>Status</p>
        <p
          :class="`job__status job__status--${jobDetails.status.toLowerCase()}`"
        >
          {{ jobDetails.status }}
        </p>
      </div>
      <div>
        <p>Skills</p>
        <form-skill-array
          tag-color="grey--2"
          :skills="jobDetails.skills"
          :show-icon="true"
        />
      </div>
      <div>
        <p>Salary</p>
        <p class="text">
          ${{ formatPrice(jobDetails.salaryRange.from)
          }}{{ jobDetails.salaryRange.to ? ' - ' : '' }}${{
            formatPrice(jobDetails.salaryRange.to)
          }}
        </p>
      </div>
      <div>
        <p>Summary</p>
        <p class="text">
          {{ formatTextLength(jobDetails.summary, 150) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import FormSkillArray from '@/components/common/FormSkillArray'

export default {
  name: 'JobDetails',
  components: {
    FormSkillArray
  },
  props: {
    jobDetails: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@include right-panel;
.job {
  &__summary {
    width: 300px;
    height: fit-content;
    box-shadow: var(--box-shadow-2);
    border-radius: 1rem;
    position: relative;
    padding: 0.5rem 1.5rem;
    > div:not(:last-child) {
      margin-bottom: 1rem;
      p:first-child {
        font-size: 13px;
      }
    }
    .text {
      color: var(--grey-5);
    }
  }
  &__actions {
    @include flex($main: flex-start);
    margin-top: 1.5rem;
    button {
      background-color: var(--grey-3);
      @include flex;
      border-radius: 20px;
      outline: none;
      &:not(:last-child) {
        margin-right: 1rem;
      }
      &:hover {
        background-color: var(--grey-7);
      }
    }
  }
}
.job__status {
  width: fit-content;
}
</style>
