<template>
  <div>
    <div v-if="showPage && messengers.length" class="page page__message">
      <message-side-bar />
      <message-chat-box v-show="toUser.id" />
      <div v-show="!toUser.id" class="content-empty chat-empty">
        <img src="@/assets/illustrations/messages.svg" alt="" />
        <h4>Welcome to Messages!</h4>
        <p>Select a talent to show conversations</p>
      </div>
    </div>
    <div v-else-if="showPage && !messengers.length" class="content-empty">
      <img src="@/assets/illustrations/messages.svg" alt="" />
      <h4>You have no message</h4>
      <p>
        Your messages will appear here once you start conversing with talents
      </p>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import MessageSideBar from '@/components/messages/MessageSideBar'
import MessageChatBox from '@/components/messages/MessageChatBox'

export default {
  name: 'Messages',
  components: {
    MessageSideBar,
    MessageChatBox
  },
  data() {
    return {
      showPage: false
    }
  },
  metaInfo: {
    title: 'Space | Messages'
  },
  computed: {
    ...mapState('message', ['messengers', 'toUser'])
  },
  created() {
    if (this.$route.params.toUser) {
      this.setToUser({ id: this.$route.params.toUser })
    }
    this.showPage = true
  },
  methods: {
    ...mapMutations('message', ['setToUser'])
  }
}
</script>

<style lang="scss" scoped>
.page {
  &__message {
    width: 100%;
    padding: 0px;
    margin-left: 272px;
    margin-top: 0rem;
    justify-content: flex-start;
    position: relative;
    background: var(--grey-1);
  }
}
.chat-empty {
  position: absolute;
  width: 600px;
  height: 100%;
  left: 0px;
  background: var(--black-2);
  top: 0px;
}
</style>
