<template>
  <div class="job in-job-item">
    <div class="job__name">
      <button class="job__title reset-btn" @click="goToApplication">
        {{ title }}
      </button>
      <p :class="`job__status job__status--${status.toLowerCase()}`">
        {{ status }}
      </p>
      <div class="dropdown-wrapper">
        <dropdown-icon class="in-dropdown-icon" @click="toggleDropdown" />
        <dropdown-card
          v-if="showDropdown"
          v-click-outside="hideDropdown"
          :lists="dropdownList"
          @editJob="editJob"
          @deleteJob="deleteJob"
          @updateStatus="updateStatus"
        />
      </div>
    </div>
    <div class="job__details">
      <div class="job__skills">
        <div class="job__tags">
          <p>
            <span v-if="salaryRange.from"
              >${{ formatPrice(salaryRange.from) }}</span
            >
            {{ salaryRange.to ? ' - ' : '' }}
            <span v-if="salaryRange.to"
              >${{ formatPrice(salaryRange.to) }}</span
            >
          </p>
          <p>{{ jobType | capitalize }}</p>
          <p>{{ workPolicy | capitalize }}</p>
        </div>
        <p class="job__text">
          {{ formatTextLength(summary, 160) }}
        </p>
      </div>
    </div>
    <div class="job-activities">
      <div class="summary">
        <button @click="goToApplication">
          <span>{{ noOfApplicants }} </span>
          {{ pluralize('Applicant', noOfApplicants) }}
        </button>
        <button @click="goToShorlist">
          <span>{{ noOfShortLists }} </span>
          Shortlisted
        </button>
        <button v-if="noOfRecents" class="recents" @click="goToApplication">
          <span>{{ noOfRecents }} </span>
          Recent
        </button>
      </div>
      <div class="flex">
        <button @click="externalShare">
          <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
            <path
              d="M18 22Q16.75 22 15.875 21.125Q15 20.25 15 19Q15 18.825 15.025 18.637Q15.05 18.45 15.1 18.3L8.05 14.2Q7.625 14.575 7.1 14.787Q6.575 15 6 15Q4.75 15 3.875 14.125Q3 13.25 3 12Q3 10.75 3.875 9.875Q4.75 9 6 9Q6.575 9 7.1 9.212Q7.625 9.425 8.05 9.8L15.1 5.7Q15.05 5.55 15.025 5.363Q15 5.175 15 5Q15 3.75 15.875 2.875Q16.75 2 18 2Q19.25 2 20.125 2.875Q21 3.75 21 5Q21 6.25 20.125 7.125Q19.25 8 18 8Q17.425 8 16.9 7.787Q16.375 7.575 15.95 7.2L8.9 11.3Q8.95 11.45 8.975 11.637Q9 11.825 9 12Q9 12.175 8.975 12.362Q8.95 12.55 8.9 12.7L15.95 16.8Q16.375 16.425 16.9 16.212Q17.425 16 18 16Q19.25 16 20.125 16.875Q21 17.75 21 19Q21 20.25 20.125 21.125Q19.25 22 18 22ZM18 6Q18.425 6 18.712 5.713Q19 5.425 19 5Q19 4.575 18.712 4.287Q18.425 4 18 4Q17.575 4 17.288 4.287Q17 4.575 17 5Q17 5.425 17.288 5.713Q17.575 6 18 6ZM6 13Q6.425 13 6.713 12.712Q7 12.425 7 12Q7 11.575 6.713 11.287Q6.425 11 6 11Q5.575 11 5.287 11.287Q5 11.575 5 12Q5 12.425 5.287 12.712Q5.575 13 6 13ZM18 20Q18.425 20 18.712 19.712Q19 19.425 19 19Q19 18.575 18.712 18.288Q18.425 18 18 18Q17.575 18 17.288 18.288Q17 18.575 17 19Q17 19.425 17.288 19.712Q17.575 20 18 20ZM18 5Q18 5 18 5Q18 5 18 5Q18 5 18 5Q18 5 18 5Q18 5 18 5Q18 5 18 5Q18 5 18 5Q18 5 18 5ZM6 12Q6 12 6 12Q6 12 6 12Q6 12 6 12Q6 12 6 12Q6 12 6 12Q6 12 6 12Q6 12 6 12Q6 12 6 12ZM18 19Q18 19 18 19Q18 19 18 19Q18 19 18 19Q18 19 18 19Q18 19 18 19Q18 19 18 19Q18 19 18 19Q18 19 18 19Z"
              fill="currentColor"
            />
          </svg>
          Share
        </button>
        <button @click="viewJob">
          <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
            <path
              d="M5 21Q4.175 21 3.587 20.413Q3 19.825 3 19V5Q3 4.175 3.587 3.587Q4.175 3 5 3H11Q11.425 3 11.713 3.287Q12 3.575 12 4Q12 4.425 11.713 4.712Q11.425 5 11 5H5Q5 5 5 5Q5 5 5 5V19Q5 19 5 19Q5 19 5 19H19Q19 19 19 19Q19 19 19 19V13Q19 12.575 19.288 12.287Q19.575 12 20 12Q20.425 12 20.712 12.287Q21 12.575 21 13V19Q21 19.825 20.413 20.413Q19.825 21 19 21ZM9 15Q8.725 14.725 8.725 14.3Q8.725 13.875 9 13.6L17.6 5H15Q14.575 5 14.288 4.712Q14 4.425 14 4Q14 3.575 14.288 3.287Q14.575 3 15 3H20Q20.425 3 20.712 3.287Q21 3.575 21 4V9Q21 9.425 20.712 9.712Q20.425 10 20 10Q19.575 10 19.288 9.712Q19 9.425 19 9V6.4L10.375 15.025Q10.1 15.3 9.7 15.3Q9.3 15.3 9 15Z"
              fill="currentColor"
            />
          </svg>
          View
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'JobItem',
  props: {
    id: {
      type: String,
      required: true,
      default: ''
    },
    title: {
      type: String,
      required: true,
      default: ''
    },
    noOfApplicants: {
      type: Number,
      required: true,
      default: 0
    },
    noOfShortLists: {
      type: Number,
      required: true,
      default: 0
    },
    noOfRecents: {
      type: Number,
      required: true,
      default: 0
    },
    salaryRange: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    summary: {
      type: String,
      required: true,
      default: ''
    },
    createdAt: {
      type: String,
      required: true,
      default: ''
    },
    status: {
      type: String,
      required: true,
      default: 'open'
    },
    jobType: {
      type: String,
      required: true,
      default: ''
    },
    workPolicy: {
      type: String,
      required: true,
      default: ''
    },
    isVerified: {
      type: Boolean,
      required: true,
      default: false
    },
    skills: {
      type: Array,
      required: false,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      dropdownList: [
        { type: 'action', text: 'Change status', action: 'updateStatus' },
        { type: 'action', text: 'Edit job', action: 'editJob' },
        { type: 'action', text: 'Delete', action: 'deleteJob' }
      ],
      showDropdown: false
    }
  },
  computed: {
    linkUrl() {
      const title = this.title
        .toLowerCase()
        .replace(/\s+/g, '_')
        .replace(/\W/g, '')
        .replace(/_+/g, '-')
      return `${process.env.VUE_APP_USER_FRONTEND}/jobs/${this.id}/${title}`
    }
  },
  methods: {
    ...mapMutations(['toggleSharePostModal']),
    deleteJob() {
      this.showDropdown = false
      this.$emit('deleteJob', this.id)
    },
    updateStatus() {
      this.showDropdown = false
      this.$emit('updateStatus', this.id)
    },
    hideDropdown() {
      this.showDropdown = false
    },
    goToShorlist() {
      this.$router.push({
        name: 'shortlists',
        params: { applicationId: this.id }
      })
    },
    goToApplication() {
      this.$router.push({
        name: 'applications',
        params: { applicationId: this.id }
      })
    },
    viewJob() {
      open(this.linkUrl, '_blank')
    },
    toggleDropdown() {
      this.showDropdown = true
    },
    externalShare() {
      this.toggleSharePostModal({
        show: true,
        post: {
          id: this.id,
          title: this.title,
          type: this.type,
          coverImage: this.coverImage,
          poster: this.poster,
          linkUrl: this.linkUrl
        }
      })
    },
    editJob() {
      this.$router.push({ name: 'edit-job', params: { jobId: this.id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.job {
  width: 100%;
  margin-bottom: 2rem;
  border-radius: 12px;
  background: var(--grey-1);
  padding: 1.5rem;
  position: relative;
  transition: all 0.3s ease-out;
  &__title {
    font-size: 1.2rem;
    margin-right: var(--spacing-base);
    &:hover {
      text-decoration: underline;
    }
  }
  &__details {
    @include flex($main: space-between, $cross: flex-start);
  }
  &__text {
    margin-top: 0.5rem;
    color: var(--grey-5);
  }
  &__tags {
    @include flex($main: flex-start);
    margin-bottom: 0.75rem;
    p {
      margin-right: 1rem;
      padding: 0.25rem 1rem;
      border-radius: 20px;
      background: var(--black-2);
      color: var(--grey-6);
    }
    span {
      color: inherit;
    }
  }
  &__name {
    @include flex($main: flex-start, $cross: baseline);
    margin-bottom: 0.75rem;
    width: 100%;
    > p:first-child {
      font-size: 16px;
      font-weight: 600;
    }
  }
  &__skills {
    flex: 1;
  }
}
.job {
  &:nth-child(2n) {
    .job__summary {
      background: var(--blue-gradient);
    }
  }
}
.job-activities {
  width: 100%;
  height: 48px;
  padding: var(--spacing-base);
  background: var(--grey-2);
  margin-top: var(--spacing-sm);
  border-radius: var(--border-radius-xs);
  @include flex($main: space-between);
  .summary {
    display: flex;
    button {
      color: var(--blue-6);
    }
    .recents {
      border: 2px solid var(--blue-6);
      color: var(--blue-6);
      border-radius: var(--border-radius-base);
      padding: var(--spacing-2xs) var(--spacing-base);
    }
    span {
      color: inherit;
    }
  }
  button {
    text-align: left;
    display: flex;
    align-items: center;
    @include reset-btn;
    &:hover {
      text-decoration: underline;
    }
    &:not(:last-child) {
      margin-right: var(--spacing-lg);
    }
  }
  span {
    font-weight: 600;
  }
  span,
  svg {
    margin-right: var(--spacing-2xs);
  }
  svg {
    color: var(--grey-6);
  }
}
.dropdown-wrapper {
  margin-left: var(--spacing-base);
  top: var(--spacing-xs);
  margin-left: auto;
  position: relative;
}
</style>
