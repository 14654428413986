<template>
  <div class="list-users">
    <div v-if="users.length > 0">
      <list-user-item
        v-for="(user, index) in users"
        :key="`${user.id}-${index}`"
        :user="user"
        :posts="user.posts"
        :skills="user.skills"
        :resumes="user.resumes"
        @handleCurrentMessenger="
          handleCurrentMessenger({
            show: true,
            id: user.id
          })
        "
      />
    </div>
    <empty-list
      v-else
      :message="emptyListMessage"
      :has-content="users.length > 0"
      :action-type="actionType"
      :current-user="currentUser"
      :content-loading="contentLoading"
    />
    <div class="end-space">
      <div class="medium dot" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ListUserItem from './ListUserItem.vue'

export default {
  name: 'ListUsers',
  components: {
    ListUserItem
  },
  props: {
    users: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    emptyListMessage: {
      type: String,
      required: false,
      default: ''
    },
    actionType: {
      type: String,
      default: '',
      required: false
    },
    currentUser: {
      type: Object,
      required: false,
      default() {
        return {
          firstName: '',
          lastName: '',
          username: '',
          id: ''
        }
      }
    },
    contentLoading: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  methods: {
    ...mapActions('message', ['handleCurrentMessenger'])
  }
}
</script>

<style lang="scss" scoped>
.list-users {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--grey-2);
  border-radius: 0 0 var(--border-radius-lg) var(--border-radius-lg);
}
@media (max-width: 600px) {
  .list-users {
    grid-template-columns: 1fr;
    grid-template-rows: 350px;
    gap: 1rem 0rem;
    padding: 1rem 0rem;
  }
}
</style>
