<template>
  <div class="settings">
    <div class="">
      <side-panel :tabs="tabs" :active-tab="activeTab" @changeTab="changeTab" />
      <component :is="activeTab" />
    </div>
  </div>
</template>

<script>
import SidePanel from '../components/settings/SidePanel.vue'
import CompanyProfile from '../components/settings/CompanyProfile.vue'
import Members from '../components/settings/Members.vue'

export default {
  name: 'Settings',
  components: { SidePanel, CompanyProfile, Members },
  data() {
    return {
      activeTab: 'CompanyProfile',
      tabs: [
        {
          name: 'Company Profile',
          value: 'CompanyProfile'
        }
        // {
        //   name: 'Members',
        //   value: 'Members'
        // }
      ]
    }
  },
  mounted() {
    if (this.$route.hash) {
      this.currentComponent = this.$route.hash.slice(1)
    }
  },
  methods: {
    changeTab(tab) {
      this.activeTab = tab.value
      scrollTo(0, 0)
      this.$router.replace(`${this.$route.path}#${tab.value}`).catch((e) => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.settings::v-deep {
  @include flex;
  margin-bottom: 60px;
  margin-left: 270px;
  padding: 4rem 0rem;
  h5 {
    margin-bottom: 1rem;
    font-size: 1.25rem;
    width: 500px;
    max-width: 100%;
  }
  .tips {
    color: var(--grey-6);
    background: var(--grey-2);
    padding: 0.5rem 1rem;
    border-radius: 10px;
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-size: 1rem;
    width: 500px;
    max-width: 100%;
    &::before {
      content: '\261B';
    }
  }
  .settings__box {
    width: 500px;
    background-color: var(--grey-2);
    padding: 2rem;
    border-radius: 1rem;
    max-width: 100%;
    box-shadow: var(--box-shadow-3);
    button.primary {
      margin: 2rem auto 2rem;
    }
  }
}
</style>
