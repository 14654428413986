<template>
  <div class="navbar adjust-popup">
    <div class="nav-items-group left">
      <router-link :to="{ name: 'dashboard' }" class="logo cursor-pointer">
        <Logo />
      </router-link>
    </div>
    <div :class="['nav-items-group right']">
      <div class="create-buttons">
        <base-button
          text="Post a job"
          class="in-create-post"
          @click="$router.push({ name: 'create-job' })"
        />
      </div>
      <div class="nav-items">
        <div class="notification__cont">
          <button
            class="nav-item u-button-click"
            @click.stop="toggleNotificationDropdown"
          >
            <svg width="24" height="24">
              <use href="#iconnotification" />
            </svg>
          </button>
          <span v-if="newNotifications" class="notification__indicator" />
          <notification-dropdown
            v-if="showNotificationDropdown"
            @hideNotifications="toggleNotificationDropdown"
          />
        </div>
      </div>
      <div class="user-actions" @mouseleave="toggleDropdown(false)">
        <div
          class="company__thumbnail in-user-profile"
          @mouseenter="toggleDropdown(true)"
        >
          <img
            :src="
              getImage(company.logo.url, {
                size: 'small',
                name: company.companyName
              })
            "
            alt=""
          />
          <h4>{{ user.firstName }}</h4>
        </div>
        <dropdown-card
          v-if="showDropdown"
          :username="user.username"
          @logoutUser="logoutUser"
          @goToUserSettings="goToUserSettings"
          @showContactUsModal="onContactUs(true)"
        />
      </div>
    </div>
    <contact-us v-if="showContactUsModal" @close="onContactUs(false)" />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import ContactUs from '../common/ContactUs.vue'
import NotificationDropdown from './NotificationDropdown'
import logoutMixin from './_logoutMixin'
import getNotificationsMixin from './_getNotificationsMixin'
import DropdownCard from './NavbarDropdown'

export default {
  name: 'TheNavbar',
  components: {
    DropdownCard,
    ContactUs,
    NotificationDropdown
  },
  mixins: [logoutMixin, getNotificationsMixin],
  data() {
    return {
      showDropdown: false,
      showContactUsModal: false
    }
  },
  computed: {
    ...mapState(['company', 'user'])
  },
  methods: {
    ...mapMutations('auth', ['showModal']),

    async toggleDropdown(value) {
      if (value !== undefined) {
        this.showDropdown = value
        return
      }
      if (!this.showDropdown) {
        await this.$store.commit('toggleDropdowns', true)

        await this.$store.commit('toggleDropdowns', false)

        this.showDropdown = true
      } else {
        this.showDropdown = false
      }
    },
    postJob() {
      this.$router.push({ name: 'create-job' })
    },
    hideDropdown() {
      this.showDropdown = false
    },
    goToUserSettings() {
      open(`${process.env.VUE_APP_USER_FRONTEND}/settings`, '_blank')
    },
    onContactUs(show) {
      this.showContactUsModal = show
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  background-color: var(--opacity-blue-60);
  backdrop-filter: blur(8px);
  height: 56px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  justify-content: space-between;
  padding: 0rem 1.75rem;
  z-index: 50;
  p,
  i {
    color: var(--white);
  }
}
.nav-items-group,
.nav-items-group > div {
  display: flex;
  align-items: center;
}
.logo {
  margin-right: 1.5rem;
  display: flex;
  img {
    object-fit: unset;
  }
  img:first-child {
    margin-right: 0.5rem;
    width: 32px;

    height: 32px;
    object-fit: contain;
  }
}

.nav-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0 1.5rem;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  @include flex;
  transition: all 0.3s var(--ease-out-quint);
  background-color: transparent;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
h4 {
  color: white;
}
.c-post-job {
  margin-right: 1.5rem;
}
.notification {
  &__cont {
    position: relative;
  }
  &__indicator {
    position: absolute;
    top: 5px;
    right: 26px;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    background: var(--red-gradient);
  }
}
.user-actions {
  position: relative;
  margin-left: 1.5rem;
  padding: 8px 0px; // (56px - 40px) / 2
}
.company {
  &__thumbnail {
    @include flex;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 2rem;
    padding: 0.25rem 2rem 0.25rem 0.25rem;
    cursor: pointer;
    img {
      width: 2rem;
      height: 2rem;
      object-fit: cover;
      border-radius: 50%;
      margin-right: 0.5rem;
    }
  }
}
p {
  font-size: 1rem;
}
.slide-right-enter {
  transform: translateY(-5px);
  opacity: 0;
}
.slide-right-enter-active {
  transition: all 0.3s ease-in-out;
}
.notification-wrapper {
  width: 24px;
  height: 24px;
}
.create-buttons {
  width: 132px;
  margin: 0 1.5rem;
  transition: var(--transition-2);
  transform-origin: top right;
  &.small {
    width: 40px;
    transform-origin: top left;
  }
}
@media (max-width: 1200px) {
  .logo-text {
    display: none;
  }
}
@media (max-width: 960px) {
  .create-button {
    display: none;
  }
}
@media (max-width: 900px) {
  .logo-text {
    display: block;
  }
  .nav-items-group.left {
    flex: 1;
  }
  .nav-items-group.right.search-has-focus .nav-items {
    display: none;
  }
  .nav-item {
    img {
      display: block;
      height: 1.5rem;
      width: auto;
      margin: 0 0.5rem;
    }
    p {
      display: none;
    }
    .show-md {
      display: block;
    }
  }
  .nav-item.nav-item-show-md {
    p {
      display: block;
    }
  }
}
@media (max-width: 600px) {
  .nav-item {
    display: none;
  }
}
</style>
