<template>
  <div class="page--has-info job">
    <div>
      <div class="page-header">
        <back-arrow @click="$router.go(-1)" />
        <h4>{{ pageTitle }}</h4>
      </div>
      <is-loading v-if="!showPage" />
      <div v-else>
        <div class="job-steps">
          <div v-for="(step, index) in steps" :key="index">
            <button
              :class="['reset-btn', { filled: currentStep >= index + 1 }]"
            >
              {{ index + 1 }}
            </button>
            <p>{{ step }}</p>
          </div>
          <hr />
        </div>
        <form
          v-if="currentStep === 1"
          class="job-form flex-col"
          @keydown.enter.prevent
          @input="monitorErrors"
        >
          <div class="mb-lg">
            <input-field
              v-model.trim="state.title"
              label="Title"
              name="title"
              type="text"
              :error-message="errorMessages.title"
            />
            <skill-input-field
              v-model.trim="currentSkill"
              label="Skills"
              name="requiredSkills"
              bg-color="grey--1"
              :options="skillOptions"
              placeholder="Figma, Vue.js, Illustrator, Algolia"
              :skills="state.skills"
              :error-message="errorMessages.skills"
              @update="updateSkills"
              @addSkillFromInput="addSkillFromInput"
              @removeSkill="removeSkill"
            />
            <select-field
              :selected="state.jobType"
              label="Job Type"
              name="jobType"
              class="in-job-type"
              placeholder="Fulltime, contract, or internship"
              :error-message="errorMessages.jobType"
              :options="defaultTypes"
              @update="updateSelection"
            />
            <select-field
              :selected="state.workPolicy"
              label="Work Policy"
              name="workPolicy"
              class="in-work-policy"
              placeholder="Remote, or onsite"
              :error-message="errorMessages.workPolicy"
              :options="defaultWorkPolicy"
              @update="updateSelection"
            />
            <location-field
              :current-location="state.location"
              label="Location"
              name="location"
              placeholder="Optional"
              :error-message="errorMessages.location"
              @update="updateLocation"
            />
            <input-field
              v-model.number.trim="state.salaryRange.from"
              label="Min Salary (USD/Year)"
              placeholder="Optional"
              name="minSalary"
              :error-message="errorMessages.salaryRange.from"
              type="number"
            />
            <input-field
              v-model.number.trim="state.salaryRange.to"
              label="Max Salary (USD/Year)"
              placeholder="Optional"
              type="number"
              name="maxSalary"
              :error-message="errorMessages.salaryRange.to"
            />
            <textarea-input-field
              v-model.trim="state.summary"
              label="Summary"
              name="summary"
              type="text"
              :error-message="errorMessages.summary"
            />
            <text-editor
              label="Details"
              :content="state.details"
              @editEditorContent="editEditorContent"
            />
          </div>
          <div class="job-actions">
            <base-button text="Next" @click="changeStep(2)" />
          </div>
        </form>
        <form v-if="currentStep === 2" class="job-form flex-col">
          <div class="step-instruction">
            <p class="mb-sm">
              Add questions applicants will be required to answer, for example
            </p>
            <p>- Are you willing to relocate?</p>
            <p>- What's your Salary Expectation?</p>
            <p class="mt-sm">Click on "Next" if you don't have any.</p>
          </div>
          <div class="mb-lg">
            <input-field
              v-for="(question, index) in state.questions"
              :key="index"
              v-model.trim="question.value"
              :placeholder="getPlaceholder({ index, key: 'questions' })"
              :can-have-error="false"
              :has-slot="true"
              :name="`question${index}`"
              @input="
                addToList({
                  index,
                  value: question.value,
                  key: 'questions'
                })
              "
            >
              <button
                v-if="index < state.questions.length - 1"
                class="reset-btn remove"
                @click.stop.prevent="
                  removeFromList({ index, key: 'questions' })
                "
              >
                <img src="@/assets/icons/round-close-24px.svg" alt="close" />
              </button>
            </input-field>
          </div>
          <div class="job-actions">
            <base-button text="Back" type="secondary" @click="changeStep(1)" />
            <base-button text="Next" @click="changeStep(3)" />
          </div>
        </form>
        <form v-if="currentStep === 3" class="job-form flex-col">
          <div class="step-instruction">
            <p class="mb-sm">Fill in stages of your interview, for example</p>
            <p>- Introductory Call</p>
            <p>- Interview Stage 1</p>
            <p>- Take Home Assessment</p>
            <p class="mt-sm">
              We automatically create a kanban board for each stage, so you can
              move candidates from one stage to the other with ease.
            </p>
            <p class="mt-sm">Click on "Next" to skip this.</p>
          </div>
          <div class="mb-lg">
            <input-field
              v-for="(interviewStage, index) in state.interviewStages"
              :key="index"
              v-model.trim="interviewStage.value"
              :placeholder="getPlaceholder({ index, key: 'interviewStages' })"
              :can-have-error="false"
              :has-slot="true"
              :name="`interviewStage${index}`"
              @input="
                addToList({
                  index,
                  value: interviewStage.value,
                  key: 'interviewStages'
                })
              "
            >
              <button
                v-if="index < state.interviewStages.length - 1"
                class="reset-btn remove"
                @click.stop.prevent="
                  removeFromList({ index, key: 'interviewStages' })
                "
              >
                <img src="@/assets/icons/round-close-24px.svg" alt="close" />
              </button>
            </input-field>
          </div>
          <div class="job-actions">
            <base-button text="Back" type="secondary" @click="changeStep(2)" />
            <base-button text="Next" @click="changeStep(4)" />
          </div>
        </form>
        <div v-else-if="currentStep === 4" class="job-form flex-col">
          <div class="step-instruction">
            <p>
              Go through your job post to make sure all details are correct.
            </p>
          </div>
          <div class="job-review">
            <div>
              <p>Title</p>
              <p>{{ state.title }}</p>
            </div>
            <div class="flex space-between meta">
              <div>
                <p>Job Type</p>
                <p>{{ state.jobType.name }}</p>
              </div>
              <div>
                <p>Work Policy</p>
                <p>{{ state.workPolicy.name }}</p>
              </div>
              <div v-if="state.salaryRange.from || state.salaryRange.to">
                <p>Salary</p>
                <p>
                  <span v-if="state.salaryRange.from"
                    >${{ state.salaryRange.from }}</span
                  >
                  <span v-if="state.salaryRange.from && state.salaryRange.to">
                    -
                  </span>
                  <span v-if="state.salaryRange.to"
                    >${{ state.salaryRange.to }}</span
                  >
                </p>
              </div>
            </div>
            <div v-if="state.location && state.location.id">
              <p>Location</p>
              <p>
                {{ state.location.state }}
                <span v-if="state.location.state">,</span>
                {{ state.location.country }}
              </p>
            </div>
            <div>
              <p>Skills</p>
              <form-skill-array
                tag-color="grey--2"
                :skills="state.skills"
                :show-icon="true"
              />
            </div>
            <div>
              <p>Summary</p>
              <p>{{ state.summary }}</p>
            </div>
            <div>
              <p>Details</p>
              <div class="blog-modal__details" v-html="state.details" />
            </div>
            <div v-if="state.questions.length > 1">
              <p>Application Questions</p>
              <div>
                <p
                  v-for="(question, index) in state.questions.slice(0, -1)"
                  :key="index"
                >
                  {{ index + 1 }}. {{ question.value }}
                </p>
              </div>
            </div>
            <div v-if="state.interviewStages.length > 1">
              <p>Interview Stages</p>
              <div>
                <p
                  v-for="(stage, index) in state.interviewStages.slice(0, -1)"
                  :key="index"
                >
                  {{ index + 1 }}. {{ stage.value }}
                </p>
              </div>
            </div>
          </div>
          <div class="job-actions">
            <base-button text="Back" type="secondary" @click="changeStep(3)" />
            <base-button
              text="Submit"
              :is-loading="btnDisabled"
              @click="submit"
            />
          </div>
        </div>
      </div>
    </div>
    <page-info-container :info="info" :show-page-mobile-info-modal="false" />
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import { mapState, mapMutations } from 'vuex'
import skillsMixin from '@/mixins/skillsMixin'
import TextEditor from '@/components/common/TextEditor'
import CREATE_JOB from '@/graphql/jobs/CreateJob.gql'
import GET_JOB from '@/graphql/jobs/GetJob.gql'
import EDIT_JOB from '@/graphql/jobs/EditJob.gql'
import FormSkillArray from '@/components/common/FormSkillArray'
import LocationField from '@/components/common/LocationField'
import PageInfoContainer from '@/components/common/PageInfoContainer.vue'

export default {
  name: 'CreateJob',
  components: {
    TextEditor,
    LocationField,
    PageInfoContainer,
    FormSkillArray
  },
  mixins: [validationMixin, skillsMixin],
  data() {
    return {
      btnDisabled: false,
      currentStep: 1,
      showPage: true,
      defaultTypes: [
        { id: 'fulltime', name: 'Full time' },
        { id: 'contract', name: 'Contract' },
        { id: 'internship', name: 'Internship' }
      ],
      defaultWorkPolicy: [
        { id: 'remote', name: 'Remote' },
        { id: 'onsite', name: 'Onsite' },
        { id: 'hybrid', name: 'Hybrid' }
      ],
      companies: [],
      steps: [
        'Add Job Details',
        'Application Questions',
        'Interview Stages',
        'Review Job'
      ],
      info: {
        title: 'Hire top talents in simple and easy steps',
        description: `Jobs posted will appear to tech talents who have the same skill-set/tech-stack/tool as you've indicated in the job details. You will receive applications, shortlist qualified candidates and move them through your interview stages all on Tabulio Space`
      },
      state: {
        title: '',
        skills: [],
        questions: [{ value: '' }],
        applyType: 'space',
        interviewStages: [{ value: '' }],
        details: '',
        summary: '',
        jobType: { id: '', name: '' },
        workPolicy: { id: '', name: '' },
        location: {
          country: '',
          state: '',
          address: '',
          placeId: ''
        },
        salaryRange: {
          from: null,
          to: null
        }
      },
      errorMessages: {
        title: '',
        skills: '',
        details: '',
        summary: '',
        jobType: '',
        workPolicy: '',
        salaryRange: {
          from: '',
          to: ''
        }
      },
      showErrors: false
    }
  },
  metaInfo: {
    title: 'Space | Create Job'
  },
  apollo: {
    getJob: {
      query: GET_JOB,
      variables() {
        const jobId = this.$route.params.jobId
        return {
          jobId
        }
      },
      result({ data, error }) {
        if (error) {
          this.showNotifyBar({
            message: 'Unable to fetch job details',
            type: 'error'
          })
        } else {
          const d = data.getJob
          const jobType = this.defaultTypes.find(
            (item) => item.id === d.jobType
          )
          const workPolicy = this.defaultWorkPolicy.find(
            (item) => item.id === d.workPolicy
          )
          const questions = (d.questions || []).concat({ value: '' })
          const interviewStages = (d.interviewStages || []).concat({
            value: ''
          })
          this.state = {
            title: d.title,
            skills: d.skills,
            details: d.details,
            location: d.location,
            applyType: d.applyType || 'space',
            questions,
            interviewStages,
            summary: d.summary,
            jobType,
            workPolicy,
            company: d.company,
            salaryRange: d.salaryRange
          }
          this.showPage = true
        }
      },
      skip() {
        return !this.$route.params.jobId
      }
    }
  },
  computed: {
    ...mapState(['company']),
    pageTitle() {
      return this.$route.params.jobId ? 'Edit your job' : 'Post a new job'
    }
  },
  created() {
    if (this.$route.params.jobId) {
      this.showPage = false
    }
  },
  methods: {
    ...mapMutations('auth', ['showModal']),
    changeStep(step) {
      if (this.currentStep === 1 && step === 2) {
        this.showErrors = true
        const isValid = this.checkErrors()
        if (!isValid) {
          this.showNotifyBar({
            message: 'Please fill all required fields',
            type: 'info'
          })
          return
        }
      }
      this.currentStep = step
    },
    addToList({ index, value, key }) {
      const isLastItem = this.state[key].length - 1 === index
      if (isLastItem && value) {
        this.state[key] = this.state[key].concat({ value: '' })
      }
    },
    removeFromList({ index, key }) {
      this.state[key] = this.state[key].filter(
        (insp, inspIndex) => inspIndex !== index
      )
    },
    getPlaceholder({ index, key }) {
      const keyLength = this.state[key].length
      if (keyLength === 1 || index < keyLength - 1) {
        return key === 'questions'
          ? 'Are you open to relocating?'
          : 'Introductory Call'
      } else {
        return key === 'questions' ? 'Add more questions' : 'Add more stages'
      }
    },
    async submit() {
      // if (!this.company.domainEmailVerified) {
      //   this.showModal('domainVerification')
      //   return
      // }
      const state = await this.removeTypename(this.state)
      const salaryRange = {
        from: this.state.salaryRange.from,
        to: this.state.salaryRange.to
      }
      if (
        salaryRange.from &&
        salaryRange.to &&
        salaryRange.from > salaryRange.to
      ) {
        salaryRange.from = this.state.salaryRange.to
        salaryRange.to = this.state.salaryRange.from
      }
      if (salaryRange.to && !salaryRange.from) {
        salaryRange.from = salaryRange.to
        salaryRange.to = null
      }
      const input = Object.assign({}, state, {
        company: this.companyId,
        interviewStages: this.state.interviewStages,
        questions: this.state.questions,
        skills: this.state.skills.map((i) => i.id),
        location: await this.removeTypename(this.state.location),
        jobType: this.state.jobType.id,
        workPolicy: this.state.workPolicy.id,
        salaryRange
      })
      this.$route.params.jobId ? this.editJob(input) : this.createJob(input)
    },
    async createJob(input) {
      try {
        this.btnDisabled = true
        const data = await this.graphqlMutation({
          mutation: CREATE_JOB,
          variables: {
            input
          }
        })
        if (data && data.createJob) {
          this.$router.push({ name: 'dashboard' })
        }
        this.showNotifyBar({
          message: 'You have created a new job',
          type: 'success'
        })
      } catch (error) {
        this.showNotifyBar({
          message: 'An error occured, try again',
          type: 'error'
        })
      } finally {
        this.btnDisabled = false
      }
    },
    async editJob(input) {
      try {
        this.btnDisabled = true
        const data = await this.graphqlMutation({
          mutation: EDIT_JOB,
          variables: {
            jobId: this.$route.params.jobId,
            company: this.companyId,
            input
          }
        })
        if (data && data.editJob) {
          this.showNotifyBar({
            message: 'Job successfully updated',
            type: 'success'
          })
          // this.$router.push({ name: 'dashboard' })
        }
      } catch (error) {
        this.showNotifyBar({
          message: 'An error occured, try again',
          type: 'error'
        })
      } finally {
        this.btnDisabled = false
      }
    },
    updateSkills(skill) {
      this.currentSkill = ''
      if (skill) {
        this.state.skills = this.state.skills.concat(skill)
        this.monitorErrors()
      }
    },
    removeSkill(skill) {
      this.state.skills = this.state.skills.filter((s) => s.id !== skill.id)
      this.monitorErrors()
    },
    updateSelection(option, name) {
      this.state[name] = option
      this.monitorErrors()
    },
    updateLocation(location) {
      this.state.location = location
    },
    editEditorContent(text) {
      this.state.details = text
    },
    checkErrors() {
      const arr = ['title', 'skills', 'summary', 'jobType', 'workPolicy']
      arr.forEach((value) => {
        switch (value) {
          case 'title':
            if (!this.$v.state.title.required) {
              this.errorMessages.title = 'Job title is required'
            } else {
              this.errorMessages.title = ''
            }
            break
          case 'skills':
            if (!this.$v.state.skills.required) {
              this.errorMessages.skills = 'Please select the required skills'
            } else {
              this.errorMessages.skills = ''
            }
            break
          case 'summary':
            if (!this.$v.state.summary.required) {
              this.errorMessages.summary = 'Please enter summary'
            } else {
              this.errorMessages.summary = ''
            }
            break
          case 'jobType':
            if (!this.$v.state.jobType.id.required) {
              this.errorMessages.jobType = 'Job type is required'
            } else {
              this.errorMessages.jobType = ''
            }
            break
          case 'workPolicy':
            if (!this.$v.state.workPolicy.id.required) {
              this.errorMessages.workPolicy = 'Work policy is required'
            } else {
              this.errorMessages.workPolicy = ''
            }
            break
          default:
            break
        }
      })
      return !this.$v.state.$invalid
    },
    monitorErrors() {
      if (this.showErrors) this.checkErrors()
    }
  },
  validations: {
    state: {
      title: {
        required
      },
      summary: {
        required
      },
      jobType: {
        id: {
          required
        }
      },
      workPolicy: {
        id: {
          required
        }
      },
      skills: {
        required,
        minLength: minLength(1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.job::v-deep {
  padding-top: 8rem;
  .page-info {
    top: calc(10rem + 56px);
  }
}
.job-form {
  width: 580px;
  min-height: 432px;
  background: var(--grey-2);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--box-shadow-3);
  padding: 1.75rem 2rem 2rem;
  max-width: 100%;
  @media screen and (max-width: 600px) {
    border-radius: 0px;
  }
}
.page-header {
  @include flex($main: flex-start);
  padding: 1rem 2rem;
  border-bottom: var(--border-2);
  align-self: flex-start;
  position: fixed;
  top: 56px;
  left: 240px;
  width: calc(100% - 240px);
  z-index: 10;
  background: var(--black-2);
  h4 {
    font-size: 1.5rem;
  }
}
.job-steps {
  @include flex($main: space-between);
  margin-bottom: 1rem;
  position: relative;
  button {
    @include flex;
    border-radius: 50%;
    height: 36px;
    pointer-events: none;
    width: 36px;
    background-color: var(--grey-2);
    transition: all 0.3s var(--ease-out-quint);
    margin-bottom: var(--spacing-xs);
    position: relative;
    z-index: 1;
    svg path {
      fill: var(--grey-6);
    }
    &.filled {
      border: 2px solid var(--blue-6);
    }
  }
  hr {
    position: absolute;
    top: 10px;
    border-bottom: var(--border-2);
    height: 1px;
    left: 32px;
    right: 32px;
  }
  p {
    text-align: center;
    width: 81px;
  }
  div {
    align-items: center;
    flex-direction: column;
    display: flex;
  }
  h4 {
    margin-left: 0.25rem;
  }
}
.job-review {
  margin-bottom: var(--spacing-lg);
  p,
  span {
    color: var(--grey-6);
  }
  > div {
    margin-bottom: var(--spacing-base);
    > p:first-child {
      font-size: 14px;
      color: var(--grey-5);
    }
  }
}
.meta {
  p:first-child {
    font-size: 14px;
    color: var(--grey-5);
  }
}
.remove {
  position: absolute;
  right: 0.4rem;
  @include flex;
  top: 50%;
  background: var(--grey-2);
  border-radius: 4px;
  padding: 0.5rem;
  transform: translateY(-50%);
  img {
    width: 1.5rem;
  }
}
.job-actions {
  @include flex;
  margin: auto 0rem var(--spacing-lg);
  button:not(:last-child) {
    margin-right: var(--spacing-sm);
  }
}
.step-instruction {
  padding: var(--spacing-sm);
  background: var(--grey-1);
  border-radius: var(--border-radius-sm);
  margin-bottom: var(--spacing-lg);
  p {
    color: var(--grey-5);
  }
}
</style>
