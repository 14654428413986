<template>
  <div class="user-card">
    <poster-thumbnail :image="image" padding="pl-none" />
    <div>
      <p class="username">{{ firstName }} {{ lastName }}</p>
      <div class="flex actions">
        <a target="_blank" :href="resumeUrl" rel="noopenner">
          <p>Resume</p>
        </a>
        <a target="_blank" :href="userUrl" rel="noopenner">
          <p>Profile</p>
        </a>
        <button @click="$emit('handleCurrentMessenger')">
          <p>Message</p>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserCard',
  props: {
    applicantId: {
      type: String,
      required: true
    },
    image: {
      type: Object,
      required: true,
      default: () => ({})
    },
    username: {
      type: String,
      required: true
    },
    firstName: {
      type: String,
      required: true
    },
    skills: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    lastName: {
      type: String,
      required: true
    },
    resumeUrl: {
      type: String,
      required: true
    }
  },
  computed: {
    userUrl() {
      return `${process.env.VUE_APP_USER_FRONTEND}/user/${this.username}`
    }
  }
}
</script>

<style lang="scss" scoped>
.user-card {
  border: 1px solid transparent;
  border-radius: var(--border-radius-xs);
  margin-bottom: var(--spacing-base);
  background: var(--grey-2);
  display: flex;
  padding: var(--spacing-base);
  cursor: move;
  &:hover {
    border: 1px solid var(--blue-6);
  }
}
.username {
  margin-bottom: var(--spacing-2xs);
  color: var(--grey-6);
}
.actions {
  a,
  button {
    @include reset-btn;
    color: var(--grey-5);
    &:hover {
      text-decoration: underline;
    }
  }
  p {
    color: inherit;
    font-size: 13px;
    margin-right: var(--spacing-base);
  }
}
</style>
