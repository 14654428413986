<template>
  <overlay-wrapper
    :show-inner-modal="showInnerModal"
    @hideInnerModal="hideInnerModal"
  >
    <transition name="scale-in" appear="">
      <div v-if="showInnerModal" class="overlay__content modal">
        <icon-delete
          width="80"
          height="80"
          color="url(#greengradient)"
          class="c-icon"
        />
        <h4>Are you sure?</h4>
        <p>
          This will remove this job and all submitted applications. Consider
          marking the job status as closed to retain the submitted applications
        </p>
        <div class="flex modal__actions">
          <base-button
            class="mr-md"
            type="tertiary"
            text="Cancel"
            @click="handleAction(false)"
          />
          <base-button
            text="Delete"
            type="primary red"
            @click="handleAction(true)"
          />
        </div>
      </div>
    </transition>
  </overlay-wrapper>
</template>

<script>
import IconDelete from '../icons/IconDelete.vue'
import innerModal from '@/mixins/innerModal'

export default {
  name: 'DeleteJob',
  components: { IconDelete },
  mixins: [innerModal],
  methods: {
    hideInnerModal() {
      this.showInnerModal = false
      setTimeout(() => {
        this.$emit('hideModal')
      }, 300)
    },
    handleAction(value) {
      this.hideInnerModal()
      this.$emit('click', value)
    }
  }
}
</script>

<style lang="scss" scoped>
@include popup-modal;
</style>
