<template>
  <div :class="['overlay__cont', { 'overlay__cont--top': showOverlay }]">
    <transition name="fade-in" appear>
      <div
        v-if="showInnerModal"
        :class="['overlay__background']"
        @click.self="hideInnerModal"
      ></div>
    </transition>
    <transition appear mode="out-in" name="base-login">
      <div v-if="showInnerModal" :class="['overlay__content modal']">
        <transition appear mode="out-in" name="login-scale-in">
          <domain-verification
            v-if="modals.domainVerification.show"
            key="domain-verification"
          />
          <domain-confirmation
            v-else-if="modals.domainConfirmation.show"
            key="domain-confirmation"
          />

          <domain-successful
            v-else-if="modals.domainSuccessful.show"
            key="domain-successful"
            @hideModals="hideInnerModal"
          />
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
// This should be prefetched when the browser is idle
import { mapMutations, mapState } from 'vuex'
import DomainVerification from './DomainVerification'
import DomainSuccessful from './DomainSuccessful'
import DomainConfirmation from './DomainConfirmation'

export default {
  name: 'BaseAuthModal',
  components: {
    DomainVerification,
    DomainSuccessful,
    DomainConfirmation
  },
  data() {
    return {
      showInnerModal: false
    }
  },
  computed: {
    ...mapState('auth', ['modals']),
    showOverlay() {
      return Object.keys(this.modals).some(
        (item) => this.modals[item].show === true
      )
    }
  },
  watch: {
    $route() {
      this.hideInnerModal()
      this.resetForm()
      this.showInnerModal = false
    },
    showOverlay(value) {
      if (value) {
        setTimeout(() => {
          this.showInnerModal = true
        }, 100)
      }
    }
  },
  mounted() {
    this.hideModals()
    this.resetForm()
  },

  methods: {
    ...mapMutations('auth', ['showModal', 'hideModals', 'resetForm']),
    hideInnerModal() {
      this.showInnerModal = false
      setTimeout(() => {
        this.hideModals()
      }, 600)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal::v-deep {
  --color-grey--1: #f6f6f9;
  border-radius: 24px;
  width: 400px;
  display: flex;
  align-items: center;
  padding: 2rem;
  position: relative;
  background: var(--grey-1);
  min-height: 280px;
  form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  h4 {
    color: white;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  h4 + p {
    margin-bottom: 2rem;
    font-size: 1rem;
    color: var(--grey-5);
    background: rgba(0, 0, 0, 0.2);
    padding: 0.25rem 1rem;
    border-radius: 4px;
  }
}
</style>
